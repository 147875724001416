import { camelCase, omitBy } from "lodash";

import type { ApiData, ApiInput } from "src/api";

import type {
  CustomerFormData,
  DataFilter,
  DataFilterWithIsNew,
} from "./types";

export const normalizeDataFilter = (dataFilter: DataFilterWithIsNew[]) =>
  dataFilter.map(({ _isNew: _, ...f }) =>
    omitBy(f, (x) => x == null || (Array.isArray(x) && x.length === 0)),
  ) as DataFilter[];

export const emptyCustomerFormData: CustomerFormData = {
  customer_name: "",
  customer_code: "",
  data_filter: [],
  show_risk_scores: false,
  emails: [],
};

export const customerFormDataToPayload = ({
  customer_name,
  customer_code,
  show_risk_scores,
  data_filter,
  emails,
}: CustomerFormData): ApiInput<"customer.create"> => ({
  customer_name,
  customer_code,
  show_risk_scores,
  data_filter: data_filter && normalizeDataFilter(data_filter),
  emails,
});

export const customerPayloadToFormData = ({
  customer_name,
  customer_code,
  show_risk_scores,
  data_filter,
}: ApiData<"customer.create">): CustomerFormData => ({
  customer_name,
  customer_code,
  show_risk_scores,
  data_filter,
  emails: [],
});

/**
 * Returns the single org, location, or program filter from an array of data
 * filters. This is used to compute the customer code.
 */
const onlyOrgLocationOrProgram = (dataFilter?: DataFilter[]) => {
  const options = (dataFilter ?? [])
    .map((f: Record<string, unknown>) => [
      f.program_code,
      f.organization_display_id,
      f.sampling_location_id,
    ])
    .flat(3)
    .filter(Boolean);
  return options.length === 1 && options[0];
};

export const generateCustomerCode = ({
  data_filter,
  customer_name,
}: Partial<CustomerFormData>) => {
  const orgLocationProgram = onlyOrgLocationOrProgram(data_filter);
  if (orgLocationProgram) {
    return orgLocationProgram.toString();
  } else if (customer_name) {
    return camelCase(customer_name).toLowerCase();
  } else {
    return "";
  }
};
