interface LogoProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}

const Logo = ({ className, ...props }: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 326.96 79.39"
    className={className}
    fill={props.fill ?? "currentColor"}
    {...props}
  >
    <g>
      <path d="M194.91,45.18h-9.17l-2.11,6.17h-4.32l8.71-23.43h4.62l8.71,23.43h-4.32l-2.11-6.17Zm-1.12-3.23l-2.74-7.92c-.26-.76-.49-1.49-.69-2.51h-.07c-.2,1.02-.43,1.75-.69,2.51l-2.74,7.92h6.93Z" />
      <path d="M203.49,34.85h3.76v3.2c1.12-2.44,2.94-3.63,5.48-3.63,3.6,0,5.48,2.28,5.48,6.7v10.23h-3.99v-9.83c0-2.61-.99-3.9-3.07-3.9-2.31,0-3.7,1.85-3.7,4.98v8.75h-3.96v-16.5Z" />
      <path d="M220.55,46.87c0-1.58,.69-2.9,2.01-3.73,1.19-.69,2.77-1.12,5.38-1.49l3.5-.43v-.43c0-2.48-.96-3.53-3.07-3.53-1.95,0-3.1,1.02-3.23,2.9h-4.06c.26-3.53,3.04-5.71,7.29-5.71,4.88,0,7.06,2.08,7.06,6.87v4.92c0,2.25,.17,3.96,.5,5.12h-3.96c-.13-.83-.23-1.82-.26-2.84-.79,2.01-2.87,3.24-5.54,3.24-3.37,0-5.61-1.95-5.61-4.88Zm10.99-2.28v-1.06l-3.73,.56c-2.18,.36-3.17,1.16-3.17,2.57s1.06,2.28,2.8,2.28c2.44,0,4.09-1.72,4.09-4.36Z" />
      <path d="M243.19,51.35h-3.96V27.43h3.96v23.93Z" />
      <path d="M245.92,57.82v-3.1c.69,.07,1.09,.1,1.52,.1,1.88,0,2.87-.79,3.47-2.64l.33-.99-6.44-16.34h4.36l3.6,10.79c.26,.76,.4,1.39,.49,1.95h.07c.07-.5,.23-1.09,.5-1.95l3.27-10.79h4.13l-6.21,17.43c-1.49,4.22-3.7,5.71-7.23,5.71-.49,0-1.15-.03-1.85-.17Z" />
      <path d="M262.26,34.85h2.97v-4.16l3.96-1.58v5.74h4.03v2.87h-4.03v7.89c0,1.78,.76,2.61,2.41,2.61,.66,0,1.09-.07,1.62-.2v3.33c-.96,.2-1.68,.3-2.71,.3-3.96,0-5.28-2.08-5.28-5.41v-8.51h-2.97v-2.87Z" />
      <path d="M275.26,27.99h4.29v4.12h-4.29v-4.12Zm.2,6.87h3.96v16.5h-3.96v-16.5Z" />
      <path d="M282.22,43.1c0-5.38,3.1-8.65,8.12-8.65,4.09,0,6.77,2.18,7.26,5.94l-3.86,.63c-.26-2.25-1.55-3.47-3.5-3.47-2.44,0-3.96,2.05-3.96,5.45s1.49,5.61,4.03,5.61c1.98,0,3.2-1.22,3.56-3.53l3.73,.76c-.59,3.66-3.37,5.91-7.29,5.91-4.92,0-8.09-3.4-8.09-8.65Z" />
      <path d="M298.59,46.96l2.94-1.32c.79,2.11,2.44,3.2,4.79,3.2,1.98,0,3.04-.76,3.04-2.01,0-1.32-.69-1.88-2.51-2.11l-2.51-.33c-3.43-.43-5.02-2.24-5.02-4.92,0-3.07,2.51-5.02,6.7-5.02,3.56,0,6.24,1.62,7.06,4.19l-2.74,1.49c-.79-1.68-2.28-2.81-4.42-2.81-1.88,0-2.94,.66-2.94,1.95,0,1.12,.76,1.78,2.51,1.98l2.77,.4c3.4,.43,4.75,2.15,4.75,4.88,0,3.23-2.51,5.21-6.8,5.21-3.79,0-6.57-1.68-7.62-4.79Z" />
    </g>
    <g>
      <path d="M97.08,44.94c0,3.3-2.18,6.5-7.86,6.5h-9.83V28.01h9.27c5.78,0,7.79,3.07,7.79,6.14,0,2.34-1.35,4.39-4.06,5.12,2.9,.5,4.69,2.54,4.69,5.68Zm-13.43-13.7v6.67h4.32c3.3,0,4.09-1.68,4.09-3.3,0-1.75-.96-3.37-4.09-3.37h-4.32Zm9.08,13.3c0-2.01-1.25-3.5-4.06-3.5h-5.02v7.16h4.79c3.14,0,4.29-1.68,4.29-3.66Z" />
      <path d="M100.02,28.07h4.29v4.12h-4.29v-4.12Zm.2,6.87h3.96v16.5h-3.96v-16.5Z" />
      <path d="M107.04,43.16c0-5.51,3.33-8.61,8.09-8.61s8.09,3.1,8.09,8.61-3.33,8.68-8.09,8.68-8.09-3.14-8.09-8.68Zm12.11,0c0-3.76-1.49-5.61-4.03-5.61s-4.06,1.85-4.06,5.61,1.52,5.68,4.06,5.68,4.03-1.91,4.03-5.68Z" />
      <path d="M129.68,48.77l-.4,2.67h-3.33V27.51h3.96v10c.86-1.72,2.64-2.97,5.08-2.97,4.03,0,6.63,3.53,6.63,8.65s-2.67,8.65-6.8,8.65c-2.44,0-4.29-1.22-5.15-3.07Zm7.82-5.58c0-3.43-1.39-5.61-3.93-5.61-2.34,0-3.76,1.95-3.76,5.08v1.12c0,3.07,1.39,5.02,3.76,5.02s3.93-2.11,3.93-5.61Z" />
      <path d="M143.28,43.16c0-5.51,3.33-8.61,8.09-8.61s8.09,3.1,8.09,8.61-3.33,8.68-8.09,8.68-8.09-3.14-8.09-8.68Zm12.11,0c0-3.76-1.48-5.61-4.03-5.61s-4.06,1.85-4.06,5.61,1.52,5.68,4.06,5.68,4.03-1.91,4.03-5.68Z" />
      <path d="M159.65,34.94h2.97v-4.16l3.96-1.58v5.74h4.03v2.87h-4.03v7.89c0,1.78,.76,2.61,2.41,2.61,.66,0,1.09-.07,1.62-.2v3.33c-.96,.2-1.68,.3-2.71,.3-3.96,0-5.28-2.08-5.28-5.41v-8.51h-2.97v-2.87Z" />
    </g>
    <path d="M62.71,16.68V62.71H16.68V16.68H62.71m2.8-2.8H13.88v51.63h51.63V13.88h0Z" />
    <path d="M23.09,54.88v-11.76h4.04c1.46,0,2.6,.27,3.42,.81s1.23,1.35,1.23,2.42c0,.56-.13,1.06-.4,1.49-.26,.44-.67,.77-1.22,.99,.69,.16,1.2,.47,1.53,.94,.33,.47,.49,1.01,.49,1.63,0,1.15-.38,2.01-1.16,2.6-.77,.59-1.87,.88-3.31,.88h-4.62Zm2.74-6.86h1.36c.62-.01,1.08-.13,1.39-.36,.31-.23,.46-.56,.46-1.01,0-.5-.16-.87-.47-1.09-.31-.23-.79-.34-1.44-.34h-1.3v2.8Zm0,1.81v2.95h1.88c.58,0,1.02-.11,1.31-.34,.29-.23,.44-.58,.44-1.05,0-.51-.12-.9-.38-1.16-.25-.26-.66-.39-1.22-.39h-2.04Z" />
    <path d="M23.09,36.27v-11.76h4.04c1.46,0,2.6,.27,3.42,.81s1.23,1.35,1.23,2.42c0,.56-.13,1.06-.4,1.49-.26,.44-.67,.77-1.22,.99,.69,.16,1.2,.47,1.53,.94,.33,.47,.49,1.01,.49,1.63,0,1.15-.38,2.01-1.16,2.6-.77,.59-1.87,.88-3.31,.88h-4.62Zm2.74-6.86h1.36c.62-.01,1.08-.13,1.39-.36,.31-.23,.46-.56,.46-1.01,0-.5-.16-.87-.47-1.09-.31-.23-.79-.34-1.44-.34h-1.3v2.8Zm0,1.81v2.95h1.88c.58,0,1.02-.11,1.31-.34,.29-.23,.44-.58,.44-1.05,0-.51-.12-.9-.38-1.16-.25-.26-.66-.39-1.22-.39h-2.04Z" />
    <path d="M44.69,50.05c0,1.45-.46,2.64-1.38,3.58-.92,.94-2.12,1.41-3.6,1.41s-2.7-.47-3.63-1.41c-.93-.94-1.39-2.13-1.39-3.58v-2.12c0-1.44,.46-2.64,1.39-3.58s2.13-1.41,3.62-1.41,2.68,.47,3.6,1.41c.93,.94,1.39,2.14,1.39,3.58v2.12Zm-2.72-2.13c0-.84-.2-1.53-.59-2.07-.4-.54-.96-.81-1.68-.81s-1.3,.27-1.69,.8-.59,1.23-.59,2.07v2.13c0,.85,.2,1.55,.59,2.08,.4,.54,.96,.81,1.7,.81s1.27-.27,1.66-.81c.39-.54,.59-1.23,.59-2.08v-2.13Z" />
    <path d="M55.73,45.21h-3.1v9.66h-2.74v-9.66h-3.08v-2.1h8.92v2.1Z" />
    <path d="M41.06,36.28h-2.72v-11.76h2.72v11.76Z" />
    <path d="M56.25,31.45c0,1.45-.46,2.64-1.38,3.58-.92,.94-2.12,1.41-3.6,1.41s-2.7-.47-3.63-1.41c-.93-.94-1.39-2.13-1.39-3.58v-2.12c0-1.44,.46-2.64,1.38-3.58s2.13-1.41,3.62-1.41,2.68,.47,3.6,1.41,1.39,2.14,1.39,3.58v2.12Zm-2.72-2.13c0-.84-.2-1.53-.59-2.07-.4-.54-.95-.81-1.68-.81s-1.3,.27-1.69,.8c-.39,.54-.59,1.23-.59,2.07v2.13c0,.85,.2,1.55,.59,2.08,.4,.54,.96,.81,1.7,.81s1.27-.27,1.66-.81c.39-.54,.59-1.23,.59-2.08v-2.13Z" />
  </svg>
);

export default Logo;
