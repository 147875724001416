import { Alert } from "antd";

import Loading from "./Loading";

interface QueryWrapperProps {
  data?: unknown;
  isLoading: boolean;
  loadingMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
  children: React.ReactNode;
}

const QueryWrapper = ({
  data,
  isLoading,
  children,
  loadingMessage,
  errorMessage = "Unable to load data at this time. Please try again later.",
}: QueryWrapperProps) => {
  if (isLoading) {
    return <Loading tip={loadingMessage} />;
  } else if (data) {
    return <>{children}</>;
  } else {
    return <Alert message={errorMessage} />;
  }
};

export default QueryWrapper;
