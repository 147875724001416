import { Tag } from "antd";

import BiobotCollapse, { Panel } from "src/components/BiobotCollapse";

const WatchlistMethods = ({ className }: { className?: string }) => (
  <div className={className}>
    <BiobotCollapse className="font-black text-h1">
      <Panel
        header="About Beta Trends"
        className="bg-navy-1 p-4 mb-3.5 rounded"
        key="0"
      >
        <div className="text-p1 font-light mb-1 text-navy-9">
          <div className="mb-6">
            Please note, our trends feature is in BETA and is only supported for
            SARS&#8209;CoV&#8209;2 Community location-types.
          </div>
          <div className="flex" data-test="increasing">
            <div className="flex-none w-40">
              <Tag color="red" className="text-p2 font-light">
                Increasing
              </Tag>
            </div>
            <div className="flex-auto text-p1-thin">
              Recent data shows an increasing trend. We have high confidence
              that the underlying SARS&#8209;CoV&#8209;2 disease burden is
              increasing in this location.
            </div>
          </div>
          <div className="bg-navy-4 w-full h-[1px] mt-2 mb-3" />
          <div className="flex" data-test="stable">
            <div className="flex-none w-40">
              <Tag color="gold" className="text-p2 font-light">
                Stable
              </Tag>
            </div>
            <div className="flex-auto text-p1-thin">
              Recent data shows a stable trend. We have high confidence that the
              underlying SARS&#8209;CoV&#8209;2 disease burden is stable in this
              location.
            </div>
          </div>
          <div className="bg-navy-4 w-full h-[1px] mt-2 mb-3" />
          <div className="flex" data-test="decreasing">
            <div className="flex-none w-40">
              <Tag color="green" className="text-p2 font-light">
                Decreasing
              </Tag>
            </div>
            <div className="flex-auto text-p1-thin">
              Recent data shows a decreasing trend. We have high confidence that
              the underlying SARS&#8209;CoV&#8209;2 disease burden is decreasing
              in this location.
            </div>
          </div>
          <div className="bg-navy-4 w-full h-[1px] mt-2 mb-3" />
          <div className="flex" data-test="insufficient-data">
            <div className="flex-none w-40">
              <Tag color="default" className="text-p2 font-light">
                Insufficient Data
              </Tag>
            </div>
            <div className="flex-auto text-p1-thin">
              Recent data shows an indeterminate trend. A location must have
              collected at least 12 samples (with measurements {">"}300,000
              copies/L) to receive a trend designation.
            </div>
          </div>
          <div className="bg-navy-4 w-full h-[1px] mt-2 mb-3" />
        </div>
      </Panel>
    </BiobotCollapse>
  </div>
);
export default WatchlistMethods;
