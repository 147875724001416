import RadioButtons from "./RadioButtons";

interface ConcentrationSelectorProps {
  value: Concentration;
  onChange: (val: Concentration) => void;
}

const options = [
  { label: "copies/L", value: "1/L" }, // = copies/L
  { label: "copies/mL", value: "1/mL" }, // = copies/mL
] as const;

export type Concentration = (typeof options)[number]["value"];

const ConcentrationSelector = ({
  value,
  onChange,
}: ConcentrationSelectorProps) => (
  <RadioButtons
    value={value}
    onChange={onChange}
    options={options}
    data-test="concentration-selector-group"
  />
);

export default ConcentrationSelector;
