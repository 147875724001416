import { GeoIdentityTransform, GeoPath, GeoProjection, geoPath } from "d3-geo";
import { createContext, useContext, useMemo } from "react";

import useGlobalMemo from "../common/useGlobalMemo";
import { useId } from "../common/util";
import type { GeoSeries } from "./Series";
import { projectionMapping } from "./projection";
import type { BBChartFeatureCollection, ProjectionShape } from "./types";

export interface MapData {
  /** The projection (function) used for this map. */
  projection: GeoProjection | GeoIdentityTransform;
  /** Path generator function (e.g. for graticules). */
  path: GeoPath;
  /** All data series used in this map. */
  series: Record<string, GeoSeries>;
  /** The default features used in FeatureSeries when no features are specified. */
  defaultFeatures?: BBChartFeatureCollection;
  /** Bounding box of default features in the projection's coordinate system. */
  bbox?: [[number, number], [number, number]];
  /** Are all series empty? */
  hasNoData: boolean;
  /** A random prefix for ids in this map (ids must be globally unique). */
  idPrefix: string;
}

const MapDataContext = createContext<MapData | undefined>(undefined);

/** Hook to retrieve the current map's series, features, and projection. */
export const useMapData = () => {
  const value = useContext(MapDataContext);
  if (!value) {
    throw new Error("useMapData must be called inside MapDataProvider");
  }
  return value;
};

export interface MapDataProviderProps
  extends Omit<UseProjectionProps, "projection"> {
  series: GeoSeries[];
  /** Projection, defaults to features.projection or "mercator" if no features */
  projection?: ProjectionShape | null;
  /** Default features, these are also used to fit the extent of the projection */
  features?: BBChartFeatureCollection & { projection?: ProjectionShape };
  /** Fit the projection to the extent of the default features. */
  fit?: boolean;
  children: React.ReactNode;
}

export interface UseProjectionProps {
  scale?: number;
  angle?: number;
  fitFeatures?: BBChartFeatureCollection;
  projection: ProjectionShape;
}

/**
 * Builds a memoized projection.
 *
 */
export const useProjection = ({
  projection,
  angle,
  scale,
  fitFeatures,
}: UseProjectionProps) => {
  const fitExtent = fitFeatures?.fitExtent ?? fitFeatures;
  return useGlobalMemo(() => {
    // Partly lifted from visx-geo/projection/Projection.tsx since all we care
    // about for MapDataContext is the projection, not rendering.
    const maybeCustomProjection =
      typeof projection === "string"
        ? projectionMapping[projection]
        : projection;

    const currProjection = maybeCustomProjection();

    if (angle && currProjection.angle) currProjection.angle(angle);
    if (scale && currProjection.scale) currProjection.scale(scale);
    if (fitExtent && currProjection.fitSize) {
      // We fit the features to a constant size viewport and then apply a
      // transformation to the map itself. This allows us to memoize the
      // projection more aggressively. The projection ends up being used as a
      // memoization key for pre-rendering features, so we want to avoid
      // recalculating the projection as much as possible.
      currProjection.fitSize([1000, 1000], fitExtent);
    }

    const path = geoPath().projection(currProjection);
    const bbox = fitExtent ? path.bounds(fitExtent) : undefined;

    return { path, projection: currProjection, bbox };
  }, [projection, angle, scale, fitExtent]);
};

/** Provider for Map projection and series. */
export const MapDataProvider = ({
  series,
  children,
  features,
  fit = true,
  projection: projection_,
  ...projectionProps
}: MapDataProviderProps) => {
  const projData = useProjection({
    fitFeatures: fit ? features : undefined,
    projection: projection_ ?? features?.projection ?? "mercator",
    ...projectionProps,
  });
  const idPrefix = useId();
  const value = useMemo(
    () => ({
      ...projData,
      series: Object.fromEntries(series.map((s) => [s.seriesKey, s])),
      defaultFeatures: features,
      idPrefix,
      hasNoData: series.every((s) => Object.keys(s.data).length === 0),
    }),
    [projData, series, features, idPrefix],
  );
  return (
    <MapDataContext.Provider value={value}>{children}</MapDataContext.Provider>
  );
};
