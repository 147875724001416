// AntD Theme Customization Docs: https://ant.design/docs/react/customize-theme
// Theme Editor: https://ant.design/theme-editor
import { ThemeConfig } from "antd";

import colors from "../colors";

export const themeConfig = {
  token: {
    borderRadius: 4,
    colorText: colors["navy-7"],
    colorTextHeading: colors["navy-9"],
    colorTextSecondary: colors["navy-7"],
    colorTextTertiary: colors["navy-5"],
    colorTextQuaternary: colors["navy-3"],
    colorBorder: colors["navy-3"],
    colorBorderSecondary: colors["navy-2"],
    colorBgLayout: colors["navy-1"],
    colorBgSpotlight: colors["navy-9"],
    colorPrimary: colors["biobotblue-5"],
    colorSuccess: colors["green-5"],
    colorWarning: colors["orange-5"],
    colorError: colors["red-5"],
    colorInfo: colors["biobotblue-5"],
    wireframe: false,
    fontSize: 14,
    // Fonts -- same as antd default, with GT America prepended
    fontFamily:
      "GT America, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    fontFamilyCode:
      "GT America Mono, SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace",
  },
  components: {
    Button: {
      colorBgContainerDisabled: colors["neutral-1"],
      colorLink: colors["biobotblue-5"],
      colorPrimaryActive: colors["biobotblue-6"],
      colorPrimaryBorder: colors["yellow-5"],
      colorPrimary: colors["yellow-5"],
      colorText: colors["navy-9"],
      colorTextLightSolid: colors["navy-9"],
      colorTextDisabled: colors["neutral-3"],
    },
    Collapse: {
      colorTextHeading: colors["navy-7"],
    },
    DatePicker: {
      colorPrimary: colors["biobotblue-6"],
      colorPrimaryHover: colors["biobotblue-5"],
      colorText: colors["navy-9"],
      colorTextLightSolid: colors["navy-1"],
    },
    Menu: {
      radiusSubMenuItem: 100,
      radiusItem: 100,
      colorSplit: colors["navy-2"],
      colorSubItemBg: "transparent",
    },
    Radio: {
      colorBorder: colors["neutral-3"],
      colorPrimary: colors["yellow-5"],
      colorTextLightSolid: colors["navy-7"],
    },
    Select: {
      colorText: colors["navy-7"],
      colorPrimaryHover: colors["biobotblue-5"],
    },
    Switch: {
      colorPrimary: colors["biobotblue-5"],
      colorPrimaryHover: colors["biobotblue-6"],
    },
    Table: {
      colorFillAlter: "transparent",
      colorTextHeading: colors["navy-3"],
      controlItemBgHover: "rgba(0, 0, 0, 0.04)",
      colorSplit: colors["navy-2"],
      boxShadowSecondary: `
        0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 9px 28px 8px rgba(0, 0, 0, 0.05)
      `,
      colorFillContent: "rgba(0, 0, 0, 0.06)",
      colorFillSecondary: "transparent",
      fontSize: 16,
    },
    Tabs: {
      colorPrimary: colors["navy-9"],
      lineWidth: 0,
      lineHeight: 0.4,
      fontSize: 14,
    },
    Modal: {
      boxShadow: `
        0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 9px 28px 8px rgba(0, 0, 0, 0.05)
      `,
    },
    Tag: {
      borderRadiusSM: 2,
    },
  },
} satisfies ThemeConfig;
