import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

const compounds = [{ label: "Dihydrocodeine", value: "Dihydrocodeine" }];

const aboutTheData = (
  <>
    <p>
      Dihydrocodeine is a semi-synthetic opioid used to treat moderate to severe
      pain and as a cough suppressant. It is available in various forms,
      including tablets, capsules, and syrups. Life-threatening overdoses are
      possible, and prolonged use can result in physical dependence.
      Dihydrocodeine is excreted in urine and moderately stable in wastewater.
    </p>
    <p>
      Medical doses range from 30 mg to 40 mg for standard formulations and from
      60 mg to 120 mg for extended-release formulations, with a maximum daily
      dose of 240 mg.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const DihydrocodeinePage = () => {
  const { data, isLoading } = api.data.dihydrocodeineWastewater.useQuery({
    fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default DihydrocodeinePage;
