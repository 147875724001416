import { Radio, RadioGroupProps, Space } from "antd";
import React from "react";

import useJsonOptions, {
  JsonOptions,
  JsonValue,
} from "src/hooks/useJsonOptions";

interface RadioButtonsOwnProps<T extends JsonValue> {
  value?: T;
  onChange: (val: T) => void;
  options: JsonOptions<T>;
  label?: string | React.ReactNode;
}

export type RadioButtonsProps<T extends JsonValue> = RadioButtonsOwnProps<T> &
  Omit<RadioGroupProps, keyof RadioButtonsOwnProps<T>>;

const RadioButtons = <T extends JsonValue>({
  label,
  value,
  onChange,
  options: rawOptions,
  ...props
}: RadioButtonsProps<T>) => {
  const { toControl, fromControl, options } = useJsonOptions(rawOptions);
  return (
    <Space direction="vertical">
      {typeof label === "string" ? (
        <div className="text-h2 mt-0">{label}</div>
      ) : (
        label
      )}
      <Radio.Group
        buttonStyle="solid"
        optionType="button"
        size="small"
        value={toControl(value)}
        onChange={(e) => onChange(fromControl(e.target.value))}
        options={options}
        {...props}
      />
    </Space>
  );
};

export default RadioButtons;
