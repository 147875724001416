import { Checkbox, ConfigProvider } from "antd";
import { useMemo } from "react";

import { useOrdinalColorScale } from "src/bb-chart";
import useOptions from "src/hooks/useOptions";

/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */

interface CheckboxLegendOwnProps<T extends string | number> {
  value?: T[];
  onChange?: (val: T[]) => void;
  options: readonly ({ label: string; value: T } | T)[];
  colorScale?: (value: string) => string | undefined;
  sort?: boolean;
}

type CheckboxGroupProps = Parameters<typeof Checkbox.Group>[0];

export type CheckboxLegendProps<T extends string | number> =
  CheckboxLegendOwnProps<T> &
    Omit<CheckboxGroupProps, keyof CheckboxLegendOwnProps<T>>;

const CheckboxLegendInner = <T extends string | number>({
  value,
  onChange,
  options: rawOptions,
  colorScale,
  sort,
  ...props
}: CheckboxLegendProps<T>) => {
  const options = useOptions(rawOptions, { sort });
  return (
    <Checkbox.Group value={value} onChange={onChange as any} {...props}>
      {options.map((o) => (
        <ConfigProvider
          key={o.value}
          theme={{
            token: {
              colorPrimary: colorScale?.(o.value.toString()),
            },
          }}
        >
          <Checkbox value={o.value}>{o.label}</Checkbox>
        </ConfigProvider>
      ))}
    </Checkbox.Group>
  );
};

const CheckboxLegendCreateScale = <T extends string | number>(
  props: CheckboxLegendProps<T>,
) => {
  const domain: (string | number)[] = useMemo(
    () => props.options.map((o) => (typeof o === "object" ? o.label : o)),
    [props.options],
  );
  const colorScale = useOrdinalColorScale(domain);
  return <CheckboxLegendInner {...props} colorScale={colorScale} />;
};

/** A legend showing checkboxes for each series.
 *
 * The background of each checkbox is colored according to the series color.
 */
const CheckboxLegend = <T extends string | number>(
  props: CheckboxLegendProps<T>,
) => {
  const Inner = props.colorScale
    ? CheckboxLegendInner
    : CheckboxLegendCreateScale;
  return <Inner {...props} />;
};

export default CheckboxLegend;
