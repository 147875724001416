import { Alert, Table, TableColumnsType } from "antd";
import { Link } from "react-router-dom";

import api, { type ApiData } from "src/api";
import PageTitle from "src/components/PageTitle";
import {
  filterEqual,
  filterOptions,
  numberSorter,
  renderTimestampLocal,
  stringSorter,
} from "src/util/table";

type NotificationBatchListDatum = ApiData<"notification.batchList">[number];

const NotificationBatchListBody = () => {
  const {
    data: notificationBatchList,
    isLoading,
    isError,
  } = api.notification.batchList.useQuery({});

  if (isError) return <Alert message="Error loading notifications" />;

  const tableData = notificationBatchList?.data ?? [];

  const columns: TableColumnsType<NotificationBatchListDatum> = [
    {
      title: "Notification type",
      dataIndex: [],
      key: "notification_type",
      filters: filterOptions(tableData, "notification_type"),
      filterSearch: true,
      onFilter: filterEqual("notification_type"),
      sorter: stringSorter("notification_type"),
      render: (d: NotificationBatchListDatum) => (
        <Link to={`/notifications/batch/${d.key}`}>{d.notification_type}</Link>
      ),
    },
    {
      title: "Scheduled",
      dataIndex: "scheduled_at",
      key: "scheduled_at",
      sorter: stringSorter("scheduled_at"),
      defaultSortOrder: "descend",
      render: renderTimestampLocal,
    },
    {
      title: "Total",
      dataIndex: "total_count",
      sorter: numberSorter("total_count"),
    },
    {
      title: "Sent",
      dataIndex: "sent_count",
      sorter: numberSorter("sent_count"),
    },
    {
      title: "Failed",
      dataIndex: "failed_count",
      sorter: numberSorter("failed_count"),
      onCell: (record) =>
        record.failed_count > 0 ? { className: "bg-coral-2" } : {},
    },
    {
      title: "Scheduled",
      dataIndex: "scheduled_count",
      sorter: numberSorter("scheduled_count"),
    },
    {
      title: "Sending",
      dataIndex: "sending_count",
      sorter: numberSorter("sending_count"),
    },
  ];

  return (
    <div className="space-y-4">
      <p className="italic">Timestamps are displayed in your time zone.</p>
      <Table
        rowKey="key"
        scroll={{ x: "max-content" }}
        loading={isLoading}
        columns={columns}
        pagination={{
          defaultPageSize: 10,
        }}
        dataSource={tableData}
      />
    </div>
  );
};

const NotificationBatchListPage = () => (
  <>
    <PageTitle title="Notifications" />
    <NotificationBatchListBody />
  </>
);

export default NotificationBatchListPage;
