import { useEffect, useRef } from "react";

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

/**
 * Hook for using IntersectionObserver. Takes a callback and opts (same as
 * IntersectionObserver), and returns a ref that should be attached to the
 * element you want to observe.
 *
 * @example
 * // This renders a div that displays its own intersectionRect
 * const [rect, setRect] = useState();
 * const ref = useIntersectionObserver((entries) => {
 *   setRect(entries[0].intersectionRect)
 * })
 * return <div ref={ref}>{JSON.stringify(rect)}</div>
 */
const useIntersectionObserver = <TRef extends HTMLElement = any>(
  callback: IntersectionObserverCallback,
  opts?: IntersectionObserverInit,
) => {
  const ref = useRef<TRef | null>(null);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const observer = new IntersectionObserver(callback, opts);
    observer.observe(ref.current);
    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [
    ref,
    callback,
    opts?.threshold?.toString(),
    opts?.root,
    opts?.rootMargin,
  ]);
  return ref;
};

export default useIntersectionObserver;
