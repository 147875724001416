import { useAuth0 } from "@auth0/auth0-react";
import {
  QueryFunctionContext,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "react-query";

import usePermissionOverride from "./usePermissionOverride";

// Require query keys to be a 1-element array with an object.
type QK<T = object> = readonly [T];

// Oof, useQuery has 4 generics that go all the way down, so this is a pretty
// obnoxious one to type.
interface Auth0QueryFunctionContext<TQueryKey extends QK = QK>
  extends QueryFunctionContext<TQueryKey> {
  accessToken: string;
  permissionOverride?: string;
}

type Auth0QueryFunction<T = unknown, TQueryKey extends QK = QK> = (
  context: Auth0QueryFunctionContext<TQueryKey>,
) => T | Promise<T>;

interface UseAuth0QueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QK = QK,
> extends Omit<
    UseQueryOptions<
      TQueryFnData,
      TError,
      TData,
      QK<TQueryKey[0] & { permissionOverride?: string }>
    >,
    "queryFn" | "queryKey"
  > {
  queryKey: TQueryKey; // this is optional by default, make it required
  queryFn: Auth0QueryFunction<
    TQueryFnData,
    QK<TQueryKey[0] & { permissionOverride?: string }>
  >;
  customerOverride?: boolean;
}

/**
 * A type used for adding an extra options param to a custom react-query hook.
 *
 * @example
 * const useMyQuery = <TData = MyQueryDefault>(
 *   options: UseQueryExtraOptions<MyQueryDefault, TData> = {}
 * ) =>
 *   useQuery({
 *     queryKey: // whatever,
 *     queryFn: // whatever,
 *     ...options
 *   });
 */
export interface UseQueryExtraOptions<
  TQueryFnData = unknown,
  TData = TQueryFnData,
> extends Omit<
    UseAuth0QueryOptions<TQueryFnData, unknown, TData>,
    // These are all the options that rely on TError or TQueryKey, which are not
    // used as generic params in UseQueryExtraOptions for simplicity's sake
    | "query"
    | "queryFn"
    | "queryKey"
    | "refetchInterval"
    | "refetchOnMount"
    | "refetchOnReconnect"
    | "refetchOnWindowFocus"
    | "useErrorBoundary"
  > {
  // some of these can be simple values though, so allow those
  refetchInterval?: number;
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
  useErrorBoundary?: boolean;
}

/**
 * Like react-query's {@link useQuery}, but with auth-related enhancements:
 * enhancements:
 * - includes the auth0 `accessToken` the queryFn argument object.
 * - adds permissionOverride as a queryKey.
 * - overrides may be disabled by passing { customerOverride: false } in the
 *   query option object.
 */
const useAuth0Query = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QK = QK,
>(
  options: UseAuth0QueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> => {
  const { queryFn, queryKey, customerOverride, ...opts } = options;
  const { getAccessTokenSilently } = useAuth0();
  const permissionOverride = usePermissionOverride(customerOverride !== false);
  return useQuery({
    async queryFn(args) {
      const accessToken = await getAccessTokenSilently();
      return queryFn({ ...args, accessToken });
    },
    queryKey: [{ ...queryKey[0], permissionOverride }],
    ...opts,
  });
};
export default useAuth0Query;
