import { DatePicker } from "antd";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";

import type { IsoDate } from "~api/types/util";

export interface DateStringPickerProps
  extends Omit<DatePickerProps, "value" | "onChange"> {
  value?: IsoDate | null;
  onChange?: (value: IsoDate | null) => void;
}

/** Like antd's DatePicker, but value and onChange use strings. */
const DateStringPicker = ({
  value,
  onChange,
  ...props
}: DateStringPickerProps) => (
  <DatePicker
    value={value ? dayjs(value) : null}
    onChange={(val) => {
      if (val) {
        onChange?.(val.format("YYYY-MM-DD") as IsoDate);
      } else {
        onChange?.(null);
      }
    }}
    {...props}
  />
);

export type DateStringRange = [IsoDate | null, IsoDate | null];

export interface DateStringRangePickerProps
  extends Omit<RangePickerProps, "value" | "onChange"> {
  value?: DateStringRange | null;
  onChange?: (value: DateStringRange | null) => void;
}

/** Like antd's DatePicker.RangePicker, but value and onChange use strings. */
const DateStringRangePicker = ({
  value,
  onChange,
  ...props
}: DateStringRangePickerProps) => (
  <DatePicker.RangePicker
    value={value ? [dayjs(value[0]), dayjs(value[1])] : null}
    onChange={(vals) => {
      if (vals) {
        onChange?.(vals.map((d) => d?.format("YYYY-MM-DD")) as DateStringRange);
      } else {
        onChange?.(null);
      }
    }}
    {...props}
  />
);

DateStringPicker.RangePicker = DateStringRangePicker;

export default DateStringPicker;
