import AboutTheData from "src/components/AboutTheData";
import BiobotCard from "src/components/BiobotCard";
import { Concentration } from "src/components/GraphElements/ConcentrationSelector";
import useId from "src/hooks/useId";
import useUniqueValues from "src/hooks/useUniqueValues";

import PCRSummaryBody from "../PCRSummaryBody";
import { PCRDatum } from "../types";
import CovidLinks from "./CovidLinks";

export interface CovidSummaryCardProps {
  data: PCRDatum[];
  setConcentrationUnit: (value: Concentration) => void;
  concentrationUnit: Concentration;
}
const CovidSummaryCard = ({
  data: rawData,
  setConcentrationUnit,
  concentrationUnit,
}: CovidSummaryCardProps) => {
  const titleId = useId();
  const subtitleId = useId();
  const [locationTypes] = useUniqueValues(rawData, ["location_type"]);
  return (
    <BiobotCard
      title="Compare SARS-CoV-2 Across Locations"
      subtitle="Discover trends in SARS-CoV-2 with this interactive time series graph.
  Select multiple locations to look at trends over time and across
  locations. This visualization supports monitoring and comprehension of
  SARS-CoV-2 dynamics in wastewater."
      titleId={titleId}
      subtitleId={subtitleId}
    >
      <PCRSummaryBody
        rawData={rawData}
        concentrationUnit={concentrationUnit}
        setConcentrationUnit={setConcentrationUnit}
        aria-labelledby={titleId}
        aria-describedby={subtitleId}
      />
      {locationTypes.filter(Boolean).length > 0 && (
        <AboutTheData className="mt-8">
          <CovidLinks locationTypes={locationTypes} />
        </AboutTheData>
      )}
    </BiobotCard>
  );
};

export default CovidSummaryCard;
