import { Alert, Spin, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import api, { getErrorMessage } from "src/api";
import PageTitle from "src/components/PageTitle";

import CustomerForm from "./CustomerForm";
import { CustomerFormData } from "./types";
import { customerFormDataToPayload, customerPayloadToFormData } from "./utils";

interface Params {
  customer_code: string;
}

const EditCustomerPage = () => {
  const updateCustomer = api.customer.update.useMutation();
  const { customer_code } = useParams<keyof Params>() as Params;
  const navigate = useNavigate();

  const { data: customer, isLoading } = api.customer.get.useQuery({
    customer_code,
  });

  if (isLoading) {
    return <Spin size="small" />;
  } else if (!customer) {
    return (
      <Alert message="Error loading customer" type="error" className="py-0" />
    );
  }

  const onFinish = (formValues: CustomerFormData) => {
    const payload = customerFormDataToPayload(formValues);
    updateCustomer.mutate(payload, {
      onError: (error) => {
        message.error(
          `Failed to create customer: ${getErrorMessage(error)}`,
          10,
        );
      },
      onSuccess: () => {
        message.success(
          `Successfully updated customer ${formValues.customer_name}`,
        );
        navigate("..", { relative: "path" });
      },
    });
  };

  return (
    <>
      <PageTitle
        title={`Edit: ${customer.data.customer_name}`}
        back={{ to: "..", relative: "path" }}
      />
      <CustomerForm
        onFinish={onFinish}
        onCancel={() => navigate("..", { relative: "path" })}
        initialValues={customerPayloadToFormData(customer.data)}
      />
    </>
  );
};

export default EditCustomerPage;
