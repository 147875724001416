import {
  type UiPermission as UiPermission_,
  useCustomer,
} from "src/context/Customer";

export type UiPermission = UiPermission_;

const useUiPermissions = (): UiPermission_[] => {
  const { user_permissions } = useCustomer();
  return user_permissions ?? [];
};

export default useUiPermissions;
