import { omit, pick } from "lodash";
import React from "react";

import {
  ChartTheme,
  ChartThemeProvider,
  useChartTheme,
} from "../common/ChartTheme";
import { Margin, SvgSizeProvider, useSvgSize } from "../common/SvgSize";
import { TooltipProvider, TooltipWrapper } from "../common/Tooltip";
import { AriaChartProps } from "../xy/Chart";
import { MapDataProvider, MapDataProviderProps, useMapData } from "./MapData";
import { AnyGeoSeries } from "./Series";
import { GeoTooltipComponent, GeoTooltipHoverDetector } from "./Tooltip";
import { mapTransform } from "./util";

/* eslint-disable @typescript-eslint/no-explicit-any */

interface MapBodyProps<TSeries extends AnyGeoSeries[]> extends AriaChartProps {
  className?: string;
  tooltip?: GeoTooltipComponent<TSeries, any>;
  overlay?: React.ReactNode;
  children?: React.ReactNode;
}

const MapBody = <TSeries extends AnyGeoSeries[]>({
  className,
  children,
  overlay,
  tooltip,
  ...ariaProps
}: MapBodyProps<TSeries>) => {
  const { svgWidth, svgHeight, width, height, margin } = useSvgSize();
  const { bbox } = useMapData();
  const { tooltip: ttTheme, background } = useChartTheme();
  const transform = mapTransform({ bbox, width, height, margin });

  const TTProvider = tooltip ? TooltipProvider : React.Fragment;

  return (
    <TTProvider>
      <div className="relative">
        <svg
          width={svgWidth}
          height={svgHeight}
          className={className}
          {...ariaProps}
          paintOrder="stroke fill"
        >
          <g transform={transform}>
            {background && (
              <rect width={width} height={height} fill="none" {...background} />
            )}
            {tooltip ? (
              <GeoTooltipHoverDetector>{children}</GeoTooltipHoverDetector>
            ) : (
              children
            )}
          </g>
        </svg>
        {overlay && (
          <div className="absolute inset-0 pointer-events-none">{overlay}</div>
        )}
        {tooltip && (
          <TooltipWrapper tooltip={tooltip} className={ttTheme?.className} />
        )}
      </div>
    </TTProvider>
  );
};

const mapDataProps = [
  "features",
  "series",
  "projection",
  "angle",
  "scale",
  "fit",
] as const;

export interface MapProps<TSeries extends AnyGeoSeries[]>
  extends AriaChartProps,
    Pick<MapDataProviderProps, (typeof mapDataProps)[number]>,
    MapBodyProps<TSeries> {
  series: [...TSeries];
  theme?: ChartTheme;
  width?: number;
  height?: number;
  margin?: Margin;
  children?: React.ReactNode;
}

const defaultMargin = {
  top: 10,
  left: 10,
  right: 10,
  bottom: 10,
};

/**
 * The standard Map component. This includes all geo context providers
 * (size, data, tooltip, theme) and renders a ChartBody child.
 *
 * @example
 * // a simple line chart with a tooltip
 * const MyMap = () => {
 *   const someData: { state: string, value: number }[] = fetchData();
 *   const someColorScale = scaleLinear({
 *     domain: [1, 100],
 *     range: ["red", "blue"],
 *   });
 *   const series = useGeoSeries({
 *     data: someData,
 *     featureId: "state",
 *     value: "value",
 *   });
 *   return (
 *     <Map
 *       series={[series]}
 *       features={usStates}
 *       title="A short title"
 *       desc="This is a longer description for what we're looking at"
 *     >
 *       <FeatureSeries colorScale={someColorScale} />
 *     </Map>
 *   );
 * };
 */
export const Map = <TSeries extends AnyGeoSeries[]>(
  props: MapProps<TSeries>,
) => {
  const { theme, width, height, margin, children, ...restProps } = props;
  if (theme) {
    return (
      <ChartThemeProvider value={theme}>
        <Map {...omit(props, "theme")} />
      </ChartThemeProvider>
    );
  }
  return (
    <SvgSizeProvider
      width={width}
      height={height}
      margin={margin ?? defaultMargin}
    >
      <MapDataProvider {...pick(restProps, mapDataProps)}>
        <MapBody {...omit(restProps, mapDataProps)}>{children}</MapBody>
      </MapDataProvider>
    </SvgSizeProvider>
  );
};

export default Map;
