import { isEqual } from "lodash";

import useCustomMemo from "./useCustomMemo";

const cmpDataAndKeys = (a: unknown[], b: unknown[]) =>
  a[0] === b[0] && isEqual(a[1], b[1]);

/**
 * Calculates unique values in a dataset, given a list of keys of interest.
 *
 * @example
 * const [locationNames, locationTypes] = useUniqueValues(data, [
 *   "sampling_location_name",
 *   "location_type"
 * ])
 */
const useUniqueValues = <T, K extends keyof T, KS extends [K, ...K[]]>(
  data: T[],
  keys: KS,
) =>
  useCustomMemo(
    () => {
      const sets = keys.map(() => new Set());
      data.forEach((d) => {
        sets.forEach((set, idx) => set.add(d[keys[idx]]));
      });
      return sets.map((set) => [...set]) as {
        [I in keyof KS]: KS[I] extends keyof T ? T[KS[I]][] : never;
      };
    },
    [data, keys],
    cmpDataAndKeys,
  );

export default useUniqueValues;
