import { DependencyList, useEffect, useState } from "react";

import createCache from "./cache";

const cache = createCache({
  gcTimeout: 1000 * 60 * 6, // 5 minutes
});

/** Like useMemo, but uses a cache shared by all components. */
const useGlobalMemo = <T>(fn: () => T, deps: DependencyList) => {
  const [val, setVal] = useState(() => cache.fetch(fn, deps));
  useEffect(
    () => {
      setVal(cache.fetch(fn, deps));
      cache.incRef(deps);
      return () => cache.decRef(deps);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
  return val;
};

export default useGlobalMemo;
