import { ParentSize } from "@visx/responsive";
import { createContext, useContext, useMemo } from "react";

import { useId } from "./util";

export interface Margin {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export interface SizeProps {
  width?: number;
  height?: number;
  margin: Margin;
}

export interface SvgSize {
  svgWidth: number;
  svgHeight: number;
  width: number;
  height: number;
  margin: Margin;
  /** A random prefix for ids in this chart (ids must be globally unique). */
  idPrefix: string;
}

const SvgSizeContext = createContext<SvgSize | undefined>(undefined);

export const useSvgSize = () => {
  const value = useContext(SvgSizeContext);
  if (!value) {
    throw new Error("useSvgSize must be called inside SvgSizeProvider");
  }
  return value;
};

export interface SvgSizeProviderProps {
  width?: number;
  height?: number;
  margin: Margin;
  children: React.ReactNode;
}

const SvgSizeProviderInner = ({
  width: svgWidth,
  height: svgHeight,
  margin,
  children,
}: Required<SvgSizeProviderProps>) => {
  const { top, left, right, bottom } = margin;
  const idPrefix = useId();
  const value = useMemo(() => {
    const chartWidth = Math.max(0, svgWidth - left - right);
    const chartHeight = Math.max(0, svgHeight - top - bottom);
    return {
      idPrefix,
      svgWidth,
      svgHeight,
      width: chartWidth,
      height: chartHeight,
      margin: { top, left, right, bottom },
    };
  }, [idPrefix, svgWidth, svgHeight, top, left, right, bottom]);
  if (value.width === 0 || value.height === 0) {
    return null;
  }
  return (
    <SvgSizeContext.Provider value={value}>{children}</SvgSizeContext.Provider>
  );
};

/**
 * Provider for chart dimensions. This is typically the outermost chart
 * context. If width and height are not given, size is calculated based on the
 * parent size.
 */
export const SvgSizeProvider = ({
  width,
  height,
  margin,
  children,
}: SvgSizeProviderProps) => {
  if (width != null && height != null) {
    return (
      <SvgSizeProviderInner width={width} height={height} margin={margin}>
        {children}
      </SvgSizeProviderInner>
    );
  }
  return (
    <ParentSize debounceTime={100}>
      {({ width: parentWidth, height: parentHeight }) => (
        <SvgSizeProviderInner
          width={width ?? parentWidth}
          height={height ?? parentHeight}
          margin={margin}
        >
          {children}
        </SvgSizeProviderInner>
      )}
    </ParentSize>
  );
};
