import { Spin } from "antd";

import api from "src/api";
import FatalError from "src/components/FatalError";
import useRoleCheck from "src/hooks/useRoleCheck";
import {
  BooleanParam,
  StringParam,
  useSessionQueryParams,
} from "src/util/queryParams";

import {
  CustomerContext,
  CustomerOverrideContext,
  CustomerOverrideUpdateContext,
  emptyCustomerOverride,
} from "./Context";

const CustomerProvider = ({
  children,
  disabled,
}: {
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  const query = api.customer.config.useQuery({}, { enabled: !disabled });

  if (query.isLoading) {
    return <Spin />;
  } else if (query.isError) {
    return <FatalError message="Error loading customer information" />;
  } else if (!query.data) {
    return <Spin />;
  } else {
    return (
      <CustomerContext.Provider value={query.data.data}>
        {children}
      </CustomerContext.Provider>
    );
  }
};

const customerOverrideDefaults = {
  customerCode: process.env.REACT_APP_DEMO_CUSTOMER || undefined,
  customerMode: false,
};

/**
 * Resets the customer override provider to its "empty" value (i.e. as if it
 * were not present). This is used in internal admin pages so that api queries
 * do not include a permissionOverride on the customer.
 *
 * This is necessary because we need access to AdminCustomerProvider for the
 * MainLayout and NavMenu (which need a current customer, and allow customer
 * switching for admins). But if the page rendered in MainLayout is an internal
 * admin page, we do _not_ want a current customer in that context.
 */
export const CustomerOverrideProviderReset = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <CustomerOverrideUpdateContext.Provider value={null}>
    <CustomerOverrideContext.Provider value={emptyCustomerOverride}>
      {children}
    </CustomerOverrideContext.Provider>
  </CustomerOverrideUpdateContext.Provider>
);

const AdminCustomerProvider = ({ children }: { children: React.ReactNode }) => {
  const [value, setValue] = useSessionQueryParams(
    {
      customerCode: StringParam,
      customerMode: BooleanParam,
    },
    customerOverrideDefaults,
  );
  return (
    <CustomerOverrideUpdateContext.Provider value={setValue}>
      <CustomerOverrideContext.Provider value={value}>
        <CustomerProvider disabled={!value.customerCode}>
          {children}
        </CustomerProvider>
      </CustomerOverrideContext.Provider>
    </CustomerOverrideUpdateContext.Provider>
  );
};

/** Customer provider with admin override. */
const Provider = ({ children }: { children: React.ReactNode }) => {
  const isAdmin = useRoleCheck("biobot-admin");
  if (isAdmin) {
    return <AdminCustomerProvider>{children}</AdminCustomerProvider>;
  } else {
    return <CustomerProvider>{children}</CustomerProvider>;
  }
};

export default Provider;
