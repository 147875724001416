import type { Accessor } from "../common/accessor";
import type { GapFn } from "../types";
import type { Series } from "./Series";

interface BarPathParams<Datum> {
  /** Accessor for the center of the bar */
  x: Accessor<Datum, number>;
  /** Accessor for the top of the bar */
  y: Accessor<Datum, number>;
  /** Accessor for the bottom of the bar */
  y0: Accessor<Datum, number>;
  /** Defined accessor */
  defined?: Accessor<Datum, boolean>;
  /** Width of each bar in px */
  width: number;
}

/** Creates a single path representing a series of bars. */
export const barPath =
  <Datum>({ x, y, y0, defined, width }: BarPathParams<Datum>) =>
  (data: Datum[]) =>
    data
      .map((d) => {
        if (!defined || defined(d)) {
          const midX = x(d);
          const top = y(d);
          const bottom = y0(d);
          return `
            M ${midX - width / 2} ${bottom}
            V ${top}
            H ${midX + width / 2}
            V ${bottom}
            H ${midX - width / 2}
          `;
        } else {
          return "";
        }
      })
      .join(" ");

export const seriesDataWithGaps = <T>(
  series: Series<T>,
  gapFn?: GapFn | number,
): (T | null)[] => {
  if (gapFn == null) {
    return series.data;
  }
  const wantsGap =
    typeof gapFn === "function"
      ? gapFn
      : (x0: number, x1: number) => Math.abs(x1 - x0) >= gapFn;
  const { data, x, defined } = series;
  // setup the first iteration
  let d0 = data[0];
  let defined0 = defined(d0);
  let x0 = defined0 ? x(d0) : null;
  const ret: (T | null)[] = [d0];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < data.length; ++i) {
    // see if we need a gap between these two points
    const d1 = data[i];
    const defined1 = defined(d1);
    const x1 = defined1 ? x(d1) : null;
    if (defined0 && defined1 && wantsGap(x0, x1, d0, d1)) {
      ret.push(null);
    }
    ret.push(d1);
    // setup the next iteration
    d0 = d1;
    defined0 = defined1;
    x0 = x1;
  }
  return ret;
};
