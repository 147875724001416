import type { TickFormatter } from "@visx/axis";
import { extent } from "d3-array";
import {
  format as d3format,
  formatPrefix,
  precisionFixed,
  precisionPrefix,
} from "d3-format";

/** Returns true if any of `ticks` are in the same month. */
export const hasDuplicateMonth = (ticks: { value: Date }[]) => {
  const months = new Set<string>();
  return ticks.some((t) => {
    const year = t.value.getUTCFullYear();
    const month = t.value.getUTCMonth();
    const monthYear = `${year}-${month}`;
    if (months.has(monthYear)) {
      return true;
    }
    months.add(monthYear);
    return false;
  });
};

interface DateFormatterArgs {
  byDay: TickFormatter<Date>;
  byMonth: TickFormatter<Date>;
}

/**
 * Creates a date tick formatter that adapts based on the range in the data. If
 * there are multiple ticks in the same month, uses the `byDay` formatter,
 * otherwise uses the `byMonth` formatter.
 */
export const dateFormatter = ({ byDay, byMonth }: DateFormatterArgs) => {
  const format: TickFormatter<Date> = (value, index, allTicks) => {
    if (hasDuplicateMonth(allTicks)) {
      return byDay(value, index, allTicks);
    } else {
      return byMonth(value, index, allTicks);
    }
  };
  return format;
};

const replaceBillion = (fmt: (x: number) => string) => (x: number) =>
  fmt(x).replace("G", "B");

// SI units, 2 sig figs
const fmtLgNumber = replaceBillion(d3format(",.2~s"));
// round to whole number, commas separating thousands
const fmtMdNumber = d3format(",~d");
// fixed precision, 2 decimals
const fmtSmNumber = d3format(",.2f");

/** Picks a format for numbers displayed by themselves (e.g. tooltips). */
const pickNumberFormatter = (value: number) => {
  if (value >= 10_000) {
    return fmtLgNumber;
  } else if (value >= 100) {
    return fmtMdNumber;
  } else {
    return fmtSmNumber;
  }
};

// SI formats by number of sig figs
const fmtTickK = [formatPrefix(",.0", 1e3), formatPrefix(",.1", 1e3)];
const fmtTickM = [formatPrefix(",.0", 1e6), formatPrefix(",.1", 1e6)];
const fmtTickB = [formatPrefix(",.0", 1e9), formatPrefix(",.1", 1e9)].map(
  replaceBillion,
);

// fixed precision formats by number of decimal places
const fmtTickFixed = [d3format(",.0f"), d3format(",.1f"), d3format(",.2f")];

/** Picks a format for ticks. */
const pickTickFormatter = (ticks: { value: number }[]) => {
  const [min, max] = extent(ticks, (x) => Math.abs(x.value)) as [
    number,
    number,
  ];
  const step = (max - min) / (ticks.length - 1);
  if (max >= 10_000) {
    // Above 10k we use SI notation with up to 1 decimal. Note that
    // fmtTickK/M/B use a fixed SI unit for each tick, rather than fmtLgNumber
    // which would use a different unit per tick. This means we end up with:
    // - [2M, 1.5M, 1M, 0.5M] instead of [2M, 1.5M, 1M, 500k]
    // - [1M, 0.8M, 0.6M, 0.4M, 0.2M] instead of [1M, 800k, 600k, 400k, 200k]
    if (max >= 1_000_000_000) {
      const precision = Math.min(2, precisionPrefix(step, 1e9));
      return fmtTickB[precision];
    } else if (max >= 1_000_000) {
      const precision = Math.min(2, precisionPrefix(step, 1e6));
      return fmtTickM[precision];
    } else {
      const precision = Math.min(2, precisionPrefix(step, 1e3));
      return fmtTickK[precision];
    }
  } else {
    // Below 10k we use fixed precision, between 0 and 2 decimal places
    // depending on the step size.
    const precision = Math.min(2, precisionFixed(step));
    return fmtTickFixed[precision];
  }
};

/**
 * Formats numbers for tooltips and ticks.
 *
 * - Zero is always 0 (no decimals)
 * - >= 10k we use SI units with up to 2 decimal place
 *   - we use 'B' for Billion instead of 'G' for Giga
 * - >= 100 we round to whole numbers
 * - < 100 we show 2 decimal places
 *
 * Tick notes:
 * - Ticks use the same level of precision for a given axis (e.g. 1.5 and 1.0,
 *   not 1.5 and 1).
 * - When using SI units, ticks use the same unit for a given axis (e.g.
 *   there's never a mix of M and k).
 */
export const numberFormatter = (
  value: number,
  // This function can be used as both a number formatter, which has the type
  // (value) => string and a tick formatter, which is (value, index, allTicks) => string
  _index?: number,
  allTicks?: { value: number; index: number }[],
) => {
  if (value === 0) {
    return "0";
  }
  if (allTicks && allTicks.length > 0) {
    return pickTickFormatter(allTicks)(value);
  } else {
    return pickNumberFormatter(value)(value);
  }
};
