import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";
import { renameAvgColumns } from "./LCMSCommandCenter/util";

export const compounds = [
  { label: "Fentanyl", value: "Fentanyl" },
  { label: "Norfentanyl", value: "Norfentanyl" },
];

const aboutTheData = (
  <>
    <p>
      Fentanyl is a highly-potent Schedule II synthetic opioid that has both
      medical and illicit uses. Both fentanyl and its major metabolite,
      norfentanyl, are excreted in urine and are stable in wastewater.
      Wastewater catchments that capture waste from hospitals or other medical
      treatment facilities may have fentanyl contributions from both prescribed
      and illicit fentanyl use. Fentanyl may be combined with other substances
      for illicit use.
    </p>
    <p>
      In medical settings, fentanyl can be administered via intravenous or
      intramuscular injections, skin patches, nasal sprays, or soluble films
      designed to dissolve in the mouth. Illicitly, however, fentanyl is most
      often injected, or taken orally via fentanyl-contaminated pills. A typical
      therapeutic dose of fentanyl is 0.05 mg, whereas illicit doses tend to be
      0.1 mg or more depending on individual tolerance. A lethal dose of
      fentanyl is 2 mg.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const FentanylPage = () => {
  const { data, isLoading } = api.data.fentanylWastewater.useQuery({
    fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  const { data: avgData, isLoading: avgIsLoading } =
    api.data.fentanylNational.useQuery(
      { fields: ["target_name", "date", "estimated_consumption_weekly_avg"] },
      { select: renameAvgColumns },
    );

  const combinedData = [...(avgData?.data ?? []), ...(data?.data ?? [])];

  return (
    <LCMSCommandCenter
      data={combinedData}
      isLoading={isLoading || avgIsLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default FentanylPage;
