import { utcFormat } from "d3-time-format";

import type { IsoDate } from "~api/types/util";

import { dateFormatter } from "src/components/GraphElements/formatters";

export interface LCMSDatum {
  sampling_location_name: string; // grouping and labels
  sample_date: IsoDate; // x
  estimated_consumption: number | null; // y
  compound_name: string; // label
  location_type: "community" | "facility" | null;
}

export interface Compound {
  label: string;
  value: string;
  lowerLabel?: string;
  defaultSelected?: boolean;
}

// TODO(compat): remove this
export type HRSDatum = LCMSDatum;

export interface AvgDatum {
  date: IsoDate; // x
  estimated_consumption_weekly_avg: number | null; // y
  target_name: string; // label
}

// Accessors
export const getSampleDate = (d: HRSDatum) => new Date(d.sample_date);

// Formatters
export const fmtDateLong = utcFormat("%b %d, %Y");

export const xTickFormat = dateFormatter({
  byDay: utcFormat("%b %-d '%y"), // "Mar 9 '23"
  byMonth: utcFormat("%b '%y"), // "Mar '23"
});

// Allow average data to be merged with kit data so they can both be represented in the same chart
export const renameAvgColumns = ({ data }: { data: AvgDatum[] }) => ({
  data: data.map((d) => ({
    sample_date: d.date,
    compound_name: d.target_name,
    estimated_consumption: d.estimated_consumption_weekly_avg,
    sampling_location_name: "US National Average",
    location_type: "community" as const,
  })),
});
