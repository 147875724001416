interface Auth0RoleLinkProps {
  roleId: string;
}

const Auth0RoleLink = ({ roleId }: Auth0RoleLinkProps) => {
  if (roleId === "unknown") {
    return <span className="text-coral-6">{roleId}</span>;
  } else {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={`${process.env.REACT_APP_AUTH0_MANAGEMENT_DOMAIN}/roles/${roleId}/settings`}
      >
        {roleId}
      </a>
    );
  }
};

export default Auth0RoleLink;
