import type { AnyD3Scale } from "@visx/scale";
import { extent } from "d3-array";
import { lab as colorLab } from "d3-color";
import { minBy } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useDeepCompareMemoize } from "use-deep-compare-effect";

import type { ScaleConfig } from "../types";

const ordinalScales = new Set(["band", "point", "ordinal"]);

/**
 * Computes the domain for the given scale type. For ordinal scales, returns
 * all distinct values. For continuous scales, returns the extent (min, max).
 */
export const domainForScaleType = <T, R extends string | number | Date>(
  scaleType: ScaleConfig["type"],
  data: T[],
  accessor: (d: T, idx: number, arr: T[]) => R | null | undefined,
) => {
  if (ordinalScales.has(scaleType)) {
    const domain: R[] = [];
    const seen = new Set();
    data.forEach((d, idx, arr) => {
      const val = accessor(d, idx, arr);
      if (val != null && !seen.has(val)) {
        seen.add(val);
        domain.push(val);
      }
    });
    return domain;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return extent(data as any, accessor as any) as
      | [R, R]
      | [undefined, undefined];
  }
};

/**
 * Inverts any scale. For ordinal scales, which can't be natively inverted,
 * returns the domain value closest to the input.
 */
export const invertScale = <T extends AnyD3Scale>(
  scale: T,
  value: ReturnType<T>,
) => {
  if ("invert" in scale) {
    return scale.invert(value);
  } else if ("bandwidth" in scale) {
    const width = scale.bandwidth();
    // find the closest value in the domain to the cursor
    return minBy(scale.domain(), (v) => {
      const val = scale(v);
      if (val === undefined) {
        return Number.MAX_VALUE;
      }
      const center = val + width / 2;
      return Math.abs(value - center);
    });
  }
  // otherwise we can't handle this kind of scale
  return undefined;
};

/** useMemo with deep instead of shallow comparison. */
export const useDeepCompareMemo: typeof useMemo = (f, deps) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(f, useDeepCompareMemoize(deps));

/** Generates a stable random id. This is built-in in React 18. */
export const useId = () =>
  useState(() => Math.random().toString(32).slice(2))[0];

/** useMemo factory function for custom comparators */
export const customMemoFactory =
  <T extends readonly unknown[]>(cmp: (a: T, b: T) => boolean) =>
  <Ret>(f: () => Ret, deps: T) => {
    // taken from use-deep-compare-effect
    const ref = useRef(deps);
    const signalRef = useRef(0);
    if (!cmp(ref.current, deps)) {
      ref.current = deps;
      signalRef.current += 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(f, [signalRef.current]);
  };

const shallowEqual = (a: unknown[], b: unknown[]) =>
  a.length === b.length && a.every((elem, idx) => elem === b[idx]);

/** Like useMemo, but flattens the dependency array 1 level. */
export const useFlatMemo = customMemoFactory((a, b) =>
  shallowEqual(a.flat(), b.flat()),
);

export const brightnessThreshold = 66;

export const isDarkColor = (colorStr: string) => {
  const c = colorLab(colorStr);
  return c.l < brightnessThreshold;
};
