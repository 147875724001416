/* eslint-disable @typescript-eslint/no-explicit-any */

export type Accessor<Datum, TOut = any> = (d: Datum) => TOut;
export type DefinedAccessor<Datum> = Accessor<Datum, boolean>;

export type AccessorReturn<
  Datum,
  Val,
  Default = never,
> = Val extends keyof Datum
  ? Datum[Val]
  : Val extends Accessor<Datum>
    ? ReturnType<Val>
    : Default;

export const makeAccessor = <Datum>(val: Accessor<Datum> | keyof Datum) =>
  typeof val === "function" ? val : (d: Datum) => d[val];

export const makeDefinedAccessor = <Datum>(
  defined: Accessor<Datum, boolean> | keyof Datum | undefined,
  valAccessor: Accessor<Datum>,
) => {
  if (typeof defined === "function") {
    return defined;
  } else if (defined) {
    return (d: Datum) => d[defined] != null;
  } else {
    return (d: Datum) => valAccessor(d) != null;
  }
};
