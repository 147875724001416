import { StringLike, scaleOrdinal } from "@visx/scale";
import type { CurveFactory } from "d3-shape";
import { createContext, useContext, useMemo } from "react";

import colors from "src/colors";

import type { GapFn } from "../types";
import { Accessor, makeAccessor } from "./accessor";

export const categoricalColors = [
  colors["biobotblue-5"],
  colors["navy-7"],
  colors["green-5"],
  colors["yellow-5"],
  colors["purple-6"],
  colors["aqua-6"],
  colors["orange-5"],
  colors["navy-6"],
  colors["skyblue-7"],
  colors["biobotblue-3"],
  colors["navy-5"],
];

interface AxisTheme {
  axisLineClassName?: string;
  tickClassName?: string;
  tickLineProps?: {
    className?: string;
  };
  hideTicks?: boolean;
  hideAxisLine?: boolean;
  gridLineClassName?: string;
  grid?: boolean;
}

export interface ChartTheme {
  axis?: AxisTheme;
  xAxis?: AxisTheme;
  yAxis?: AxisTheme;
  background?: {
    fill?: string;
    className?: string;
  };
  tooltip?: {
    className?: string;
  };
  hover?: {
    line?: {
      className?: string;
    };
    point?: {
      className?: string;
      r?: number;
    };
  };
  series?: {
    line?: {
      className?: string;
      gap?: GapFn | number;
      unfocusedClassName?: string;
      curve?: CurveFactory;
    };
    area?: {
      className?: string;
      gap?: GapFn | number;
      unfocusedClassName?: string;
      curve?: CurveFactory;
    };
    bar?: { className?: string; unfocusedClassName?: string };
    feature?: {
      className?: string;
      labelClassName?: string;
    };
    annotation?: {
      className?: string;
    };
    graticule?: {
      className?: string;
    };
  };
  colors?: string[];
}

export const defaultTheme: ChartTheme = {
  axis: {
    hideTicks: false,
    tickClassName: "text-xs text-neutral-4",
    tickLineProps: { className: "stroke-neutral-2" },
    axisLineClassName: "stroke-neutral-2",
    gridLineClassName: "stroke-neutral-2 stroke-dashed",
  },
  tooltip: {
    className:
      "font-mono text-white text-xs bg-navy-8 opacity-95 rounded shadow p-2",
  },
  hover: {
    line: {
      className: "stroke-neutral-2",
    },
    point: {
      className: "stroke-2 stroke-white",
      r: 4,
    },
  },
  series: {
    line: {
      className: "stroke-2",
    },
    feature: {
      className: "stroke-1 stroke-white group-hover/feature:fill-biobotblue-5",
      labelClassName:
        "text-black [&.bg-dark]:text-navy-1 group-hover/label:text-navy-1",
    },
    annotation: {
      className: "stroke-1 stroke-neutral-5 fill-transparent",
    },
  },
  colors: categoricalColors,
};

const ChartThemeContext = createContext<ChartTheme>(defaultTheme);

export const ChartThemeProvider = ChartThemeContext.Provider;
export const useChartTheme = () => useContext(ChartThemeContext);

/**
 * Creates a color scale for the current chart theme given a list of values to
 * use as the domain.
 */
export const useOrdinalColorScale = (domain: StringLike[]) => {
  const { colors: range } = useChartTheme();
  if (!range) {
    throw new Error("Color theme is not defined");
  }
  return useMemo(() => scaleOrdinal({ domain, range }), [domain, range]);
};

/** Wraps a component by populating default props from the chart theme. */
export const wrapTheme = <T,>(
  themeKey: Accessor<ChartTheme> | keyof ChartTheme,
  Component: React.ComponentType<T>,
) => {
  const themeAccessor = makeAccessor(themeKey);
  return (props: T) => {
    const theme = useChartTheme();
    return <Component {...themeAccessor(theme)} {...props} />;
  };
};
