import { useMemo } from "react";

import {
  Accessor,
  AccessorReturn,
  DefinedAccessor,
  makeAccessor,
  makeDefinedAccessor,
} from "../common/accessor";

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Series that accepts `any` as its Datum. This should usually be used to
 * constrain generics, e.g. `<T extends AnyGeoSeries>`.
 */
export type AnyGeoSeries = GeoSeries<any>;

export interface GeoSeries<
  Datum = object | string | number | boolean,
  Val = any,
  K extends string = string,
> {
  /** an identifier for this series */
  seriesKey: K;
  /** a label for this series */
  label: string;
  /** An object mapping geometry ids to values (only includes defined values) */
  data: Record<string, { datum: Datum; value: Val }>;
}

export interface GeoSeriesInput<
  Datum,
  K extends string,
  FeatureId extends Accessor<Datum, string | number> | keyof Datum,
  Val extends Accessor<Datum> | keyof Datum,
> {
  /** an identifier for this series */
  seriesKey?: K;
  /** An optional label used for this series (default: seriesKey) */
  label?: string;
  /** the original data */
  data: Datum[];
  /* the property (or function) used for feature ids */
  featureId: FeatureId;
  /* the property (or function) used for values */
  value: Val;
  /**
   * the property (or function) used to determine if a value is defined
   * (defaults to the value accessor)
   */
  defined?: keyof Datum | DefinedAccessor<Datum>;
}

/**
 * Combines a set of geographic data and its accessors. Returns a series to use
 * in a {@link Map}.
 */
export const useGeoSeries = <
  Datum,
  K extends string,
  FeatureIdInput extends Accessor<Datum, string | number> | keyof Datum,
  ValInput extends Accessor<Datum> | keyof Datum,
>({
  seriesKey = "series1" as K,
  label,
  data,
  featureId,
  value,
  defined,
}: GeoSeriesInput<Datum, K, FeatureIdInput, ValInput>) =>
  useMemo(() => {
    type Val = AccessorReturn<Datum, ValInput>;
    const featureIdAccessor = makeAccessor(featureId);
    const valAccessor = makeAccessor(value);
    const definedAccessor = makeDefinedAccessor(defined, valAccessor);
    const series: GeoSeries<Datum, Val, K> = {
      seriesKey,
      label: label ?? seriesKey,
      data: data.reduce(
        (ret, datum) => {
          if (definedAccessor(datum)) {
            const k = featureIdAccessor(datum);
            if (k) {
              // eslint-disable-next-line no-param-reassign
              ret[k] = { datum, value: valAccessor(datum) };
            }
          }
          return ret;
        },
        {} as Record<string, { value: Val; datum: Datum }>,
      ),
    };
    return series;
  }, [seriesKey, label, data, featureId, value, defined]);
