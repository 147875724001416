import { message } from "antd";
import { useNavigate } from "react-router-dom";

import api, { getErrorMessage } from "src/api";
import PageTitle from "src/components/PageTitle";

import CustomerForm from "./CustomerForm";
import { CustomerFormData } from "./types";
import { customerFormDataToPayload, emptyCustomerFormData } from "./utils";

const CreateCustomerPage = () => {
  const createCustomer = api.customer.create.useMutation();
  const navigate = useNavigate();

  const onFinish = (formValues: CustomerFormData) => {
    const payload = customerFormDataToPayload(formValues);
    createCustomer.mutate(payload, {
      onError: (error) => {
        message.error(
          `Failed to create customer: ${getErrorMessage(error)}`,
          10,
        );
      },
      onSuccess: (res) => {
        message.success(
          `Successfully created customer ${formValues.customer_name}`,
        );
        navigate(`../${res.data.customer_code}`, { relative: "path" });
      },
    });
  };

  return (
    <>
      <PageTitle title="New Customer" back=".." />
      <CustomerForm
        onFinish={onFinish}
        onCancel={() => navigate("..")}
        initialValues={emptyCustomerFormData}
      />
    </>
  );
};

export default CreateCustomerPage;
