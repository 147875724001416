import { type AppState, Auth0Provider } from "@auth0/auth0-react";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

const Auth0RouteProvider = ({ children }: PropsWithChildren<unknown>) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    if (appState?.returnTo) navigate(appState.returnTo);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope="api:customer-dashboard"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0RouteProvider;
