import { Tag } from "antd";
import { omit } from "lodash";

import Help from "src/components/Help";

import { FormGrid, StandardField } from "./formItems";
import type { DataFilter, DataFilterComponentProps } from "./types";

export const validateKitDataFilter = (
  dataFilter: Extract<DataFilter, { filter_type: "kit" }>,
) => {
  /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
  const isValid =
    dataFilter?.organization_display_id?.length ||
    dataFilter?.sampling_location_id?.length ||
    dataFilter?.program_code?.length;
  /* eslint-enable */
  if (isValid) {
    return Promise.resolve();
  } else {
    return Promise.reject(
      new Error(
        "You must select at least one program/organization/location filter",
      ),
    );
  }
};

const KitDataFilterHelp = () => (
  <div className="space-y-4 [&>*]:space-y-3 text-navy-9">
    <div>
      <p className="text-h3">Datasets (required)</p>
      <p>
        Select the datasets that the customer should have available to them. For
        chemistry data, we have several shortcuts that select different chem
        bundles. You are encouraged to use these:
        <ul>
          <li>
            <Tag className="text-xs mx-1">All hrs datasets</Tag>
          </li>
          <li>
            <Tag className="text-xs mx-1">All sud datasets</Tag>
          </li>
          <li>
            <Tag className="text-xs mx-1">All opioid datasets</Tag>
          </li>
        </ul>
      </p>
      <p>
        Note that the dataset selection determines which command centers are
        available in the sidebar.
      </p>
    </div>
    <div>
      <p className="text-h3">Location selection (required)</p>
      <p>
        You should usually configure a customer using one of programs,
        organizations, or sampling locations. You do not need to select a
        program and an organization and a location. You can pick more than one
        option per category, e.g. picking multiple organizations will show data
        from all selected organizations.
      </p>
      <p>
        In rare circumstances you may want to select a program and a location,
        or a program and an organization. This would display data produced for
        the program at the selected locations (or organizations). There is never
        a need to select both organizations and locations, since that would show
        data for locations that exist in a that organization, and locations
        already only exist in a single location.
      </p>
      <p>
        If you need to show all data for an organization{" "}
        <span className="text-bold">and</span> data for an extra location in a
        different organization, you can add multiple kit data slices: one for
        the organization, and a second one for the extra location.
        Alternatively, you could add one data slice with the full list of
        locations and no organization selection.
      </p>
    </div>
    <div>
      <p className="text-h3">Date range (optional)</p>
      <p>
        You may additionally filter data by data range. Both start and end dates
        are optional, so you do not need to set the end date to a distant future
        date like 4000-12-31 or 9999-12-31.
      </p>
    </div>
  </div>
);

const KitDataFilter = ({ namePrefix, fields }: DataFilterComponentProps) => (
  <FormGrid>
    <StandardField
      namePrefix={namePrefix}
      {...fields.dataset_name}
      label={
        <>
          Datasets{" "}
          <Help title="Kit Data Help">
            <KitDataFilterHelp />
          </Help>
        </>
      }
    />
    <div className="col-span-2 leading-6">
      <p>
        <span className="text-coral-5">* </span>Location selection
      </p>
      <p className="text-navy-5 w-0 min-w-full">
        Choose at least one program, organization, or sampling location (you do
        not need to pick all three)
      </p>
    </div>
    <StandardField
      namePrefix={namePrefix}
      {...fields.program_code}
      labelClassName="ml-8 leading-8"
    />
    <StandardField
      namePrefix={namePrefix}
      {...fields.organization_display_id}
      labelClassName="ml-8 leading-8"
    />
    <StandardField
      namePrefix={namePrefix}
      {...fields.sampling_location_id}
      labelClassName="ml-8 leading-8"
      rules={[
        ({ getFieldValue }) => ({
          validator: () =>
            validateKitDataFilter(
              getFieldValue(["data_filter", ...namePrefix]),
            ),
        }),
      ]}
      dependencies={[
        // hmm this doesn't work?
        ["data_filter", ...namePrefix, "program_code"],
        ["data_filter", ...namePrefix, "organization_display_id"],
      ]}
    />
    {Object.values(
      omit(fields, [
        "dataset_name",
        "program_code",
        "organization_display_id",
        "sampling_location_id",
      ]),
    ).map((config) => (
      <StandardField key={config.name} namePrefix={namePrefix} {...config} />
    ))}
  </FormGrid>
);

export default KitDataFilter;
