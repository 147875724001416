import {
  geoAlbers,
  geoAlbersUsa,
  geoEqualEarth,
  geoMercator,
  geoNaturalEarth1,
  geoOrthographic,
} from "d3-geo";

export const projectionMapping = {
  orthographic: () => geoOrthographic(),
  albers: () => geoAlbers(),
  albersUsa: () => geoAlbersUsa(),
  mercator: () => geoMercator(),
  naturalEarth: () => geoNaturalEarth1(),
  equalEarth: () => geoEqualEarth(),
};
