import type { ReactNode } from "react";

import AboutTheData from "src/components/AboutTheData";
import BiobotCard from "src/components/BiobotCard";
import useId from "src/hooks/useId";

import LCMSSummaryBody from "./LCMSSummaryBody";
import { summaryCardSubtitle, summaryCardTitle } from "./text";
import type { Compound, LCMSDatum } from "./util";

export interface LCMSSummaryText {
  aboutTheData?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
}

export interface LCMSSummaryCardProps extends LCMSSummaryText {
  data: LCMSDatum[];
  compounds: readonly Compound[];
}

const LCMSSummaryCard = ({
  data,
  compounds,
  title,
  subtitle,
  aboutTheData,
}: LCMSSummaryCardProps) => {
  const titleId = useId();
  const subtitleId = useId();
  return (
    <BiobotCard
      title={title ?? summaryCardTitle(compounds)}
      subtitle={subtitle ?? summaryCardSubtitle(compounds)}
      titleId={titleId}
      subtitleId={subtitleId}
    >
      <LCMSSummaryBody
        data={data}
        compounds={compounds}
        aria-labelledby={titleId}
        aria-describedby={subtitleId}
      />
      {aboutTheData && (
        <AboutTheData className="mt-8">{aboutTheData}</AboutTheData>
      )}
    </BiobotCard>
  );
};

export default LCMSSummaryCard;
