import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

export const compounds = [
  { label: "Naloxone", value: "Naloxone" },
  { label: "6ɑ-Naloxol", value: "6a-Naloxol", lowerLabel: "6ɑ-naloxol" },
];

const aboutTheData = (
  <>
    <p>
      Naloxone is an opioid receptor antagonist used to swiftly reverse opioid
      overdose, restoring normal respiration in affected individuals. Naloxone
      is typically administered as an injection or nasal spray and acts quickly
      to counter the life-threatening effects of opioid overdose. It is often
      distributed to first responders, healthcare professionals, and community
      members to be used in emergency situations where opioid overdose is
      suspected, and has more recently been approved for over-the-counter use.
    </p>
    <p>
      IV dosages range from 0.4-2 mg and may need to be administered repeatedly
      depending on the level of opioid toxicity. Nasal sprays come in 2 mg, 4
      mg, and 8 mg doses and may also need to be administered repeatedly.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const NaloxonePage = () => {
  const { data, isLoading } = api.data.naloxoneWastewater.useQuery({
    fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default NaloxonePage;
