import dayjs from "dayjs";

import type { IsoTimestamp } from "~api/types/util";

import { type ApiData, type ApiInput } from "src/api";

export type ApiTokenFormData = ApiInput<"user.createToken">;
export type NewApiToken = ApiData<"user.createToken">;
export type ApiToken = ApiData<"user.listTokens">[number];

const dateAtCurrentTime = (val: string) => {
  const d = dayjs(val);
  return dayjs().year(d.year()).month(d.month()).date(d.date());
};

export const tokenFormDataToPayload = ({
  expires_at,
  ...vals
}: ApiTokenFormData) => ({
  expires_at: expires_at
    ? dateAtCurrentTime(expires_at).toISOString()
    : expires_at,
  ...vals,
});

export const initialTokenFormValues: ApiTokenFormData = {
  nickname: null,
  expires_at: dayjs().add(7, "days").format("YYYY-MM-DD"),
};

export const fmtTokenDate = (date: IsoTimestamp) =>
  new Date(date).toDateString();
