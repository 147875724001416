import { useState } from "react";

import api from "src/api";
import fluImage from "src/assets/images/flu.png";
import { Concentration } from "src/components/GraphElements/ConcentrationSelector";
import NoData from "src/components/NoData";
import QueryWrapper from "src/components/QueryWrapper";
import { useCustomer } from "src/context/Customer";

import FluLocationsCard from "./FluLocationsCard";
import FluSummaryCard from "./FluSummaryCard";

const FluPage = () => {
  const { customer_name } = useCustomer();
  const { data, isLoading } = api.data.fluWastewater.useQuery({
    fields: [
      "sampling_location_name",
      "location_type",
      "primer_target",
      "sample_date",
      "effective_concentration",
    ],
  });
  const [concentrationUnit, setConcentrationUnit] =
    useState<Concentration>("1/L");

  return (
    <div>
      <h1 className="text-h1">Influenza Command Center for {customer_name}</h1>
      <QueryWrapper data={data} isLoading={isLoading}>
        {data?.data?.length ? (
          <div className="space-y-5">
            <FluSummaryCard
              data={data.data}
              setConcentrationUnit={setConcentrationUnit}
              concentrationUnit={concentrationUnit}
            />
            <FluLocationsCard
              data={data.data}
              setConcentrationUnit={setConcentrationUnit}
              concentrationUnit={concentrationUnit}
            />
          </div>
        ) : (
          <NoData target="Influenza" img={fluImage} />
        )}
      </QueryWrapper>
    </div>
  );
};

export default FluPage;
