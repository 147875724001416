import { identity } from "lodash";
import { useMemo } from "react";

/* eslint-disable @typescript-eslint/no-explicit-any */

type Json =
  | string
  | number
  | boolean
  | null
  | readonly Json[]
  | readonly [...Json[]] // tuples just map to json arrays
  | { [key: string]: Json };

/** A "plain" value that antd components can handle. */
type SimpleValue = string | number | boolean;

const isSimpleValue = (x: unknown): x is SimpleValue =>
  typeof x === "string" || typeof x === "number" || typeof x === "boolean";

interface JsonOptionsReturn<T extends JsonValue> {
  toControl: (input: T | undefined) => SimpleValue | undefined;
  fromControl: (output: SimpleValue) => T;
  options: { label: string; value: SimpleValue }[];
}

const stringify = (x: unknown) => JSON.stringify(x) ?? "<undefined>";

/**
 * Hook that handles arbitrary JSON values in a control's option list (which
 * ordinarily only supports strings and numbers). Returns a transformed option
 * list, and functions for converting between the outside world (JSON) and the
 * control (strings)
 */
const useJsonOptions = <T extends JsonValue>(rawOptions: JsonOptions<T>) =>
  useMemo<JsonOptionsReturn<T>>(() => {
    // Some controls can take a list of strings or numbers. Convert this to a
    // standard format { label, value }[] since we need to manipulate it.
    const options = rawOptions.map((x: JsonOptions<T>[number]) =>
      typeof x === "object" ? x : { label: `${x}`, value: x },
    );
    if (options.every(({ value }) => isSimpleValue(value))) {
      // no-op transformer since everything is a simple value already
      return { toControl: identity, fromControl: identity, options } as any;
    } else {
      // Transform between JSON stringified versions and the actual values.
      const valueMap = new Map(
        options.map(({ value }) => [stringify(value), value]),
      );
      return {
        toControl: (input: T | undefined) => stringify(input),
        fromControl: (output: string) => valueMap.get(output),
        options: options.map((o) => ({ ...o, value: stringify(o.value) })),
      };
    }
  }, [rawOptions]);

/** Value type that supports JSON inputs and options */
export type JsonValue = Json | undefined;

/** Type for the `options` prop. */
export type JsonOptions<T extends JsonValue> =
  | readonly { label: string; value: T }[]
  | readonly SimpleValue[];

export default useJsonOptions;
