import curl_example from "src/assets/examples/curl_example.txt";
import nodejs_example from "src/assets/examples/nodejs_example.txt";
import python_example from "src/assets/examples/python_example.txt";

const examplesMap = {
  curl: {
    title: "Curl",
    markdown: curl_example,
  },
  python: {
    title: "Python",
    markdown: python_example,
  },
  nodejs: {
    title: "Node.js",
    markdown: nodejs_example,
  },
};

export const isExample = (name?: string): name is keyof typeof examplesMap =>
  name != null && name in examplesMap;

export default examplesMap;
