import { QuestionCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Spin, Switch, Tag, message } from "antd";

import api, { type ApiOutput } from "src/api";

type FeatureFlagOutput = ApiOutput<"featureFlag.getAll">;
type FeatureFlags = FeatureFlagOutput["flags"];

const ExposedTag = () => (
  <Tag color="purple" title="This feature flag is exposed to the frontend">
    exposed
  </Tag>
);

interface FlagItemProps {
  name: string;
  flag: { description: string; defaultValue: boolean };
}

const FlagItem = ({ name, flag }: FlagItemProps) => {
  const isFrontend = "expose" in flag && flag.expose;
  return (
    <div className="flex my-4 gap-12">
      <div className="w-72 max-w-1/2">
        <div className="flex gap-2">
          <p className="text-p2">{name}</p>
          {isFrontend && <ExposedTag />}
        </div>
        <p className="text-sm font-thin">{flag.description}</p>
      </div>
      <Form.Item noStyle name={name} valuePropName="checked">
        <Switch />
      </Form.Item>
      {isFrontend ? (
        <Form.Item
          noStyle
          name={["_byRole", "biobot-admin", name]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      ) : (
        <div title="Admins can only override frontend flags">
          <QuestionCircleOutlined />
        </div>
      )}
    </div>
  );
};

const Headers = () => (
  <div className="flex my-4 gap-12 text-p2">
    <div className="w-72 max-w-1/2">Feature</div>
    <div>Users</div>
    <div>Admins</div>
  </div>
);

const FlagForm = ({ flags, flagDescriptions }: FeatureFlagOutput) => {
  const setFlags = api.featureFlag.setAll.useMutation();
  const [form] = Form.useForm();
  const handleFinish = (values: FeatureFlags) => {
    setFlags.mutate(values, {
      onError() {
        message.error("Failed to update feature flags.");
      },
      onSuccess() {
        message.success("Feature flags updated.");
      },
    });
  };
  const initialValues = {
    ...flags,
    _byRole: {
      "biobot-admin": {
        ...flags,
        ...flags._byRole?.["biobot-admin"],
      },
    },
  };
  return (
    <Form initialValues={initialValues} form={form} onFinish={handleFinish}>
      <Headers />
      {Object.entries(flagDescriptions).map(([name, flag]) => (
        <FlagItem key={name} name={name} flag={flag} />
      ))}
      <Form.Item noStyle>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const FlagEditor = () => {
  const { data, isLoading } = api.featureFlag.getAll.useQuery({
    bustCache: true,
  });
  if (!data) {
    if (isLoading) {
      return <Spin />;
    } else {
      return <Alert message="Error loading feature flags" />;
    }
  } else {
    return <FlagForm {...data} />;
  }
};

const FeatureFlagsPage = () => (
  <div>
    <h1 className="text-h1">Feature Flags</h1>
    <Alert message="Note that feature flags are cached for 5 minutes. As a user with the feature-flagger role, you will see frontend flag changes immediately, but they may not be reflected to customers for 5 minutes." />
    <FlagEditor />
  </div>
);

export default FeatureFlagsPage;
