import {
  AriaChartProps,
  Chart,
  HoverVerticalLine,
  LineSeries,
  XAxis,
  YAxis,
  useSeriesTemplate,
} from "src/bb-chart";
import { DateRange } from "src/components/DateRangePickerWithPresets";
import EmptyPlot from "src/components/EmptyPlot";
import { numberFormatter } from "src/components/GraphElements/formatters";
import { useStdTooltip } from "src/components/GraphElements/tooltip";

import { PCRDatum } from "./types";
import {
  fmtDateLong,
  getPrimerTargetLabel,
  getSampleDate,
  xTickFormat,
} from "./util";

export interface PCRSummaryChartProps extends AriaChartProps {
  data: PCRDatum[];
  colorScale?: (sampling_location_name: string) => string;
  dateRange?: DateRange;
  locations?: string[];
  unitsText: string;
}

const PCRSummaryChart = ({
  data,
  colorScale,
  dateRange,
  locations,
  unitsText,
  ...ariaProps
}: PCRSummaryChartProps) => {
  const series = useSeriesTemplate({
    data,
    groupBy: "sampling_location_name",
    x: getSampleDate,
    y: "effective_concentration",
    label: "sampling_location_name",
    colorScale,
  });

  const tooltip = useStdTooltip<typeof series>({
    title: getPrimerTargetLabel,
    fmtX: fmtDateLong,
    fmtY: numberFormatter,
    units: unitsText,
    zero: "Below the limit of detection",
    nearestOnly: true,
  });

  const showDataPoints = series.every((s) => s.definedData.length <= 150);
  const empty =
    locations && locations.length === 0 ? (
      <EmptyPlot status="select-location" />
    ) : (
      <EmptyPlot status="no-results-timeframe" />
    );

  return (
    <Chart
      series={series}
      xScales={[{ type: "time", domain: dateRange }]}
      yScales={[{ type: "linear", zero: true, nice: 5 }]}
      tooltip={tooltip}
      empty={empty}
      {...ariaProps}
    >
      <XAxis tickFormat={xTickFormat} />
      <YAxis grid tickFormat={numberFormatter} zeroTick="LOD" />
      {series.map((s) => (
        <LineSeries
          key={s.seriesKey}
          seriesKey={s.seriesKey}
          showDataPoints={showDataPoints}
        />
      ))}
      <HoverVerticalLine />
    </Chart>
  );
};

export default PCRSummaryChart;
