import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

export const compounds = [{ label: "Buprenorphine", value: "Buprenorphine" }];

const aboutTheData = (
  <>
    <p>
      Buprenorphine is a partial opioid agonist used in the treatment of opioid
      use disorder and moderate chronic pain. It is unique for its ceiling
      effect, which reduces the risk of misuse, dependence, and adverse effects.
      Buprenorphine is commonly administered via sublingual tablets or films.
      Buprenorphine is excreted in urine and are stable in wastewater.
    </p>
    <p>
      Typical doses for opioid use disorder treatment range from 4 mg to 24 mg.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const BuprenorphinePage = () => {
  const { data, isLoading } = api.data.buprenorphineWastewater.useQuery({
    fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default BuprenorphinePage;
