import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip, type TooltipProps } from "antd";

const TooltipHelp = ({ title, className, ...props }: TooltipProps) => (
  <Tooltip title={title} {...props}>
    <QuestionCircleOutlined className={className} />
  </Tooltip>
);

export default TooltipHelp;
