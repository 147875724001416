interface Props {
  title?: React.ReactNode;
  titleId?: string;
  subtitle?: React.ReactNode;
  subtitleId?: string;
  children: React.ReactNode;
  className?: string;
  size?: "lg" | "sm";
}

const cardBody = "bg-white border border-solid border-navy-3 rounded";

const classes = {
  card: {
    lg: `${cardBody} p-5`,
    sm: `${cardBody} p-2`,
  },
  title: {
    lg: "text-navy-9 text-h2 mb-0",
    sm: "text-navy-9 text-p1",
  },
  subtitle: {
    lg: "text-navy-7 text-p1-thin mb-6 mr-4",
    sm: "text-navy-7 text-p1-thin",
  },
};

const BiobotCard = ({
  title,
  titleId,
  subtitle,
  subtitleId,
  children,
  className,
  size = "lg",
}: Props) => (
  <div className={`${classes.card[size]} ${className ?? ""}`}>
    {title && (
      <div id={titleId} className={classes.title[size]}>
        {title}
      </div>
    )}
    {subtitle && (
      <div id={subtitleId} className={classes.subtitle[size]}>
        {subtitle}
      </div>
    )}
    {children}
  </div>
);

export default BiobotCard;
