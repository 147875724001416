export default {
  // css keywords
  inherit: "inherit",
  transparent: "transparent",
  current: "currentColor",
  none: "none",
  // Standard Colors
  black: "#000000",
  white: "#FFFFFF",
  /**
   * Official Styleguide Colors (sorted alphabetically)
   * https://www.figma.com/file/5hYFK75rQD7ZIQ0BXtieuj?node-id=144:4398
   *
   * aqua, biobotblue, coral, green, lime, navy, neutral,
   * orange, pink, purple, red, skyblue, yellow
   *
   */
  "aqua-1": "#DFFFF8",
  "aqua-2": "#AFF1EB",
  "aqua-3": "#87E9E1",
  "aqua-4": "#5FE2D7",
  "aqua-5": "#30D3C5",
  "aqua-6": "#1DBFB7",
  "aqua-7": "#0F9997",
  "aqua-8": "#067173",
  "aqua-9": "#03494D",
  "aqua-10": "#022527",
  "biobotblue-1": "#E8F7FF",
  "biobotblue-2": "#BFE9FF",
  "biobotblue-3": "#93D8FF",
  "biobotblue-4": "#59C4FF",
  "biobotblue-5": "#00A4FF",
  "biobotblue-6": "#0086D9",
  "biobotblue-7": "#0068B3",
  "biobotblue-8": "#004D8C",
  "biobotblue-9": "#003566",
  "biobotblue-10": "#002140",
  "coral-1": "#FFEEED",
  "coral-2": "#FFD3D2",
  "coral-3": "#FFB4B2",
  "coral-4": "#FF9391",
  "coral-5": "#FF7875",
  "coral-6": "#D9595B",
  "coral-7": "#B34046",
  "coral-8": "#8C2B34",
  "coral-9": "#661F27",
  "coral-10": "#401318",
  "green-1": "#E5FDEE",
  "green-2": "#B4F2C9",
  "green-3": "#96E7B6",
  "green-4": "#74DD9E",
  "green-5": "#00C783",
  "green-6": "#0EB67C",
  "green-7": "#0A9063",
  "green-8": "#026643",
  "green-9": "#004839",
  "green-10": "#00221B",
  "lime-1": "#F2FCD5",
  "lime-2": "#E9F5C4",
  "lime-3": "#E0F3A6",
  "lime-4": "#D3EB89",
  "lime-5": "#C0DB6A",
  "lime-6": "#A9CA3F",
  "lime-7": "#84A63A",
  "lime-8": "#608026",
  "lime-9": "#324A0B",
  "lime-10": "#182405",
  "navy-1": "#F7F8FD",
  "navy-2": "#D5E0EB",
  "navy-3": "#BACBDB",
  "navy-4": "#96B1C7",
  "navy-5": "#7197B5",
  "navy-6": "#41759C",
  "navy-7": "#125283",
  "navy-8": "#0E436C",
  "navy-9": "#0E2D4C",
  "navy-10": "#000C1A",
  "neutral-1": "#F6F7F9",
  "neutral-2": "#DEDFE3",
  "neutral-3": "#C5C6CA",
  "neutral-4": "#ACADB1",
  "neutral-5": "#949497",
  "neutral-6": "#7B7C7E",
  "neutral-7": "#626365",
  "neutral-8": "#4A4A4B",
  "neutral-9": "#313132",
  "neutral-10": "#181819",
  "orange-1": "#FFEED9",
  "orange-2": "#FFDAB0",
  "orange-3": "#FFC387",
  "orange-4": "#FCA85D",
  "orange-5": "#F08633",
  "orange-6": "#C96420",
  "orange-7": "#A34712",
  "orange-8": "#7D2F07",
  "orange-9": "#571D04",
  "orange-10": "#311002",
  "pink-1": "#FFF1F5",
  "pink-2": "#FFE0E9",
  "pink-3": "#FFB8CE",
  "pink-4": "#FF8FB6",
  "pink-5": "#FF66A1",
  "pink-6": "#D94C87",
  "pink-7": "#B3366E",
  "pink-8": "#8C2356",
  "pink-9": "#661841",
  "pink-10": "#400F29",
  "purple-1": "#FAF0FF",
  "purple-2": "#F3E0FF",
  "purple-3": "#E9C7FF",
  "purple-4": "#D79EFF",
  "purple-5": "#C275FF",
  "purple-6": "#9B59D9",
  "purple-7": "#7740B3",
  "purple-8": "#572B8C",
  "purple-9": "#3C1F66",
  "purple-10": "#261340",
  "red-1": "#FFF3ED",
  "red-2": "#FFD6C4",
  "red-3": "#FFB69C",
  "red-4": "#FF9373",
  "red-5": "#FF6E4A",
  "red-6": "#D94F34",
  "red-7": "#B33522",
  "red-8": "#8C2014",
  "red-9": "#66130D",
  "red-10": "#400C08",
  "skyblue-1": "#EDF7FF",
  "skyblue-2": "#DAEAFF",
  "skyblue-3": "#BAD8FF",
  "skyblue-4": "#9CC3FF",
  "skyblue-5": "#73A6FF",
  "skyblue-6": "#5782D9",
  "skyblue-7": "#3E61B3",
  "skyblue-8": "#2A448C",
  "skyblue-9": "#1E2E66",
  "skyblue-10": "#131D40",
  "yellow-1": "#FFF5DA",
  "yellow-2": "#FFEBB5",
  "yellow-3": "#FFE191",
  "yellow-4": "#FFD76C",
  "yellow-5": "#FFCD47",
  "yellow-6": "#D9A732",
  "yellow-7": "#B38220",
  "yellow-8": "#8C6012",
  "yellow-9": "#603D0A",
  "yellow-10": "#3A2506",
};
