import { Route } from "react-router-dom";

import ProtectedRoute from "src/routes/ProtectedRoute";

import ApiTokenCreatePage from "./ApiTokenCreatePage";
import ApiTokenListPage from "./ApiTokenListPage";
import UserSettingsPage from "./UserSettingsPage";

export default (
  <Route
    path="/settings"
    element={<ProtectedRoute permission="ui:api-token" />}
  >
    <Route path="" element={<UserSettingsPage />} />
    <Route path="tokens" element={<ApiTokenListPage />} />
    <Route path="tokens/new" element={<ApiTokenCreatePage />} />
  </Route>
);
