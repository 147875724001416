import {
  CloseOutlined,
  DownOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Card, Dropdown, Form, FormInstance, Spin } from "antd";
import { omit, pick } from "lodash";
import { useState } from "react";

import DataFilterTags from "./DataFilterTags";
import GenericDataFilter from "./GenericDataFilter";
import KitDataFilter from "./KitDataFilter";
import type { CustomerFormInfo, DataFilterWithIsNew } from "./types";

interface DataFilterListProps {
  info: CustomerFormInfo;
}

interface DataFilterListItemProps {
  name: number;
  remove: (name: number) => void;
  info: CustomerFormInfo;
}

const filterComponents = {
  kit: KitDataFilter,
  default: GenericDataFilter,
};

const dataFilterFields = (form: FormInstance, idx: number) => {
  const vals = form.getFieldsValue()?.data_filter?.[idx];
  return Object.keys(vals).map((k) => ["data_filter", idx, k]);
};

const tryValidate = async (form: FormInstance, idx: number) => {
  try {
    await form.validateFields(dataFilterFields(form, idx));
    return true;
  } catch (_) {
    return false;
  }
};

const DataFilterListItem = ({
  name,
  info,
  remove,
}: DataFilterListItemProps) => {
  const form = Form.useFormInstance();
  const dataFilterWatch = Form.useWatch("data_filter", form)?.[name];
  const dataFilterInitial = form.getFieldValue(["data_filter", name]);
  const dataFilter: DataFilterWithIsNew = dataFilterWatch ?? dataFilterInitial;
  const filterType = dataFilter?.filter_type;
  const [collapsed, setCollapsed] = useState(!dataFilter._isNew);
  const Component = filterComponents[filterType] ?? filterComponents.default;
  if (!filterType) {
    // Waiting for a re-render to get the filterType
    return <Spin />;
  }
  const title = `${filterType} data`;
  return (
    <Card
      size="small"
      type="inner"
      title={
        <div className="flex items-center gap-4">
          <Button
            title={`${collapsed ? "collapse" : "expand"} ${title}`}
            type="ghost"
            className="m-0 p-0"
            onClick={async () => {
              setCollapsed(!collapsed && (await tryValidate(form, name)));
            }}
          >
            {collapsed ? <RightOutlined /> : <DownOutlined />}
            <span className="capitalize">{title}</span>
          </Button>
          <div className="flex w-0 min-w-full">
            <DataFilterTags
              dataFilter={pick(dataFilter, ["dataset_name", "filter_type"])}
              info={info}
              maxTags={4}
            />
          </div>
        </div>
      }
      extra={
        <Button
          title="Remove filter"
          type="ghost"
          className="grayscale m-0 p-0"
          onClick={() => remove(name)}
        >
          <CloseOutlined />
        </Button>
      }
    >
      {/* we need to keep the form items mounted for antd form validation */}
      <div className={collapsed ? "hidden" : ""}>
        <Component namePrefix={[name]} {...info[filterType]} />
      </div>
      {collapsed && (
        <div className="flex flex-wrap gap-y-2 w-0 min-w-full text-sm">
          <DataFilterTags
            dataFilter={omit(dataFilter, ["dataset_name"])}
            info={info}
          />
        </div>
      )}
    </Card>
  );
};

const AddDataSliceButton = ({
  onClick,
  info,
}: {
  info: CustomerFormInfo;
  onClick: (filterType: string) => void;
}) => (
  <Dropdown
    menu={{
      items: Object.keys(info).map((key) => ({ key, label: `${key} data` })),
      onClick: (e) => onClick(e.key),
    }}
  >
    <Button type="link" className="p-0">
      <PlusOutlined />
      Add data
      <DownOutlined />
    </Button>
  </Dropdown>
);

const DataFilterList = ({ info }: DataFilterListProps) => (
  <Form.List name="data_filter">
    {(fields, { add, remove }, { errors }) => (
      <div className="space-y-2">
        {fields.map((field) => (
          <DataFilterListItem {...field} info={info} remove={remove} />
        ))}
        <Form.Item>
          <Form.ErrorList errors={errors} />
          <AddDataSliceButton
            info={info}
            onClick={(key) => add({ filter_type: key, _isNew: true })}
          />
        </Form.Item>
      </div>
    )}
  </Form.List>
);

export default DataFilterList;
