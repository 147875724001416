import cx from "classnames";

import BiobotCard from "src/components/BiobotCard";
import BiobotCollapse from "src/components/BiobotCollapse";
import TooltipHelp from "src/components/TooltipHelp";
import { type Customer, useCustomer } from "src/context/Customer";

import { DownloadLink } from "./Buttons";

interface GeographiesCardProps {
  className?: string;
}

const hasGeoDataset = (
  { datasets }: Customer,
  pattern: RegExp | string = /county|state|regional/,
) => {
  if (typeof pattern === "string") {
    return datasets?.some((d) => d.includes(pattern));
  } else {
    return datasets?.some((d) => pattern.test(d));
  }
};

const topojsonHelp = (
  <TooltipHelp
    className="text-neutral-7 text-base"
    title={
      <span>
        For details on using TopoJSON files, see{" "}
        <a
          href="https://github.com/topojson/topojson"
          target="_blank"
          rel="noreferrer"
          className="text-biobotblue-4 hover:text-biobotblue-3"
        >
          the TopoJSON GitHub repository
        </a>
      </span>
    }
  />
);

const GeographiesCard = ({ className }: GeographiesCardProps) => {
  const customer = useCustomer();
  if (!hasGeoDataset(customer)) {
    return null;
  }
  return (
    <BiobotCard className={className}>
      <BiobotCollapse>
        <BiobotCollapse.Panel key="0" header="Geographic Files">
          <DownloadLink
            type="default"
            download
            label={
              <span>
                US TopoJSON (counties, states, and regions) {topojsonHelp}
              </span>
            }
            href={`${process.env.PUBLIC_URL}/maps/counties.json`}
            gaAction="us-counties-downloaded"
          />
          <DownloadLink
            type="default"
            download
            label="US Counties Shapefile"
            className={cx({ hidden: !hasGeoDataset(customer, "county") })}
            href="https://www2.census.gov/geo/tiger/GENZ2023/shp/cb_2023_us_county_5m.zip"
            gaAction="census-us-counties-downloaded"
          />
          <DownloadLink
            type="default"
            download
            className={cx({ hidden: !hasGeoDataset(customer, "state") })}
            label="US States Shapefile"
            href="https://www2.census.gov/geo/tiger/GENZ2023/shp/cb_2023_us_state_5m.zip"
            gaAction="census-us-states-downloaded"
          />
        </BiobotCollapse.Panel>
      </BiobotCollapse>
    </BiobotCard>
  );
};

export default GeographiesCard;
