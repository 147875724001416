import { Alert, Spin, Tabs } from "antd";
import { useParams } from "react-router-dom";

import api, { type ApiData } from "src/api";
import BiobotCard from "src/components/BiobotCard";
import {
  renderCustomerLink,
  renderNotificationBatchLink,
  renderNotificationState,
} from "src/components/NotificationList";
import PageTitle from "src/components/PageTitle";
import { renderTimestampLocal } from "src/util/table";

interface Params {
  key: string;
}

type Notification = ApiData<"notification.detail">;

interface NotificationDetailsProps {
  notification: Notification;
}

const NotificationDetails = ({ notification }: NotificationDetailsProps) => {
  const items = [
    ["State", renderNotificationState(notification.state)],
    ["Customer", renderCustomerLink(notification)],
    ["Batch", renderNotificationBatchLink(notification)],
    ["Sent", renderTimestampLocal(notification.sent_at) ?? "(unsent)"],
    ["Scheduled", renderTimestampLocal(notification.send_after)],
    ["Send before", renderTimestampLocal(notification.send_before)],
  ] as const;

  return (
    <div className="grid grid-cols-1 md:grid-rows-3 md:grid-cols-2 md:grid-flow-col gap-4">
      {items.map(([label, value]) => (
        <div key={label}>
          <p className="text-p2-thin text-neutral-7">{label}</p>
          <div className="text-p1">{value}</div>
        </div>
      ))}
    </div>
  );
};

interface EmailBodyProps {
  notification: Notification;
}

const emailClass = "w-full h-[75vh] border border-solid overflow-auto";

const EmailBodyPlain = ({ notification }: EmailBodyProps) => (
  <div className={emailClass}>
    <pre className="whitespace-pre-wrap text-sm px-4">
      {notification.body_plain}
    </pre>
  </div>
);

const EmailBodyHtml = ({ notification }: EmailBodyProps) => (
  <iframe
    title="HTML email"
    className={emailClass}
    // Force links inside the iframe to open in a new tab
    srcDoc={`<base target="_blank">${notification.body_html}`}
  />
);

const NotificationBody = ({ notification }: EmailBodyProps) => (
  <div>
    <div className="text-h2">{notification.subject}</div>
    <div className="text-p1-thin">to: {notification.recipient}</div>
    <Tabs
      items={[
        {
          key: "html",
          label: "HTML",
          children: <EmailBodyHtml notification={notification} />,
        },
        {
          key: "plain",
          label: "Plain",
          children: <EmailBodyPlain notification={notification} />,
        },
      ]}
    />
  </div>
);
const NotificationDetailPage = () => {
  const { key } = useParams<keyof Params>() as Params;
  const { data: notification, isLoading } = api.notification.detail.useQuery({
    // The backend will validate that this is actually a number
    key: key as unknown as number,
  });

  if (isLoading) {
    return <Spin size="small" />;
  } else if (!notification) {
    return (
      <Alert
        message="Error loading notifications"
        type="error"
        className="py-0"
      />
    );
  }
  return (
    <>
      <PageTitle
        title={`${notification.data.subject} - ${notification.data.recipient}`}
        back
      />
      <div className="space-y-4">
        <p className="italic">Timestamps are displayed in your time zone.</p>
        <BiobotCard>
          <NotificationDetails notification={notification.data} />
        </BiobotCard>
        <BiobotCard>
          <NotificationBody notification={notification.data} />
        </BiobotCard>
      </div>
    </>
  );
};

export default NotificationDetailPage;
