import useUiPermissions, { type UiPermission } from "./useUiPermissions";

/**
 * Checks that a user has a given UI permission. Ui permissions are prefixed
 * with "ui:".
 *
 * See {@link ~api/api/customer/config.ts} for details.
 */
const useUiPermissionCheck = (permission: UiPermission) =>
  useUiPermissions().includes(permission);

export default useUiPermissionCheck;
