import { createContext } from "react";

import type { ApiData } from "src/api";

// Customer context

export type Customer = ApiData<"customer.config">;

export type UiPermission = NonNullable<Customer["user_permissions"]>[number];

export const CustomerContext = createContext<Customer | undefined>(undefined);

// CustomerOverride context (admin-only)

export interface CustomerOverride {
  customerCode?: string;
  customerMode?: boolean;
}

type StateUpdater<T> = (state: T | ((prev: T) => T)) => void;

type CustomerOverrideUpdater = StateUpdater<CustomerOverride> | null;

export const emptyCustomerOverride = {};
export const CustomerOverrideContext = createContext<CustomerOverride>(
  emptyCustomerOverride,
);

export const CustomerOverrideUpdateContext =
  createContext<CustomerOverrideUpdater>(null);
