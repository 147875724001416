import { FormGrid, StandardField } from "./formItems";
import type { DataFilterComponentProps } from "./types";

const GenericDataFilter = ({
  namePrefix,
  fields,
}: DataFilterComponentProps) => (
  <FormGrid>
    {Object.values(fields).map((config) => (
      <StandardField key={config.name} namePrefix={namePrefix} {...config} />
    ))}
  </FormGrid>
);

export default GenericDataFilter;
