import BiobotCard from "src/components/BiobotCard";
import { Concentration } from "src/components/GraphElements/ConcentrationSelector";
import useId from "src/hooks/useId";

import PCRSummaryBody from "../PCRSummaryBody";
import { PCRDatum } from "../types";
import { genogroupOptions } from "./util";

export interface NoroSummaryCardProps {
  data: PCRDatum[];
  setConcentrationUnit: (value: Concentration) => void;
  concentrationUnit: Concentration;
}
const NoroSummaryCard = ({
  data: rawData,
  concentrationUnit,
  setConcentrationUnit,
}: NoroSummaryCardProps) => {
  const titleId = useId();
  const subtitleId = useId();
  return (
    <BiobotCard
      title="Compare GI or GII Across Locations"
      subtitle="Discover trends in norovirus genogroups GI and GII with this interactive
  time series graph. Select multiple locations and toggle between GI or
  GII genogroups to look at trends over time and across locations. This
  visualization supports monitoring and comprehension of norovirus
  dynamics in wastewater."
      titleId={titleId}
      subtitleId={subtitleId}
    >
      <PCRSummaryBody
        rawData={rawData}
        targetNameOptions={genogroupOptions}
        setConcentrationUnit={setConcentrationUnit}
        concentrationUnit={concentrationUnit}
        typeSelectorText="Genogroup Selection"
        aria-labelledby={titleId}
        aria-describedby={subtitleId}
      />
    </BiobotCard>
  );
};

export default NoroSummaryCard;
