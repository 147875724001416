import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, ButtonProps, Drawer, DrawerProps } from "antd";
import cx from "classnames";
import { SetStateAction } from "react";

import { useHelpContext } from "src/context/Help";
import useId from "src/hooks/useId";

export const useHelpState = () => {
  const id = useId();
  const [helpId, setHelpId] = useHelpContext();
  const isOpen = id === helpId;
  const setIsOpen = (x: SetStateAction<boolean>) => {
    const newVal = typeof x === "function" ? x(isOpen) : x;
    if (newVal) {
      setHelpId(id);
    } else if (isOpen) {
      setHelpId(null);
    }
  };
  return [isOpen, setIsOpen] as const;
};

interface HelpButtonProps extends ButtonProps {
  onClick: Exclude<ButtonProps["onClick"], undefined>;
}

/** Standalone Button with help styling. You must pass `onClick`. */
export const HelpButton = ({
  children,
  className,
  ...props
}: HelpButtonProps) => (
  <Button
    type="link"
    title="help"
    {...props}
    className={cx(className, {
      "p-0 m-0": !props.type,
      "text-navy-5": !children,
    })}
  >
    {children ?? <QuestionCircleOutlined />}
  </Button>
);

interface HelpDrawerProps extends DrawerProps {
  open: Exclude<DrawerProps["open"], undefined>;
  onClose: Exclude<DrawerProps["onClose"], undefined>;
}

/** Standalone Drawer with help styling. You must pass `open` and `onClose` */
export const HelpDrawer = ({ title, ...props }: HelpDrawerProps) => (
  <Drawer mask={false} title={title ?? "Help"} {...props} />
);

interface HelpProps extends DrawerProps {
  buttonText?: React.ReactNode;
  buttonClassName?: string;
  buttonProps?: ButtonProps;
}

/** A button that renders help text into a drawer. */
const Help = ({
  buttonText,
  buttonClassName,
  buttonProps,
  onClose,
  children,
  ...props
}: HelpProps) => {
  const [isOpen, setIsOpen] = useHelpState();
  return (
    <>
      <HelpButton
        onClick={() => setIsOpen((x) => !x)}
        className={cx(buttonClassName, buttonProps?.className)}
        {...buttonProps}
      >
        {buttonText}
      </HelpButton>
      <HelpDrawer
        {...props}
        onClose={(e) => {
          onClose?.(e);
          setIsOpen(false);
        }}
        open={isOpen}
      >
        {children}
      </HelpDrawer>
    </>
  );
};

export default Help;
