import {
  AriaChartProps,
  Chart,
  HoverVerticalLine,
  LineSeries,
  XAxis,
  YAxis,
  useSeriesTemplate,
} from "src/bb-chart";
import { DateRange } from "src/components/DateRangePickerWithPresets";
import { numberFormatter } from "src/components/GraphElements/formatters";
import { useStdTooltip } from "src/components/GraphElements/tooltip";

import { LCMSDatum, fmtDateLong, getSampleDate, xTickFormat } from "./util";

interface LCMSLocationChartProps extends AriaChartProps {
  data: LCMSDatum[];
  dateRange?: DateRange;
  concentrationRange?: [number, number];
  isExpanded?: boolean;
  colorScale?: (label: string) => string;
  empty?: React.ReactNode;
  isNationalAverage?: boolean;
}

const LCMSLocationChart = ({
  data,
  dateRange,
  concentrationRange,
  isExpanded,
  colorScale,
  empty,
  isNationalAverage,
  ...ariaProps
}: LCMSLocationChartProps) => {
  const series = useSeriesTemplate({
    data,
    groupBy: "compound_name",
    label: "compound_name",
    x: getSampleDate,
    y: "estimated_consumption",
    color: "compound_name",
    colorScale,
  });

  const unitsText = "mg/1000 people/day";
  const tooltip = useStdTooltip<typeof series>({
    fmtX: fmtDateLong,
    fmtY: numberFormatter,
    units: unitsText,
    zero: "Below the limit of quantitation",
  });

  const showDataPoints = isExpanded ? data.length <= 150 : data.length <= 50;

  return (
    <Chart
      series={series}
      xScales={[{ type: "time", domain: dateRange }]}
      yScales={[
        {
          type: "linear",
          zero: true,
          nice: 5,
          domain: concentrationRange,
        },
      ]}
      tooltip={tooltip}
      empty={empty}
      {...ariaProps}
    >
      <XAxis tickFormat={xTickFormat} />
      <YAxis grid numTicks={5} tickFormat={numberFormatter} zeroTick="LOQ" />
      {series.map((s) => (
        <LineSeries
          key={s.seriesKey}
          seriesKey={s.seriesKey}
          showDataPoints={showDataPoints}
          className={isNationalAverage ? "stroke-dotted stroke-2" : "stroke-2"}
        />
      ))}
      <HoverVerticalLine />
    </Chart>
  );
};

export default LCMSLocationChart;
