import { utcFormat } from "d3-time-format";

import type { Concentration } from "src/components/GraphElements/ConcentrationSelector";
import { dateFormatter } from "src/components/GraphElements/formatters";

import type { PCRDatum } from "./types";

// Accessors
export const getSampleDate = (d: PCRDatum) => new Date(d.sample_date);
export const getPrimerTargetLabel = (d: PCRDatum) =>
  d.primer_target?.replace("NoV_", "").replace("Inf", "Influenza ");

// Formatters
export const fmtDateLong = utcFormat("%b %d, %Y");

export const fmtUnits = (unit: Concentration) =>
  unit === "1/L" ? "copies/L" : "copies/mL";

export const xTickFormat = dateFormatter({
  byDay: utcFormat("%b %-d '%y"), // "Mar 9 '23"
  byMonth: utcFormat("%b '%y"), // "Mar '23"
});
