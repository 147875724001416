import { Button } from "antd";
import { uniqBy } from "lodash";
import { useMemo, useState } from "react";

interface LocationTypeSelectorProps {
  value: LocationType;
  onChange: (val: LocationType) => void;
  className?: string;
}

const buttons = [
  { value: "all", label: "All Locations" },
  { value: "community", label: "All Community Locations" },
  { value: "facility", label: "All Building Locations" },
] as const;

export type LocationType = (typeof buttons)[number]["value"];

const LocationTypeSelector = ({
  value,
  onChange,
  className,
}: LocationTypeSelectorProps) => (
  <div className={`flex flex-wrap gap-2 ${className ?? ""}`}>
    {buttons.map((option) => (
      <Button
        type={value === option.value ? "primary" : "default"}
        title={option.label}
        onClick={() => onChange(option.value)}
      >
        {option.label}
      </Button>
    ))}
  </div>
);

/**
 * Encapsulates logic necessary for using a LocationTypeSelector.
 *
 * When there are multiple location types, we should show LocationTypeSelector,
 * and data must be filtered by location type _before_ we do any other
 * transformation.
 *
 * @example
 * const { data: filteredData, locationTypeControl } = useLocationTypeSelector(data);
 * return (
 *   <div>
 *     {locationTypeControl}
 *     <Chart data={filteredData} />
 *   </div>
 * )
 */
export const useLocationTypeSelector = <
  T extends { location_type?: "community" | "facility" | null },
>(
  data: T[],
) => {
  const [locationType, setLocationType] = useState<LocationType>("all");
  return useMemo(() => {
    const locationTypes = uniqBy(data, "location_type")
      .map((d) => d.location_type)
      .filter(Boolean);
    if (locationTypes.length <= 1) {
      return { data, locationTypeControl: null };
    }
    return {
      data:
        locationType === "all"
          ? data
          : data.filter((d) => d.location_type === locationType),
      locationTypeControl: (
        <LocationTypeSelector value={locationType} onChange={setLocationType} />
      ),
    };
  }, [data, locationType, setLocationType]);
};

export default LocationTypeSelector;
