import { localPoint } from "@visx/event";
import getXAndYFromEvent from "@visx/event/lib/getXAndYFromEvent";
import { RefObject, useCallback, useRef } from "react";

import { HoverHandler } from "../common/HoverDetector";
import { useSvgSize } from "../common/SvgSize";
import {
  TooltipData,
  TooltipDatumBase,
  useTooltip,
  useTooltipSetter,
} from "../common/Tooltip";
import { useMapData } from "./MapData";
import { AnyGeoSeries, GeoSeries } from "./Series";
import { BBChartFeatureCollection } from "./types";

export type GeoTooltipDatum<
  T extends AnyGeoSeries = AnyGeoSeries,
  G extends BBChartFeatureCollection = BBChartFeatureCollection,
> =
  T extends GeoSeries<infer Datum, infer Val>
    ? TooltipDatumBase<Datum> & {
        seriesKey: T["seriesKey"];
        feature: Pick<G["features"][number], "id" | "properties">;
        value: Val;
      }
    : never;

export type GeoTooltipProps<
  T extends AnyGeoSeries | AnyGeoSeries[],
  G extends BBChartFeatureCollection = BBChartFeatureCollection,
> = TooltipData<GeoTooltipDatum<T extends AnyGeoSeries[] ? T[number] : T, G>[]>;

/** Type for a Component that renders geographic tooltips. */
export type GeoTooltipComponent<
  T extends AnyGeoSeries | AnyGeoSeries[],
  G extends BBChartFeatureCollection = BBChartFeatureCollection,
> = (props: GeoTooltipProps<T, G>) => JSX.Element | null;

export const useGeoTooltip = <
  G extends BBChartFeatureCollection = BBChartFeatureCollection,
>() => useTooltip<GeoTooltipDatum<AnyGeoSeries, G>[]>();

const containingSvgGroup = (el_: SVGElement) => {
  let el = el_;
  for (;;) {
    if (el instanceof SVGGElement) {
      return el;
    }
    if (el.parentElement instanceof SVGElement) {
      el = el.parentElement;
    } else {
      return null;
    }
  }
};

const useGeoTooltipCallbacks = (thisRef: RefObject<SVGElement | null>) => {
  const setTooltip = useTooltipSetter<GeoTooltipDatum[]>();
  const { margin } = useSvgSize();
  const { series: allSeries } = useMapData();

  const onHover = useCallback<HoverHandler>(
    (event) => {
      if (!thisRef.current) {
        setTooltip({ isOpen: false });
        return;
      }
      // We need to be explicit about the element this handler is for,
      // otherwise we could end up with a point that is slightly off if this is a
      // hover event that targets a text element!
      const cursor = localPoint(thisRef.current, event);
      if (!cursor) {
        setTooltip({ isOpen: false });
        return;
      }

      // Note that elementsFromPoint has the potential to be inefficient, but
      // while developing it seemed fast enough. I think it would be more ideal
      // to use SVGElement.getIntersectionList(), but firefox hasn't implemented
      // it yet. I'm not positive it works based on a quick read about how it
      // works based on if the hape has the appropriate pointer-events, but it
      // seems like it should, and I'd expect it to be faster both b/c it would
      // limit processing to the current svg, and b/c it seems like hit testing
      // ought to be decently optimized in a graphics rendering context.
      // Alternately we could try a solution based on d3.geoContains, which
      // would mean looping through all features (and we don't have access to the
      // list of all features in this function)
      const xy = getXAndYFromEvent(event);
      const ttData = document
        .elementsFromPoint(xy.x, xy.y)
        .map((el) => {
          if (el instanceof SVGElement) {
            if (el.dataset.bbChartTooltip) {
              return JSON.parse(el.dataset.bbChartTooltip);
            }
            // data-bb-chart-toolltip is also allowed on svg groups, but groups
            // will never be returned from elementsFromPoint.
            const g = containingSvgGroup(el);
            if (g?.dataset.bbChartTooltip) {
              return JSON.parse(g.dataset.bbChartTooltip);
            }
          }
          return null;
        })
        .filter(Boolean)
        .map((d) => ({
          ...d,
          label: allSeries[d.seriesKey].label,
          ...allSeries[d.seriesKey].data[d.feature.id ?? ""],
        }));

      const nearest = ttData[0];
      const seriesData = Object.fromEntries(
        ttData.map((d) => [d.seriesKey, d]),
      );

      if (!nearest) {
        setTooltip({ isOpen: false });
      } else {
        const x = cursor.x - margin.left;
        const y = cursor.y - margin.top;
        setTooltip({
          isOpen: true,
          data: {
            nearest,
            series: seriesData,
            cursor: { x, y },
          },
          left: x,
          top: y,
        });
      }
    },
    [thisRef, allSeries, setTooltip, margin.left, margin.top],
  );
  const onLeave = useCallback<HoverHandler>(() => {
    setTooltip({ isOpen: false });
  }, [setTooltip]);

  return { onHover, onLeave };
};

interface GeoTooltipHoverDetectorProps {
  children?: React.ReactNode;
}

/** An svg `rect` that captures cursor events and updates TooltipContext. */
export const GeoTooltipHoverDetector = ({
  children,
}: GeoTooltipHoverDetectorProps) => {
  const ref = useRef<SVGGElement>(null);
  const { onHover, onLeave } = useGeoTooltipCallbacks(ref);
  return (
    <g
      ref={ref}
      onTouchStart={onHover}
      onTouchMove={onHover}
      onMouseMove={onHover}
      onMouseLeave={onLeave}
    >
      {children}
    </g>
  );
};
