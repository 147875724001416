import { Navigate, Outlet } from "react-router-dom";

import { FeatureFlags, useFeatureFlags } from "src/context/FeatureFlag";
import useRoleCheck, { RoleName } from "src/hooks/useRoleCheck";
import useUiPermissions, {
  type UiPermission,
} from "src/hooks/useUiPermissions";

export interface ProtectedRouteProps {
  permission?: UiPermission;
  feature?: keyof FeatureFlags;
  roleName?: RoleName;
}

const ProtectedRoute = ({
  permission,
  feature,
  roleName,
}: ProtectedRouteProps) => {
  const permissions = useUiPermissions();
  const flags = useFeatureFlags();
  const hasRole = useRoleCheck(roleName);

  if (permission != null && !permissions.includes(permission)) {
    return <Navigate to="/" />;
  } else if (feature != null && !flags[feature]) {
    return <Navigate to="/" />;
  } else if (roleName != null && !hasRole) {
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
};

export default ProtectedRoute;
