import { Switch } from "antd";
import { useState } from "react";

import { ChartThemeProvider } from "src/bb-chart";
import AboutTheData from "src/components/AboutTheData";
import BiobotCard from "src/components/BiobotCard";
import { SmallMultiples } from "src/components/ChartLayout";
import RangePickerWithPresets, {
  DateRange,
} from "src/components/DateRangePickerWithPresets";
import EmptyPlot from "src/components/EmptyPlot";
import ConcentrationSelector, {
  Concentration,
} from "src/components/GraphElements/ConcentrationSelector";
import { useLocationTypeSelector } from "src/components/GraphElements/LocationTypeSelector";
import useExtent from "src/hooks/useExtent";
import useTransformedData from "src/hooks/useTransformedData";
import useUniqueValues from "src/hooks/useUniqueValues";
import { smallChartTheme } from "src/theme/chartTheme";

import type { PCRDatum } from "../types";
import { fmtUnits } from "../util";
import CovidExecSummary from "./CovidExecSummary";
import CovidLinks from "./CovidLinks";
import CovidLocationChart from "./CovidLocationChart";
import CovidRiskLevelLegend from "./CovidRiskLevelLegend";
import CovidRiskLevelOverlay from "./CovidRiskLevelOverlay";

interface CovidLocationsBodyProps {
  data: PCRDatum[];
  concentrationUnit: Concentration;
  setConcentrationUnit: (value: Concentration) => void;
  enableRiskLevels?: boolean;
}

export type CovidLocationsCardProps = CovidLocationsBodyProps;

const CovidLocationsBody = ({
  data: rawData_,
  concentrationUnit,
  setConcentrationUnit,
  enableRiskLevels = false,
}: CovidLocationsBodyProps) => {
  const { data: rawData, locationTypeControl } =
    useLocationTypeSelector(rawData_);

  const [locationNames] = useUniqueValues(rawData, ["sampling_location_name"]);

  const [showRiskLevels, setShowRiskLevels] = useState(enableRiskLevels);
  const [isStandardized_, setIsStandardized] = useState(false);
  const [dateRange_, setDateRange] = useState<DateRange>();

  // Risk levels on means we remove date range and axis standardization.
  // Handling the state this way means we restore the previously saved state
  // when the risk level toggle is switched off.
  const dateRange = showRiskLevels ? undefined : dateRange_;
  const isStandardized = showRiskLevels ? false : isStandardized_;
  const fullDateExtent = useExtent(rawData, "sample_date");

  const data = useTransformedData(rawData, {
    filter: {
      sample_date: { between: dateRange },
    },
    units: {
      effective_concentration: { from: "1/L", to: concentrationUnit },
    },
  });

  const concentrationRange = useExtent(data, "effective_concentration");

  const empty = <EmptyPlot status="no-results-timeframe" />;

  return (
    <div>
      <div className="flex flex-col gap-8 py-6 sticky top-0 bg-white z-[100]">
        <div className="flex flex-wrap items-end gap-8">
          <div className="-mb-1">
            <div className="text-p2 text-navy-9">Select Date Range</div>
            <RangePickerWithPresets
              disabled={showRiskLevels}
              value={dateRange}
              onChange={setDateRange}
            />
          </div>
          <div className="flex gap-2">
            <div className="w-4 h-4 bg-biobotblue-5 rounded mt-1" />
            <p className="text-p2-thin">Wastewater Effective Concentration</p>
          </div>
          <ConcentrationSelector
            value={concentrationUnit}
            onChange={setConcentrationUnit}
          />
          {enableRiskLevels && (
            <label className="text-p2-thin flex items-center gap-2">
              <Switch
                onChange={setShowRiskLevels}
                checked={showRiskLevels}
                title="Show Current Risk"
              />
              Show Current Risk
            </label>
          )}
          {locationNames.length > 1 && (
            <label className="text-p2-thin flex items-center gap-2">
              <Switch
                onChange={(val) => {
                  setIsStandardized(val);
                  // standardized on means we disable risk levels
                  setShowRiskLevels(false);
                }}
                checked={isStandardized}
                title="Standardize Graph Axes"
              />
              Standardize Axes
            </label>
          )}
        </div>
        {locationTypeControl}
      </div>
      <SmallMultiples.GridWithExpand
        data={data}
        groupBy="sampling_location_name"
        keys={locationNames}
        expandDisabled={showRiskLevels}
        renderChart={({ data: chartData, isExpanded }) => (
          <div className="aspect-[16/9] relative">
            <CovidLocationChart
              data={chartData}
              unitsText={fmtUnits(concentrationUnit)}
              dateRange={dateRange ?? (isStandardized ? fullDateExtent : null)}
              concentrationRange={
                isStandardized ? concentrationRange : undefined
              }
              isExpanded={isExpanded}
              empty={empty}
            />
            {showRiskLevels && chartData[0]?.location_type === "facility" && (
              <CovidRiskLevelOverlay
                sampling_location_name={chartData[0].sampling_location_name}
              />
            )}
          </div>
        )}
      />
    </div>
  );
};

const CovidLocationsCard = (props: CovidLocationsCardProps) => {
  const { enableRiskLevels, data } = props;
  const [locationTypes] = useUniqueValues(data, ["location_type"]);
  return (
    <BiobotCard title="All Locations">
      {enableRiskLevels && <CovidExecSummary />}
      <ChartThemeProvider value={smallChartTheme}>
        <CovidLocationsBody {...props} />
      </ChartThemeProvider>
      {locationTypes.filter(Boolean).length > 0 && (
        <AboutTheData
          className="mt-8"
          title={`About the data${enableRiskLevels ? " & legend" : ""}`}
        >
          {enableRiskLevels && <CovidRiskLevelLegend />}
          <CovidLinks locationTypes={locationTypes} />
        </AboutTheData>
      )}
    </BiobotCard>
  );
};

export default CovidLocationsCard;
