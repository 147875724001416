import { Button, Checkbox, Form, Input, Select } from "antd";
import { Rule } from "antd/es/form";
import { useState } from "react";

import BiobotCard from "src/components/BiobotCard";
import QueryWrapper from "src/components/QueryWrapper";

import DataFilterList from "./DataFilterList";
import DatasetSummary from "./DatasetSummary";
import EmailListInput from "./EmailListInput";
import type { CustomerFormData, CustomerFormInfo, DataFilter } from "./types";
import useCustomerFormInfo from "./useCustomerFormInfo";
import { generateCustomerCode, normalizeDataFilter } from "./utils";

interface CustomerFormProps {
  onFinish: (customer: CustomerFormData) => void;
  onCancel?: () => void;
  initialValues?: CustomerFormData;
}

interface CustomerFormPureProps extends CustomerFormProps {
  info: CustomerFormInfo;
}

const customerCodeRules: Rule[] = [
  {
    required: true,
    message: "Code should be alphanumeric without spaces",
    pattern: /^[a-zA-Z0-9_-]*$/,
    min: 3,
    max: 30,
  },
];

export const CustomerFormPure = ({
  onFinish,
  onCancel,
  initialValues,
  info,
}: CustomerFormPureProps) => {
  const [form] = Form.useForm();

  const isNewCustomer = !initialValues?.customer_code;
  const [overrideCustomerCode, setOverrideCustomerCode] = useState(false);
  const [showDatasetSummary, setShowDatasetSummary] = useState(false);

  const dataFilter = Form.useWatch<DataFilter[]>("data_filter", form);

  // For new customers, auto-generate customer code
  const handleValuesChange = (values: CustomerFormData) => {
    if (isNewCustomer && !overrideCustomerCode) {
      form.setFieldValue("customer_code", generateCustomerCode(values));
    }
  };

  return (
    <div className={showDatasetSummary ? "grid grid-cols-2 gap-4" : "flex"}>
      <BiobotCard>
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFinish={onFinish}
          onValuesChange={(_changed, values) => handleValuesChange(values)}
        >
          <Form.Item
            name="customer_name"
            rules={[{ required: true, min: 2 }]}
            label="What is the name you want the customer to see in the dashboard?"
            extra="e.g. City of Berkeley or MADPH"
          >
            <Input allowClear placeholder="Customer-facing name" />
          </Form.Item>
          <div className="flex gap-2 justify-between items-center">
            <p className="leading-8">
              <span>What data would you like this customer to see?</span>
            </p>
            <Button
              type="link"
              onClick={() => setShowDatasetSummary((x) => !x)}
            >
              {showDatasetSummary ? "Hide" : "Show"} summary
            </Button>
          </div>
          <DataFilterList info={info} />
          <Form.Item
            label="Customer code"
            required
            tooltip={
              isNewCustomer
                ? null
                : "Customer code may not be changed for existing customers"
            }
          >
            {isNewCustomer && (
              <label className="flex gap-2 mb-2">
                <Checkbox
                  checked={overrideCustomerCode}
                  onChange={(e) => setOverrideCustomerCode(e.target.checked)}
                />
                Override customer code
              </label>
            )}
            <Form.Item name="customer_code" noStyle rules={customerCodeRules}>
              <Input disabled={!overrideCustomerCode} allowClear />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="show_risk_scores"
            rules={[{ required: true }]}
            label="Building Risk Scores"
            extra="This is only applicable for approved customers, if you're unsure ask a product manager."
          >
            <Select
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Form.Item>
          <EmailListInput />
          <Form.Item>
            <div className="flex justify-end gap-2">
              {onCancel && <Button onClick={onCancel}>Cancel</Button>}
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </BiobotCard>
      {showDatasetSummary && (
        <BiobotCard title="Dataset summary">
          <DatasetSummary dataFilter={normalizeDataFilter(dataFilter ?? [])} />
        </BiobotCard>
      )}
    </div>
  );
};

const CustomerForm = (props: CustomerFormProps) => {
  const { isLoading, data } = useCustomerFormInfo();
  return (
    <QueryWrapper
      isLoading={isLoading}
      data={data}
      errorMessage="Error loading filter options"
    >
      {data && <CustomerFormPure {...props} info={data} />}
    </QueryWrapper>
  );
};

export default CustomerForm;
