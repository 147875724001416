import { Switch } from "antd";
import { useState } from "react";

import {
  AriaChartProps,
  ChartThemeProvider,
  useOrdinalColorScale,
} from "src/bb-chart";
import { SmallMultiples } from "src/components/ChartLayout";
import type { DateRange } from "src/components/DateRangePickerWithPresets";
import DateRangePickerWithPresets from "src/components/DateRangePickerWithPresets";
import EmptyPlot from "src/components/EmptyPlot";
import CheckboxLegend from "src/components/GraphElements/CheckboxLegend";
import { useLocationTypeSelector } from "src/components/GraphElements/LocationTypeSelector";
import useExtent from "src/hooks/useExtent";
import useTransformedData from "src/hooks/useTransformedData";
import useUniqueValues from "src/hooks/useUniqueValues";
import { smallChartTheme } from "src/theme/chartTheme";

import LCMSLocationChart from "./LCMSLocationChart";
import type { Compound, LCMSDatum } from "./util";

interface LCMSLocationsBodyProps extends AriaChartProps {
  data: LCMSDatum[];
  compounds: readonly Compound[];
}

const LCMSLocationsBody = ({
  data,
  compounds,
  ...ariaProps
}: LCMSLocationsBodyProps) => {
  const { data: filteredData, locationTypeControl } =
    useLocationTypeSelector(data);

  const compoundNameValues = compounds.map((option) => option.value);
  const colorScale = useOrdinalColorScale(compoundNameValues);
  const [isStandardized, setIsStandardized] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>();
  const [compoundType, setCompoundType] =
    useState<string[]>(compoundNameValues);
  const fullDateExtent = useExtent(filteredData, "sample_date");

  const [locationNames] = useUniqueValues(filteredData, [
    "sampling_location_name",
  ]);

  const transformedData = useTransformedData(filteredData, {
    filter: {
      sample_date: { between: dateRange },
      compound_name: { in: compoundType },
    },
  });

  const concentrationRange = useExtent(
    transformedData,
    "estimated_consumption",
  );

  const empty =
    compoundType.length === 0 ? (
      <EmptyPlot status="select-type" />
    ) : (
      <EmptyPlot status="no-results-timeframe" />
    );

  return (
    <ChartThemeProvider value={smallChartTheme}>
      <div className="flex flex-col gap-8 pb-6 sticky top-0 bg-white z-[100]">
        <div className="flex flex-wrap items-end gap-8">
          <div className="-mb-1">
            <div className="text-p2 text-navy-9">Select Date Range</div>
            <DateRangePickerWithPresets
              value={dateRange}
              onChange={setDateRange}
            />
          </div>
          <CheckboxLegend
            value={compoundType}
            onChange={setCompoundType}
            options={compounds}
            colorScale={colorScale}
            className="[&>label]:text-p2-thin"
          />
          {locationNames.length > 1 && (
            <label className="text-p2-thin flex items-center gap-2">
              <Switch
                onChange={setIsStandardized}
                checked={isStandardized}
                title="Standardize Graph Axes"
              />
              Standardize Axes
            </label>
          )}
        </div>
        {locationTypeControl}
      </div>
      <SmallMultiples.GridWithExpand
        data={transformedData}
        groupBy="sampling_location_name"
        keys={locationNames}
        renderChart={({ data: chartData, isExpanded }) => (
          <div className="aspect-[16/9]">
            <LCMSLocationChart
              data={chartData}
              dateRange={dateRange ?? (isStandardized ? fullDateExtent : null)}
              concentrationRange={
                isStandardized ? concentrationRange : undefined
              }
              colorScale={colorScale}
              isExpanded={isExpanded}
              empty={empty}
              isNationalAverage={
                chartData[0]?.sampling_location_name === "US National Average"
              }
              {...ariaProps}
            />
          </div>
        )}
      />
    </ChartThemeProvider>
  );
};

export default LCMSLocationsBody;
