import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React from "react";

const EmailListInput: React.FC = () => (
  <Form.List name="emails">
    {(fields, { add, remove }, { errors }) => (
      <>
        {fields.map((field, index) => (
          <Form.Item
            label={index === 0 ? "Emails of users to add" : ""}
            required={false}
            key={field.key}
          >
            <Form.Item
              {...field}
              validateTrigger={["onChange", "onBlur"]}
              rules={[
                {
                  required: true,
                  type: "email",
                  message:
                    "Please input a valid email address or delete this field.",
                },
              ]}
              noStyle
            >
              <Input
                placeholder="Users email address"
                style={{ width: "90%" }}
              />
            </Form.Item>
            <MinusCircleOutlined
              className="dynamic-delete-button"
              onClick={() => remove(field.name)}
            />
          </Form.Item>
        ))}
        <Form.Item>
          <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
            Add User
          </Button>
          <Form.ErrorList errors={errors} />
        </Form.Item>
      </>
    )}
  </Form.List>
);

export default EmailListInput;
