import { Table, TableColumnsType } from "antd";
import cx from "classnames";

interface DataType {
  riskLevel: string;
  className: string;
  activity: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Risk Level",
    dataIndex: "riskLevel",
    key: "riskLevel",
    width: "15%",
  },
  {
    title: "SARS-CoV-2 Activity",
    dataIndex: "activity",
    key: "activity",
  },
];

const legendTableData: DataType[] = [
  {
    riskLevel: "Insufficient Data",
    className: "bg-neutral-2",
    activity:
      'A location must have collected two samples within the past two weeks in order to receive a calculated risk level. "Insufficient data" will appear when a location is first onboarding, until two samples have been collected and reported. This may also appear if a location\'s sampling schedule is disrupted; for instance, if sample reporting is delayed due to an issue with sample collection or a change in sampling equipment.',
  },
  {
    riskLevel: "Low",
    className: "bg-green-2",
    activity:
      "Low probability that a future wastewater sample will have a high SARS-CoV-2 concentration. Data are consistent with there being few or no infected individuals on site.",
  },
  {
    riskLevel: "Moderate",
    className: "bg-yellow-2",
    activity:
      "Moderate probability that a future wastewater sample will have a high SARS-CoV-2 concentration. Data are consistent with there being multiple infected individuals on site.",
  },
  {
    riskLevel: "High",
    className: "bg-coral-2",
    activity:
      "High probability that a future wastewater sample will have a high SARS-CoV-2 concentration. Data are consistent with there being substantial SARS-CoV-2 activity on site.",
  },
];

const CovidRiskLevelLegend = ({ className }: { className?: string }) => (
  <Table
    data-test="legend-table"
    className={cx("legend-table", className)}
    rowClassName={(record) => record.className}
    rowKey={(record) => record.riskLevel}
    scroll={{ x: "min-content" }}
    columns={columns}
    dataSource={legendTableData}
    pagination={false}
    size="middle"
  />
);

export default CovidRiskLevelLegend;
