import { useState } from "react";

import {
  AriaChartProps,
  ChartThemeProvider,
  useOrdinalColorScale,
} from "src/bb-chart";
import { ChartLayout } from "src/components/ChartLayout";
import DateRangePickerWithPresets, {
  DateRange,
} from "src/components/DateRangePickerWithPresets";
import ChartLegend from "src/components/GraphElements/ChartLegend";
import RadioButtons from "src/components/GraphElements/RadioButtons";
import Label from "src/components/Label";
import useOptions from "src/hooks/useOptions";
import useTransformedData from "src/hooks/useTransformedData";
import useUniqueValues from "src/hooks/useUniqueValues";
import { compareLocationsChartTheme } from "src/theme/chartTheme";

import LCMSSummaryChart from "./LCMSSummaryChart";
import { Compound, LCMSDatum } from "./util";

interface LCMSSummaryBodyProps extends AriaChartProps {
  data: LCMSDatum[];
  compounds: readonly Compound[];
}

const LCMSSummaryBody = ({
  data: rawData,
  compounds,
  ...ariaProps
}: LCMSSummaryBodyProps) => {
  const [locationNames] = useUniqueValues(rawData, ["sampling_location_name"]);

  // Move US National Average to its own section of the "Select Location" list.
  const benchmark = "US National Average";
  const benchmarkedLocations = [
    {
      label: "Benchmarks",
      title: "Benchmarks",
      options: useOptions([benchmark]),
    },
    {
      label: "Active Sample Sites",
      title: "Active Sample Sites",
      options: useOptions(locationNames.filter((s) => s !== benchmark)),
    },
  ];

  const colorScale = useOrdinalColorScale(locationNames);

  const [compoundName, setCompoundName] = useState(
    compounds.find((x) => x.defaultSelected)?.value ?? compounds[0].value,
  );

  const [locations, setLocations] = useState(locationNames.slice(0, 10));
  const [dateRange, setDateRange] = useState<DateRange>();

  const data = useTransformedData(rawData, {
    filter: {
      sample_date: { between: dateRange },
      sampling_location_name: { in: locations },
      compound_name: { eq: compoundName },
    },
  });

  const unitsText = "mg/1000 people/day";

  return (
    <ChartThemeProvider value={compareLocationsChartTheme}>
      <ChartLayout>
        <ChartLayout.Body>
          <p className="text-navy-9 text-xs font-thin">
            Wastewater Estimated Consumption ({unitsText})
          </p>
          <div className="aspect-[16/9]">
            <LCMSSummaryChart
              data={data}
              dateRange={dateRange}
              colorScale={colorScale}
              locations={locations}
              {...ariaProps}
            />
          </div>
        </ChartLayout.Body>

        <ChartLayout.Controls>
          {compounds.length > 1 && (
            <>
              <Label text="Compound Selection" />
              <RadioButtons
                options={compounds}
                value={compoundName}
                onChange={setCompoundName}
              />
            </>
          )}
          <Label text="Edit Duration" className="mt-2" />
          <DateRangePickerWithPresets
            value={dateRange}
            onChange={setDateRange}
          />
          <Label text="Select Locations" className="mt-2" />
          <ChartLegend
            options={benchmarkedLocations}
            value={locations}
            onChange={setLocations}
            colorScale={colorScale}
            flex
          />
        </ChartLayout.Controls>
      </ChartLayout>
    </ChartThemeProvider>
  );
};

export default LCMSSummaryBody;
