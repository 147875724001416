import {
  AriaChartProps,
  Chart,
  HoverVerticalLine,
  LineSeries,
  XAxis,
  YAxis,
  useSeriesTemplate,
} from "src/bb-chart";
import type { DateRange } from "src/components/DateRangePickerWithPresets";
import EmptyPlot from "src/components/EmptyPlot";
import { numberFormatter } from "src/components/GraphElements/formatters";
import { useStdTooltip } from "src/components/GraphElements/tooltip";

import { LCMSDatum, fmtDateLong, getSampleDate, xTickFormat } from "./util";

interface LCMSSummaryChartProps extends AriaChartProps {
  data: LCMSDatum[];
  dateRange?: DateRange;
  concentrationRange?: [number, number];
  isExpanded?: boolean;
  colorScale?: (label: string) => string;
  locations?: string[];
}

const LCMSSummaryChart = ({
  data,
  dateRange,
  concentrationRange,
  isExpanded,
  colorScale,
  locations,
  ...ariaProps
}: LCMSSummaryChartProps) => {
  const series = useSeriesTemplate({
    data,
    groupBy: "sampling_location_name",
    x: getSampleDate,
    y: "estimated_consumption",
    label: "sampling_location_name",
    colorScale,
  });

  const unitsText = "mg/1000 people/day";
  const tooltip = useStdTooltip<typeof series>({
    title: "compound_name",
    fmtX: fmtDateLong,
    fmtY: numberFormatter,
    units: unitsText,
    zero: "Below the limit of quantitation",
    nearestOnly: true,
  });

  const showDataPoints = isExpanded ? data.length <= 150 : data.length <= 50;

  const empty =
    locations && locations.length === 0 ? (
      <EmptyPlot status="select-location" />
    ) : (
      <EmptyPlot status="no-results-timeframe" />
    );

  const benchmark = "US National Average";
  return (
    <Chart
      series={series}
      xScales={[{ type: "time", domain: dateRange }]}
      yScales={[
        { type: "linear", zero: true, nice: 5, domain: concentrationRange },
      ]}
      tooltip={tooltip}
      empty={empty}
      {...ariaProps}
    >
      <XAxis tickFormat={xTickFormat} />
      <YAxis grid numTicks={5} tickFormat={numberFormatter} zeroTick="LOQ" />
      {series.map((s) => (
        <LineSeries
          key={s.seriesKey}
          seriesKey={s.seriesKey}
          showDataPoints={showDataPoints}
          className={
            s.label === benchmark ? "stroke-dotted stroke-2" : "stroke-2"
          }
        />
      ))}

      <HoverVerticalLine />
    </Chart>
  );
};

export default LCMSSummaryChart;
