import type { DatasetName } from "@repo/data/datasets";
import { Suspense, lazy } from "react";

import BiobotCard from "src/components/BiobotCard";
import Loading from "src/components/Loading";
import PageTitle from "src/components/PageTitle";

export const daasDatasets = [
  "covid:county:interpolated",
  "flu:county:interpolated",
  "rsv:county:interpolated",
] satisfies DatasetName[];

const RiskTierOverview = lazy(() => import("./RiskTierOverview"));

const DaasHomepage = () => (
  <div>
    <PageTitle.Heading>Respiratory Overview</PageTitle.Heading>
    <BiobotCard>
      <Suspense fallback={<Loading />}>
        <RiskTierOverview />
      </Suspense>
    </BiobotCard>
  </div>
);

export default DaasHomepage;
