import { useMemo, useRef } from "react";

const useCustomMemo = <T extends readonly unknown[], Ret>(
  f: () => Ret,
  deps: T,
  cmp: (a: T, b: T) => boolean,
) => {
  // taken from use-deep-compare-effect
  const ref = useRef(deps);
  const signalRef = useRef(0);
  if (!cmp(ref.current, deps)) {
    ref.current = deps;
    signalRef.current += 1;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(f, [signalRef.current]);
};

export default useCustomMemo;
