import { Space, Spin } from "antd";

interface PooEmojiMsgProps {
  text: string;
}
const PooEmojiMsg = ({ text }: PooEmojiMsgProps) => (
  <Space size={0} direction="vertical" align="center" className="text-center">
    <div className="text-2xl">💩</div>
    <span className="text-lg text-navy-9">{text}</span>
  </Space>
);

interface EmptyPlotProps {
  status:
    | "loading"
    | "no-results"
    | "no-results-timeframe"
    | "select-location"
    | "select-genogroup"
    | "select-type"
    | "error";
}
const EmptyPlot = ({ status }: EmptyPlotProps) => {
  const noDataText = "No data available";
  const errorText = "Error getting data";

  let text;
  switch (status) {
    case "loading":
      break;
    case "no-results":
      text = noDataText;
      break;
    case "no-results-timeframe":
      text = noDataText;
      break;
    case "select-location":
      text = "Please select at least one location";
      break;
    case "select-genogroup":
      text = "Please select at least one genogroup";
      break;
    case "select-type":
      text = "Please select at least one type";
      break;
    case "error":
    default:
      text = errorText;
      break;
  }
  return (
    <div className="w-full h-[250px] flex justify-center items-center opacity-50">
      {status === "loading" && <Spin size="large" />}
      {status !== "loading" && <PooEmojiMsg text={text} />}
    </div>
  );
};

export default EmptyPlot;
