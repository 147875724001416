import type { Margin } from "../common/SvgSize";
import type { BBChartFeatureCollection } from "./types";

/**
 * Filters a feature collection given a predicate of the feature collection's
 * properties.
 */
export const filterFeatures = <T extends BBChartFeatureCollection>(
  featureCollection: T,
  predicate: (props: T["features"][number]["properties"]) => boolean,
): T => ({
  ...featureCollection,
  features: featureCollection.features.filter((f) => predicate(f.properties)),
});

interface MapTransformProps {
  bbox?: [[number, number], [number, number]];
  width: number;
  height: number;
  margin: Margin;
}

/** Calculates an svg `transform` that centers and fits the given bbox. */
export const mapTransform = ({
  bbox,
  width,
  height,
  margin,
}: MapTransformProps) => {
  if (!bbox) {
    return `translate(${margin.top}, ${margin.left})`;
  }
  const mapWidth = bbox[1][0] - bbox[0][0];
  const mapHeight = bbox[1][1] - bbox[0][1];
  const scaleX = width / mapWidth;
  const scaleY = height / mapHeight;
  const scale = Math.min(scaleX, scaleY);
  const x = (width - scale * mapWidth) / 2;
  const y = (height - scale * mapHeight) / 2;

  // b/c of how matrix multiplication works, transforms are effectively applied
  // right to left, so the order is backwards.
  return [
    // 3. center in the svg
    `translate(${x + margin.left}, ${y + margin.top})`,
    // 2. scale to the svg size
    `scale(${scale})`,
    // 1. translate to the origin (features are centered in a 1000x1000 box)
    `translate(${-bbox[0][0]}, ${-bbox[0][1]})`,
  ].join(" ");
};
