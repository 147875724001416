import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";
import { renameAvgColumns } from "./LCMSCommandCenter/util";

export const compounds = [
  { label: "Methamphetamine", value: "Methamphetamine" },
  { label: "Amphetamine", value: "Amphetamine" },
];

const aboutTheData = (
  <>
    <p>
      Methamphetamine is a highly addictive Schedule II stimulant that can be
      smoked, inhaled, injected, or taken orally. When used illicitly, it is
      primarily smoked. It is used infrequently in pill form for medical
      applications such as treatment-resistant ADHD or emergency weight loss.
      Methamphetamine and one of its major metabolites, amphetamine, are both
      excreted in urine and are stable in wastewater. Trends in amphetamine
      consumption may not always mirror those of methamphetamine, as amphetamine
      itself is commonly prescribed and can also be used illicitly.
    </p>
    <p>
      When used for medical applications, 2.5 mg is the typical dose, but
      illicit doses can be 50 mg or more depending on individual tolerance. A
      lethal dose of methamphetamine is 150 mg, though life-threatening
      cardiovascular events can occur at lower doses.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const MethamphetaminePage = () => {
  const { data, isLoading } = api.data.methamphetamineWastewater.useQuery({
    fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  const { data: avgData, isLoading: avgIsLoading } =
    api.data.methamphetamineNational.useQuery(
      { fields: ["target_name", "date", "estimated_consumption_weekly_avg"] },
      { select: renameAvgColumns },
    );

  const combinedData = [...(avgData?.data ?? []), ...(data?.data ?? [])];

  return (
    <LCMSCommandCenter
      data={combinedData}
      isLoading={isLoading || avgIsLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default MethamphetaminePage;
