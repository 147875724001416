import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { Link, LinkProps, NavigateProps, useNavigate } from "react-router-dom";

/* eslint-disable no-nested-ternary */

export interface PageTitleProps {
  title: string;
  back?: string | NavigateProps | true;
  children?: React.ReactNode;
}

const BackLink = ({ to, ...props }: Partial<LinkProps>) => {
  const navigate = useNavigate();
  // state.idx is a react-router implementation detail, but it seems to be the
  // only way to determine if there's a previous entry in the history stack
  // belonging to this app.
  const canGoBack = (window.history.state?.idx ?? 0) > 1;
  if (!canGoBack && !to) {
    return null;
  }
  return (
    <Link
      onClick={(e) => {
        if (canGoBack && !to) {
          e.preventDefault();
          navigate(-1);
        }
      }}
      to={to ?? "/"}
      {...props}
      className="text-h1 text-xl opacity-50 hover:opacity-100 hover:text-h1"
    >
      <ArrowLeftOutlined />
    </Link>
  );
};

const Heading = ({
  back,
  children,
}: {
  back?: string | NavigateProps | true;
  children: React.ReactNode;
}) => (
  <h1 className="text-h1 mb-6 flex items-center gap-2">
    {back === true ? (
      <BackLink />
    ) : typeof back === "string" ? (
      <BackLink to={back} />
    ) : back != null ? (
      <BackLink {...back} />
    ) : null}
    {children}
  </h1>
);

/** Renders a standard h1 title tag and sets the document's title */
const PageTitle = ({ title, back, children }: PageTitleProps) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return children ? <>{children}</> : <Heading back={back}>{title}</Heading>;
};

PageTitle.Heading = Heading;
PageTitle.BackLink = BackLink;

export default PageTitle;
