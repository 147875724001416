import { Button, ButtonProps } from "antd";
import React from "react";
import { LinkProps, useHref, useLinkClickHandler } from "react-router-dom";

type ButtonLinkProps = ButtonProps &
  Pick<LinkProps, "to" | "state" | "target" | "replace">;

/**
 * An antd Button that works like a react-router Link.
 *
 * Straight from https://reactrouter.com/en/main/hooks/use-link-click-handler
 */
const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonLinkProps>(
  ({ onClick, replace = false, state, target, to, ...rest }, ref) => {
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
      replace,
      state,
      target,
    });

    return (
      <Button
        {...rest}
        href={href}
        onClick={(event) => {
          onClick?.(event);
          if (!event.defaultPrevented) {
            handleClick(event);
          }
        }}
        ref={ref}
        target={target}
      />
    );
  },
);

export default ButtonLink;
