import {
  Chart,
  HoverVerticalLine,
  LineSeries,
  XAxis,
  YAxis,
  useSeriesTemplate,
} from "src/bb-chart";
import type { DateRange } from "src/components/DateRangePickerWithPresets";
import { numberFormatter } from "src/components/GraphElements/formatters";
import { useStdTooltip } from "src/components/GraphElements/tooltip";

import { PCRDatum } from "../types";
import {
  fmtDateLong,
  getPrimerTargetLabel,
  getSampleDate,
  xTickFormat,
} from "./util";

interface FluLocationChartProps {
  data: PCRDatum[];
  dateRange?: DateRange;
  concentrationRange?: [number, number];
  isExpanded?: boolean;
  unitsText: string;
  colorScale?: (label: string) => string;
  empty?: React.ReactNode;
}

const FluLocationChart = ({
  data,
  dateRange,
  concentrationRange,
  isExpanded,
  unitsText,
  colorScale,
  empty,
}: FluLocationChartProps) => {
  const series = useSeriesTemplate({
    data,
    groupBy: "primer_target",
    label: getPrimerTargetLabel,
    x: getSampleDate,
    y: "effective_concentration",
    color: "primer_target",
    colorScale,
  });

  const tooltip = useStdTooltip<typeof series>({
    fmtX: fmtDateLong,
    fmtY: numberFormatter,
    units: unitsText,
    zero: "Below the limit of detection",
  });

  const showDataPoints = isExpanded ? data.length <= 150 : data.length <= 50;

  return (
    <Chart
      series={series}
      xScales={[{ type: "time", domain: dateRange }]}
      yScales={[
        { type: "linear", zero: true, nice: 5, domain: concentrationRange },
      ]}
      tooltip={tooltip}
      empty={empty}
      aria-label="Influenza Wastewater Concentration Graph"
      aria-description="Sample location graph illustrating wastewater concentration of influenza over time"
    >
      <XAxis tickFormat={xTickFormat} />
      <YAxis grid numTicks={5} tickFormat={numberFormatter} zeroTick="LOD" />
      {series.map((s) => (
        <LineSeries
          key={s.seriesKey}
          seriesKey={s.seriesKey}
          showDataPoints={showDataPoints}
        />
      ))}
      <HoverVerticalLine />
    </Chart>
  );
};

export default FluLocationChart;
