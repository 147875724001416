"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/unpivot.ts
var unpivot_exports = {};
__export(unpivot_exports, {
  default: () => unpivot_default,
  unpivot: () => unpivot
});
module.exports = __toCommonJS(unpivot_exports);
var unpivot = ({ data, pivot, ...rest }) => {
  if (!pivot?.columns.length) {
    return { data, ...rest };
  }
  const [firstArrCol, ...otherArrCols] = pivot.columns;
  const enumValues = Object.entries(pivot.values).map(([k, vals]) => [
    k,
    // snowflake treats nulls in arrays as `undefined` (unless you specifically
    // use parse_json('null'))
    vals.map((v) => v === void 0 ? null : v)
  ]);
  const ret = {
    ...rest,
    data: data.flatMap(
      (d) => d[firstArrCol].map((val, idx) => {
        const ret2 = {
          ...d,
          [firstArrCol]: val
        };
        otherArrCols.forEach((col) => {
          ret2[col] = d[col][idx];
        });
        enumValues.forEach(([col, values]) => {
          if (ret2[col] !== null) {
            ret2[col] = values[ret2[col]];
          }
        });
        return ret2;
      })
    )
  };
  return ret;
};
var unpivot_default = unpivot;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  unpivot
});
