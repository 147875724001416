import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";
import { renameAvgColumns } from "./LCMSCommandCenter/util";

export const compounds = [
  { label: "Cocaine", value: "Cocaine" },
  { label: "Benzoylecgonine", value: "Benzoylecgonine" },
];

const aboutTheData = (
  <>
    <p>
      Cocaine is a Schedule II stimulant that is most commonly inhaled but can
      also be absorbed via the mouth and gums, smoked, and injected
      intravenously. Most cocaine contributions in wastewater will be from
      illicit use, though cocaine very rarely may be used as a local anesthetic.
      Both cocaine and its major metabolite, benzoylecgonine, are excreted in
      urine and stable in wastewater.
    </p>
    <p>
      Cocaine doses vary substantially and can range from 30 mg to 100 mg or
      more depending on individual tolerance. A lethal dose is 1200 mg, though
      life-threatening cardiovascular events can occur at lower doses.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const CocainePage = () => {
  const { data, isLoading } = api.data.cocaineWastewater.useQuery({
    fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  const { data: avgData, isLoading: avgIsLoading } =
    api.data.cocaineNational.useQuery(
      { fields: ["target_name", "date", "estimated_consumption_weekly_avg"] },
      { select: renameAvgColumns },
    );

  const combinedData = [...(avgData?.data ?? []), ...(data?.data ?? [])];

  return (
    <LCMSCommandCenter
      data={combinedData}
      isLoading={isLoading || avgIsLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default CocainePage;
