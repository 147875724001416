import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import { uniqBy } from "lodash";

dayjs.extend(dayjsPluginUTC);

/** Returns a `sorter` function for a numeric column. */
export const numberSorter =
  <K extends string>(k: K) =>
  <D extends { [key in K]: number }>(a: D, b: D): number =>
    a[k] - b[k];

/** Returns a `sorter` function for a string column. */
export const stringSorter =
  <K extends string>(k: K) =>
  <D extends { [key in K]: string | null }>(a: D, b: D): number =>
    (a[k] ?? "").localeCompare(b[k] ?? "");

type Sorter<D> = (a: D, b: D) => number;

/** Composes multiple sort functions. */
export const multiSorter = <D>(
  sort1: Sorter<D>,
  sort2: Sorter<D>,
  ...[sort3, ...sortn]: Sorter<D>[]
): Sorter<D> => {
  if (sort3) {
    return multiSorter(multiSorter(sort1, sort2), sort3, ...sortn);
  } else {
    // 0 is falsey, so this evaluates sort2 if sort1 compares equal
    return (a: D, b: D) => sort1(a, b) || sort2(a, b);
  }
};

/** Generates all unique options for the given key in a dataset. */
export const filterOptions = <D, VK extends keyof D, TK extends keyof D = VK>(
  data: D[],
  valueKey: VK,
  labelKey?: TK,
) =>
  uniqBy(data, valueKey).map((d) => ({
    text: d[labelKey ?? valueKey],
    value: d[valueKey],
  }));

/**
 * Returns an `onFilter` function that includes rows that exactly match the
 * selected filter(s).
 */
export const filterEqual =
  <K extends string>(k: K) =>
  (value: unknown, record: { [key in K]: unknown }) =>
    record[k] === value;

/** Renders a timestamp in UTC. */
export const renderTimestampUTC = (date?: string | null) =>
  date ? dayjs.utc(date).format("YYYY-MM-DD HH:mm:ss [Z]") : null;

/** Renders a timestamp in user's local time zone. */
export const renderTimestampLocal = (date?: string | null) =>
  date ? dayjs(date).format("YYYY-MM-DD hh:mm:ss a") : null;
