import { lowerFirst } from "lodash";

import BiobotCard from "src/components/BiobotCard";
import BiobotCollapse from "src/components/BiobotCollapse";

import { DownloadLink } from "./Buttons";
import type { Links } from "./types";

interface DataDictionariesCardProps {
  links?: Links;
  className?: string;
}

const titleText = (group: string, label: string) => {
  if (/^[A-Z]+( |$)/.test(label)) {
    return `${group} ${label}`;
  } else {
    return `${group} ${lowerFirst(label)}`;
  }
};

const csvFilename = (group: string, label: string) => {
  const filename = `biobot_${titleText(group, label)}_format`
    .toLowerCase()
    .replace(/[^a-z0-9_]/g, "_")
    .replace(/_{2,}/g, "_");
  return `${filename}.csv`;
};

const DataDictionariesCard = ({
  links,
  className,
}: DataDictionariesCardProps) => {
  const dictionaries = Object.entries(links ?? {})
    .filter(([_, link]) => link?.href && link.dataDictionary)
    .map(([key, link]) => ({
      key,
      group: link!.group,
      label: link!.label,
    }));
  if (dictionaries.length === 0) {
    return null;
  }
  return (
    <BiobotCard className={className}>
      <BiobotCollapse>
        <BiobotCollapse.Panel key="0" header="Data Dictionaries">
          {dictionaries.map(({ key, group, label }) => (
            <DownloadLink
              key={key}
              type="default"
              label={titleText(group, label)}
              href={`${process.env.PUBLIC_URL}/data-dictionaries/${key}.csv`}
              download={csvFilename(group, label)}
              gaAction={`${key}-dictionary-downloaded`}
            />
          ))}
        </BiobotCollapse.Panel>
      </BiobotCollapse>
    </BiobotCard>
  );
};

export default DataDictionariesCard;
