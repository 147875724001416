import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "antd";
import React from "react";

export interface FatalErrorProps {
  message: React.ReactNode;
}

/** An unrecoverable error with a message and a log out button. */
const FatalError = ({ message }: FatalErrorProps) => {
  const { logout, isAuthenticated } = useAuth0();
  const handleLogOut = () => {
    sessionStorage.clear();
    logout({ returnTo: window.location.origin });
  };
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="bg-coral-1 border-solid border-coral-6 p-8 max-w-[90vw] sm:max-w-[60vw] md:max-w-[40vw] lg:max-x-[20wv]">
        <div className="flex flex-col items-center w-full">
          <div className="text-h1 flex">
            <div className="rotate-[0deg]">💩</div>
            <div className="rotate-[20deg]">💩</div>
            <div className="rotate-[40deg]">💩</div>
            <div className="rotate-[60deg]">💩</div>
            <div className="rotate-[80deg]">💩</div>
            <div className="rotate-[100deg]">💩</div>
            <div className="rotate-[120deg]">💩</div>
            <div className="rotate-[140deg]">💩</div>
            <div className="rotate-[160deg]">💩</div>
            <div className="rotate-[180deg]">💩</div>
          </div>
          <p className="text-h2">Oops!</p>
        </div>
        <p className="text-p2 text-coral-6 my-4">{message}</p>
        {isAuthenticated && (
          <Button type="primary" size="small" onClick={handleLogOut}>
            Log out
          </Button>
        )}
      </div>
    </div>
  );
};

export default FatalError;
