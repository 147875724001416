import Link from "antd/es/typography/Link";
import { map, sample } from "lodash";

import funFacts from "src/components/GraphElements/funFacts";

const substanceImageClasses = [
  "hue-rotate-0",
  "hue-rotate-30",
  "hue-rotate-330",
  "hue-rotate-0",
  "hue-rotate-30",
  "hue-rotate-330",
];

const NoData = ({ target, img }: { target: string; img: string }) => {
  const images = map(substanceImageClasses, (c, i) => (
    <div className="max-w-fit" key={i}>
      <img
        key={`substance-pic-${i}`}
        src={img}
        height="150"
        width="150"
        alt="Substance Molecule"
        className={c}
      />
    </div>
  ));
  return (
    <div className="text-navy-9">
      <div className="text-h2 font-light my-6">
        Data feeling clogged up? It might be caught in the pipeline (processing)
        or still in the tank (not subscribed). Flush away your confusion with
        our support team at{" "}
        <Link
          className="text-h2 underline"
          href={`mailto:support@biobot.io?subject=Unlock ${target} Data`}
          target="_blank"
        >
          support@biobot.io
        </Link>
      </div>
      <div className="text-p1-thin">Fun fact: {sample(funFacts)}</div>
      <div className="mt-4 flex justify-evenly">{images}</div>
    </div>
  );
};

export default NoData;
