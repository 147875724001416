import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, Tag, Tooltip, Typography, message } from "antd";
import { useState } from "react";

import api, { getErrorMessage } from "src/api";
import BiobotCard from "src/components/BiobotCard";

import { type ApiToken, NewApiToken, fmtTokenDate } from "./util";

const DeleteButton = ({ token }: { token: ApiToken }) => {
  const deleteToken = api.user.deleteToken.useMutation();
  const handleDelete = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this token?",
      content: (
        <p>
          Any applications that use this token will no longer work with the
          Biobot Platform API. You cannot recover a token once it has been
          deleted.
        </p>
      ),
      icon: null,
      okText: "Yes, delete the token",
      okButtonProps: { type: "primary", danger: true },
      onOk() {
        deleteToken.mutate(
          { key: token.key },
          {
            onError(error) {
              message.error(
                `Failed to delete token: ${getErrorMessage(error)}`,
                10,
              );
            },
          },
        );
      },
    });
  };
  return (
    <Button size="small" danger onClick={handleDelete}>
      Delete
    </Button>
  );
};

const CopyableToken = ({ value }: { value: string }) => {
  const [conceal, setConceal] = useState(true);
  return (
    <div className="mt-2">
      <Alert
        type="info"
        message={
          <p>
            Make sure you copy your API token now and store it in a safe place
            like a password manager or secret storage. Treat it like a password.{" "}
            <span className="font-bold">
              This is the only time you will be able to see the token!
            </span>
          </p>
        }
      />
      <div className="bg-lime-1 border-lime-6 border border-solid rounded px-2 my-2">
        <Typography.Text copyable={{ text: value }} className="font-mono">
          {conceal ? value.replace(/./g, "*") : value}
        </Typography.Text>
        <Tooltip title={conceal ? "Reveal" : "Hide"}>
          <Button
            className="ml-1 p-0 w-fit"
            type="link"
            icon={conceal ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            onClick={() => setConceal(!conceal)}
          />
        </Tooltip>
      </div>
    </div>
  );
};

interface ApiTokenCardProps {
  token: ApiToken | NewApiToken;
  allowDelete?: boolean;
  allowCopy?: boolean;
}

const ApiTokenCard = ({ token, allowCopy, allowDelete }: ApiTokenCardProps) => {
  const isExpired = token.expires_at && new Date(token.expires_at) < new Date();
  return (
    <BiobotCard
      size="sm"
      title={
        <div className="flex gap-2">
          <div>{token.nickname}</div>
          {isExpired && (
            <div>
              <Tag color="red">Expired</Tag>
            </div>
          )}
        </div>
      }
    >
      <div className="flex text-sm text-navy-7">
        <div className="flex-1">
          {allowCopy && "token" in token && (
            <CopyableToken value={token.token} />
          )}
          <p>Created on {fmtTokenDate(token.created_at)}</p>
          {token.expires_at ? (
            <p className="font-medium italic">
              {isExpired ? "Expired on " : "Expires on "}
              {fmtTokenDate(token.expires_at)}
            </p>
          ) : (
            <p className="font-medium italic">Does not expire</p>
          )}
        </div>
        <div>{allowDelete && <DeleteButton token={token} />}</div>
      </div>
    </BiobotCard>
  );
};

export default ApiTokenCard;
