import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import FatalError from "./components/FatalError";
import "./index.css";

// import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  release: process.env.REACT_APP_COMMIT_ID,
});

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={<FatalError message="An unknown error has occurred" />}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
