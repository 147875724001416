import { Alert, Spin } from "antd";
import { useParams } from "react-router-dom";

import api from "src/api";
import NotificationList from "src/components/NotificationList";
import PageTitle from "src/components/PageTitle";

interface Params {
  key: string;
}

const NotificationBatchDetailPage = () => {
  const { key } = useParams<keyof Params>() as Params;
  const { data: batch, isLoading } = api.notification.batchDetail.useQuery({
    // The backend will validate that this is actually a number
    key: key as unknown as number,
  });

  if (isLoading) {
    return <Spin size="small" />;
  } else if (!batch) {
    return (
      <Alert
        message="Error loading notifications"
        type="error"
        className="py-0"
      />
    );
  }
  return (
    <>
      <PageTitle title="Notification batch" back=".." />
      <NotificationList filter={{ notification_batch_key: batch.data.key }} />
    </>
  );
};

export default NotificationBatchDetailPage;
