import { Table, TableColumnsType, Tag } from "antd";
import Link from "antd/es/typography/Link";
import { startCase } from "lodash";
import React from "react";

import api from "src/api";

import WatchlistMethods from "./WatchlistMethods";

const tags = {
  Increasing: { color: "red", label: "Increasing" },
  Stable: { color: "gold", label: "Stable" },
  Unknown: { color: "default", label: "Insufficient Data" },
  Decreasing: { color: "green", label: "Decreasing" },
};

const BuildingsOnlyDescription = () => (
  <>
    Our{" "}
    <span className="text-biobotblue-5 break-keep">SARS&#8209;CoV&#8209;2</span>{" "}
    building insights feature is still in R&D, if you’d like to be on the
    waitlist for this please contact us at{" "}
    <Link
      className="text-h3 underline"
      href="mailto:support@biobot.io?subject=Unlock Covid Building Trends Data"
      target="_blank"
    >
      support@biobot.io
    </Link>
    .
  </>
);

const DataDescription = ({ assayText }: { assayText: React.ReactNode }) => (
  <>
    Explore the dynamically updated table below to understand the latest trends
    in
    <span className="text-biobotblue-5 break-keep"> {assayText} </span>{" "}
    infection rates across various locations.
  </>
);

const useTrendsQuery = (assayName: "covid" | "norovirus") => {
  const covidQuery = api.data.covidTrends.useQuery(
    {
      fields: [
        "sampling_location_name",
        "sampling_location_type",
        "biobot_trend",
      ],
      limit: -1,
    },
    { enabled: assayName === "covid" },
  );
  return assayName === "covid" ? covidQuery : null;
};

const TrendsTable = ({
  assay,
}: {
  assay: {
    text: React.ReactNode;
    value: "covid" | "norovirus";
  };
}) => {
  const { data: queryData, isLoading } = useTrendsQuery(assay.value) ?? {};

  type Row = NonNullable<typeof queryData>["data"][number];

  const communityTrends = (queryData?.data ?? []).filter(
    (d) => d.sampling_location_type === "community",
  );
  const buildingTrends = (queryData?.data ?? []).filter(
    (d) => d.sampling_location_type === "facility",
  );

  const trendsData = [...communityTrends, ...buildingTrends];

  const columns: TableColumnsType<Row> = [
    {
      title: "Name",
      dataIndex: "sampling_location_name",
      filters: trendsData
        .map((d) => d.sampling_location_name)
        .sort()
        .map((x) => ({
          text: x,
          value: x,
        })),
      filterMode: "menu",
      filterMultiple: true,
      filterSearch: true,
      onFilter: (value, row) => row.sampling_location_name === value,
    },
    {
      title: "Location Type",
      dataIndex: "sampling_location_type",
      render: (type?: string) =>
        startCase(type === "facility" ? "Building" : type),
      filters: [
        {
          text: "Building",
          value: "facility",
        },
        {
          text: "Community",
          value: "community",
        },
      ],
      onFilter: (value, row) => row.sampling_location_type === value,
    },
    {
      title: "Beta Trends",
      render: ({ sampling_location_type, biobot_trend }: Row) => (
        <div>
          {sampling_location_type === "facility" ? (
            "Feature in R&D"
          ) : (
            <Tag
              color={tags[biobot_trend ?? "Unknown"].color}
              className="text-p2 font-light"
            >
              {tags[biobot_trend ?? "Unknown"].label}
            </Tag>
          )}
        </div>
      ),
      filters: Object.entries(tags).map(([value, { label }]) => ({
        text: label,
        value,
      })),
      onFilter: (value, row) =>
        row.biobot_trend === value && row.sampling_location_type !== "facility",
    },
  ];

  return (
    <div>
      {communityTrends?.length >= 1 ? (
        <DataDescription assayText={assay.text} />
      ) : (
        <BuildingsOnlyDescription />
      )}
      <Table
        rowKey="sampling_location_name"
        scroll={{ x: "min-content" }}
        loading={isLoading}
        columns={columns}
        pagination={false}
        dataSource={trendsData}
      />
      <div className="mt-5 mb-1">
        <WatchlistMethods />
      </div>
    </div>
  );
};

export default TrendsTable;
