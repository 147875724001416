import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

const HelpContext = createContext<
  readonly [string | null, Dispatch<SetStateAction<string | null>>] | null
>(null);

export const HelpProvider = ({ children }: { children: React.ReactNode }) => {
  const [helpId, setHelpId] = useState<string | null>(null);
  return (
    <HelpContext.Provider
      value={useMemo(() => [helpId, setHelpId] as const, [helpId, setHelpId])}
    >
      {children}
    </HelpContext.Provider>
  );
};

export const useHelpContext = () => {
  const val = useContext(HelpContext);
  if (!val) {
    throw new Error("useHelpContext must be used inside HelpProvider");
  }
  return val;
};
