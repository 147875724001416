import cx from "classnames";
import { every } from "lodash";

import api from "src/api";

const CovidExecSummary = () => {
  // TODO: pass this in from above instead of hitting the api here
  const { data: riskLevels, isLoading } = api.data.covidRiskLevel.useQuery({
    fields: ["sampling_location_name", "risk_level"],
  });

  const hasHighRiskLocation = riskLevels?.data?.some(
    ({ risk_level }) => risk_level === "high",
  );

  const insufficientData = every(riskLevels?.data, { risk_level: null });

  const summaryText = hasHighRiskLocation
    ? "at least one of your locations is at high risk. You could see a high SARS-CoV-2 concentration in your next sample."
    : "no locations are at high risk. It is unlikely you will see a high SARS-CoV-2 concentration in your next sample.";

  if (insufficientData) {
    return null;
  } else
    return (
      <p
        className={cx("text-h3", { invisible: isLoading })}
        data-test="summary-text"
      >
        Based on recent sample history, {summaryText}
      </p>
    );
};

export default CovidExecSummary;
