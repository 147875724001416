import BiobotCollapse from "src/components/BiobotCollapse";

export interface AboutTheDataProps {
  title?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}

/** A styled BiobotCollapse used for "About the data" sections. */
const AboutTheData = ({
  title = "About the data",
  className,
  children,
}: AboutTheDataProps) => (
  <BiobotCollapse className={className}>
    <BiobotCollapse.Panel
      key="0"
      header={title}
      className="bg-navy-1 p-4 rounded"
    >
      <div className="space-y-4 text-p2 font-light mt-1">{children}</div>
    </BiobotCollapse.Panel>
  </BiobotCollapse>
);

export default AboutTheData;
