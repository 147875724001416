import { useAuth0 } from "@auth0/auth0-react";
import {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from "react-query";

import usePermissionOverride from "./usePermissionOverride";

export interface AuthorizationParams {
  accessToken: string;
  permissionOverride?: string;
}

export interface UseMutationExtraOptions<
  TData = unknown,
  TInput = unknown,
  TError = unknown,
  TContext = unknown,
> extends Omit<
    UseMutationOptions<TData, TError, TInput, TContext>,
    "mutationFn"
  > {
  customerOverride?: boolean;
}

/**
 * Like react-query's {@link useMutation}, but with auth-related enhancements:
 * - includes the auth0 `accessToken` the mutationFn argument object.
 * - includes permissionOverride to mutationFn arguments.
 * - overrides may be disabled by passing { customerOverride: false } in the
 *   mutation option object.
 */
const useAuth0Mutation = <TData, TInput, TError = unknown, TContext = unknown>(
  mutationFn: MutationFunction<TData, { input: TInput } & AuthorizationParams>,
  options: UseMutationExtraOptions<TData, TInput, TError, TContext> = {},
): UseMutationResult<TData, TError, TInput, TContext> => {
  const { getAccessTokenSilently } = useAuth0();
  const { customerOverride, ...opts } = options;
  const permissionOverride = usePermissionOverride(customerOverride !== false);
  return useMutation(async (input) => {
    const accessToken = await getAccessTokenSilently();
    return mutationFn({ input, accessToken, permissionOverride });
  }, opts);
};

export default useAuth0Mutation;
