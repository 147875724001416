import { sortBy } from "lodash";
import { useMemo } from "react";

type Value = string | number | null | undefined;

interface Option<T extends Value> {
  label: string;
  value: T;
  [key: string]: unknown;
}

type OptionsInput<T extends Value> = readonly (Option<T> | string | number)[];
type Options<T extends Value> = Option<T>[];

/** Transforms string options into nested { label, value } options. */
const useOptions = <T extends Value>(
  options: OptionsInput<T>,
  opts?: { sort?: boolean },
) =>
  useMemo<Options<T>>(() => {
    const ret =
      typeof options[0] === "object" && "label" in options[0]
        ? (options as Options<T>)
        : (options.map((o) => ({ label: `${o}`, value: o })) as Options<T>);
    if (opts?.sort) {
      return sortBy(ret, "label");
    } else {
      return ret;
    }
  }, [options, opts?.sort]);

export default useOptions;
