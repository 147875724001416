import { memoize } from "lodash";

const UNITS = {
  volume: {
    L: 1,
    mL: 1000,
  },
  mass: {
    g: 1,
    mg: 1000,
  },
} satisfies UnitMap;

export type UnitMap = Record<string, Record<string, number>>;

type AvailableConversions<Units extends UnitMap, From extends string> = keyof {
  [K in keyof Units as Units[K] extends { [F in From]: number }
    ? keyof Units[K]
    : never]: true;
} &
  string;

type WithInverse<T extends string> = T | `1/${T}`;

export const unitConverterFactory = <Units extends UnitMap>(unitMap: Units) => {
  const units = Object.values(unitMap);
  const inverseUnits = units.map((conversions) =>
    Object.fromEntries(
      Object.entries(conversions).map(([k, v]) => [`1/${k}`, 1 / v]),
    ),
  );
  const allUnits = [...units, ...inverseUnits];
  return <From extends AvailableConversions<Units, string>>(
    from: WithInverse<From>,
    to: WithInverse<AvailableConversions<Units, From>>,
  ) => {
    const measure = allUnits.find(
      (measures) => from in measures && to in measures,
    );
    if (!measure) {
      throw new Error(`No conversion defined between "${from}" and "${to}"`);
    }
    const factor = measure[to] / measure[from];
    return (x: number) => x * factor;
  };
};

/**
 * Returns a function to convert from one unit to another.
 *
 * @example
 * const mL_to_L = unitConverter("mL", "L");
 * console.log(mL_to_L(500))  // => 0.5
 * console.log(mL_to_L(2000)) // => 2.0
 */
export const unitConverter = memoize(
  unitConverterFactory(UNITS),
  (from, to) => `${from} -> ${to}`,
);

/** Available units to convert. */
export type UnitName = WithInverse<AvailableConversions<typeof UNITS, string>>;
