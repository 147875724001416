import { startCase } from "lodash";
import { lazy } from "react";
import { Link, Route } from "react-router-dom";

import PageTitle from "src/components/PageTitle";

const pages = {
  "risk-tier": lazy(() => import("src/pages/Playground/RiskTierPage")),
};

const IndexPage = () => (
  <div>
    <PageTitle.Heading>Viz Playground</PageTitle.Heading>
    <ul>
      {Object.keys(pages).map((path) => (
        <li key={path} className="text-p1">
          <Link to={path}>{startCase(path)}</Link>
        </li>
      ))}
    </ul>
  </div>
);

export default (
  <Route path="/playground">
    <Route path="" element={<IndexPage />} />
    {Object.entries(pages).map(([path, Element]) => (
      <Route key={path} path={path} element={<Element />} />
    ))}
  </Route>
);
