/**
 * Color square to use in graph legends, tooltips, etc
 *
 * @param param0
 * @returns a square in the specified color
 */
export const ColorSquare = ({
  color,
  className,
}: {
  color?: string;
  className?: string;
}) => (
  <div
    className={`w-4 h-4 border border-solid border-white ${className ?? ""}`}
    style={{ background: color }}
  />
);
