interface SubstancesIconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}

const SubstancesIcon = ({ className, ...props }: SubstancesIconProps) => (
  <svg
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill={props.fill ?? "currentColor"}
    {...props}
  >
    <g>
      <path d="M13.9263 1.97261C13.7755 1.70714 13.4372 1.61865 13.1724 1.76982L11.6132 2.67316L7.00171 0L2.38653 2.66948L0.827296 1.76613C0.562521 1.61127 0.227874 1.70345 0.0734217 1.96892C-0.0773532 2.2344 0.0109053 2.56992 0.275681 2.72478L1.83491 3.62813V8.97077L6.4501 11.6402V13.4469C6.4501 13.753 6.69649 14 7.00171 14C7.30694 14 7.55333 13.753 7.55333 13.4469V11.6402L12.1685 8.97077V3.62813L13.7277 2.72478C13.9925 2.57361 14.0808 2.2344 13.93 1.96892L13.9263 1.97261ZM11.0616 8.33289L6.99803 10.6853L2.93447 8.33289V3.62813L6.99803 1.27574L11.0616 3.62813V8.33289Z" />
      <path d="M6.00556 3.26673L4.08594 4.37655V6.9686C4.08594 7.27463 4.33233 7.52167 4.63755 7.52167C4.94278 7.52167 5.18917 7.27463 5.18917 6.9686V5.01811L6.55717 4.22538C6.82195 4.07421 6.91021 3.73499 6.75943 3.46952C6.60866 3.20405 6.27033 3.11556 6.00556 3.26673Z" />
    </g>
  </svg>
);

export default SubstancesIcon;
