import { utcFormat } from "d3-time-format";
import dayjs from "dayjs";

import type { Concentration } from "src/components/GraphElements/ConcentrationSelector";
import { dateFormatter } from "src/components/GraphElements/formatters";

import { PCRDatum } from "../types";

// Types
export type Genogroup = (typeof genogroupOptions)[number]["value"];

export const genogroupOptions: {
  label: string;
  value: "NoV_GI" | "NoV_GII";
}[] = [
  { label: "GI", value: "NoV_GI" },
  { label: "GII", value: "NoV_GII" },
];

export const genogroupValues = genogroupOptions.map((o) => o.value);

// Accessors
export const getSampleDate = (d: PCRDatum) => new Date(d.sample_date);

export const getGenogroupLabel = (d: PCRDatum) =>
  d.primer_target?.replace("NoV_", "");

// Formatters
export const fmtDateLong = utcFormat("%b %d, %Y");

export const fmtDateUpdated = (date: string) =>
  dayjs(date).format("MMMM DD, YYYY");

export const fmtUnits = (unit: Concentration) =>
  unit === "1/L" ? "copies/L" : "copies/mL";

export const xTickFormat = dateFormatter({
  byDay: utcFormat("%b %-d '%y"), // "Mar 9 '23"
  byMonth: utcFormat("%b '%y"), // "Mar '23"
});
