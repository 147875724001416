import {
  Alert,
  Button,
  Collapse,
  ConfigProvider,
  Spin,
  Table,
  type TableColumnsType,
  ThemeConfig,
} from "antd";
import { useState } from "react";

import api from "src/api";
import TooltipHelp from "src/components/TooltipHelp";

import type { DataFilter } from "./types";

interface Props {
  dataFilter: DataFilter[];
}

const colTitleWithTooltip = (title: string, tooltip: string) => (
  <div className="space-x-2">
    <span>{title}</span>
    <TooltipHelp title={tooltip} />
  </div>
);

const Stat = ({
  title,
  value,
  help,
}: {
  title: string;
  value: React.ReactNode;
  help?: string;
}) => (
  <span className="text-p2 space-x-2">
    <span>{title}</span>
    {help && <TooltipHelp title={help} />}
    <span className="font-bold">{value}</span>
  </span>
);

interface CollapsibleSummaryProps<T extends object> {
  title: string;
  data: T[];
  columns: TableColumnsType<T>;
}

const CollapsibleSummary = <T extends { key: string }>({
  title,
  data,
  columns,
}: CollapsibleSummaryProps<T>) => {
  const [keys, setKeys] = useState<React.Key[]>([]);
  const hasKeys = keys.length > 0;
  return (
    <Collapse ghost size="large" className="flex-1">
      <Collapse.Panel
        key="dataset"
        className="p-0"
        header={<Stat title={title} value={data.length} />}
      >
        <Button
          type="link"
          onClick={() => setKeys(hasKeys ? [] : data.map((d) => d.key))}
        >
          {hasKeys ? "Collapse all" : "Expand all"}
        </Button>
        <Table
          rowKey="key"
          dataSource={data}
          columns={columns}
          expandedRowKeys={keys}
          onExpandedRowsChange={setKeys}
          pagination={false}
          size="small"
          scroll={{ x: "max-content" }}
          expandable={{ expandRowByClick: true }}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

const collapseTheme: ThemeConfig = {
  components: {
    Collapse: {
      padding: 8,
      paddingLG: 0,
    },
  },
};

const DatasetSummaryBody = ({ dataFilter }: Props) => {
  const { isLoading, isError, data } = api.customer.detail.datasets.useQuery({
    data_filter: dataFilter,
  });

  const { message, data: stats } = data ?? {};

  if (isLoading) {
    return <Spin />;
  } else if (isError || !stats) {
    return (
      <Alert
        message={message ?? "Error loading dataset summary"}
        type="error"
      />
    );
  }
  return (
    <div className="flex flex-col">
      <Stat
        title="Total data points"
        value={stats.summary.row_count}
        help="Total number of data points available (e.g. covid=1, flu=2, xylazine=2)"
      />
      <Stat
        title="Total kits"
        value={stats.summary.kit_count}
        help="Total number of kits"
      />
      <Stat title="Earliest date" value={stats.summary.start_date} />
      <Stat title="Latest date" value={stats.summary.end_date} />
      <ConfigProvider theme={collapseTheme}>
        <CollapsibleSummary
          title="Datasets"
          data={stats.dataset}
          columns={[
            { title: "Dataset", dataIndex: "label" },
            {
              title: colTitleWithTooltip(
                "Count",
                "For kit datasets: total number of kits. For averages: number of available dates",
              ),
              dataIndex: "count",
            },
            { title: "Start date", dataIndex: "start_date" },
            { title: "End date", dataIndex: "end_date" },
          ]}
        />
        <CollapsibleSummary
          title="Locations"
          data={stats.location}
          columns={[
            { title: "Sampling location", dataIndex: "label" },
            { title: "Kit count", dataIndex: "count" },
            { title: "Start date", dataIndex: "start_date" },
            { title: "End date", dataIndex: "end_date" },
          ]}
        />
        <CollapsibleSummary
          title="County averages"
          data={stats.county}
          columns={[
            { title: "County", dataIndex: "label" },
            {
              title: colTitleWithTooltip("Count", "Number of available dates"),
              dataIndex: "count",
            },
            { title: "Start date", dataIndex: "start_date" },
            { title: "End date", dataIndex: "end_date" },
          ]}
        />
        <CollapsibleSummary
          title="State averages"
          data={stats.state}
          columns={[
            { title: "State", dataIndex: "label" },
            {
              title: colTitleWithTooltip("Count", "Number of available dates"),
              dataIndex: "count",
            },
            { title: "Start date", dataIndex: "start_date" },
            { title: "End date", dataIndex: "end_date" },
          ]}
        />
        <CollapsibleSummary
          title="Regional averages"
          data={stats.regional}
          columns={[
            { title: "Census region", dataIndex: "label" },
            {
              title: colTitleWithTooltip("Count", "Number of available dates"),
              dataIndex: "count",
            },
            { title: "Start date", dataIndex: "start_date" },
            { title: "End date", dataIndex: "end_date" },
          ]}
        />
        <CollapsibleSummary
          title="National averages"
          data={stats.national}
          columns={[
            { title: "Country", dataIndex: "label" },
            {
              title: colTitleWithTooltip("Count", "Number of available dates"),
              dataIndex: "count",
            },
            { title: "Start date", dataIndex: "start_date" },
            { title: "End date", dataIndex: "end_date" },
          ]}
        />
      </ConfigProvider>
    </div>
  );
};

const DatasetSummary = ({ dataFilter }: Props) => {
  if (!dataFilter.length) {
    return <Alert message="No datasets to show." type="error" />;
  } else {
    return <DatasetSummaryBody dataFilter={dataFilter} />;
  }
};

export default DatasetSummary;
