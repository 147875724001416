import { useContext } from "react";

import {
  CustomerContext,
  CustomerOverrideContext,
  CustomerOverrideUpdateContext,
} from "./Context";
import type { Customer, CustomerOverride, UiPermission } from "./Context";

export type { Customer, UiPermission, CustomerOverride };

/** Hook that returns the current customer. */
export const useCustomer = () => {
  const value = useContext(CustomerContext);
  if (value == null) {
    throw new Error("useCustomer must be used in Customer context");
  }
  return value;
};

/** Hook that returns customer overrides (admin-only) */
export const useCustomerOverride = () => useContext(CustomerOverrideContext);

/** Hook that returns a setter for customer overrides (admin-only) */
export const useSetCustomerOverride = () =>
  useContext(CustomerOverrideUpdateContext);
