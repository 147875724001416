import { identity } from "lodash";

import {
  Accessor,
  AnyGeoSeries,
  AnySeries,
  BBChartFeatureCollection,
  GeoTooltipComponent,
  GeoTooltipProps,
  TooltipComponent,
  TooltipProps,
  makeAccessor,
} from "src/bb-chart";
import useDeepCompareMemo from "src/hooks/useDeepCompareMemo";

import { ColorSquare } from "./ColorSquare";

export type ReactAccessor<Datum> =
  | keyof Datum
  | Accessor<Datum, React.ReactNode>;

// == XY ======================================================================

interface StdTooltipProps<S extends AnySeries[]> {
  className?: string;
  title?: ReactAccessor<S[number]["data"][number]>;
  units?: React.ReactNode;
  fmtX?: (x: NonNullable<ReturnType<S[number]["x"]>>) => React.ReactNode;
  fmtY?: (y: NonNullable<ReturnType<S[number]["y"]>>) => React.ReactNode;
  zero?: React.ReactNode;
  nearestOnly?: boolean;
  showColor?: boolean;
}

const definedSeries = <D,>(series: Record<string, D>) =>
  Object.values(series).filter(Boolean) as NonNullable<D>[];

interface TooltipRowProps {
  label?: React.ReactNode;
  color?: string;
  x?: React.ReactNode;
  y: React.ReactNode;
  units?: React.ReactNode;
}

export const TooltipRow = ({ label, color, x, y, units }: TooltipRowProps) => (
  <>
    <div className="flex gap-2">
      {color && <ColorSquare className="flex-none" color={color} />}
      {label != null && (
        <span className="flex-grow w-72 max-w-fit truncate">{label}</span>
      )}
    </div>
    <div>
      {x}
      {x != null && ": "}
      <span className="font-bold">{y}</span> {units}
    </div>
  </>
);

export const stdTooltip = <S extends AnySeries[]>({
  className,
  title,
  fmtX = identity,
  fmtY = identity,
  zero,
  units,
  nearestOnly,
  showColor = true,
}: StdTooltipProps<S>): TooltipComponent<S> => {
  const titleAccessor = title ? makeAccessor(title) : null;
  const fmtUnits = typeof units === "function" ? units : () => units;
  if (nearestOnly) {
    return ({ nearest: { label, color, x, y, datum } }: TooltipProps<S>) => {
      const zeroOverride = y === 0 && zero != null;
      return (
        <div className={className}>
          {titleAccessor && (
            <div className="font-bold truncate">{titleAccessor(datum)}</div>
          )}
          <TooltipRow
            label={label}
            color={showColor ? color : undefined}
            x={fmtX(x)}
            y={zeroOverride ? zero : (fmtY(y) ?? "No data")}
            units={zeroOverride ? null : fmtUnits(y)}
          />
        </div>
      );
    };
  } else {
    return ({ series }: TooltipProps<S>) => (
      <div className="flex flex-col gap-2">
        {definedSeries(series).map(({ seriesKey, label, color, x, y }) => {
          const zeroOverride = y === 0 && zero != null;
          return (
            <TooltipRow
              key={seriesKey}
              label={label}
              color={showColor ? color : undefined}
              x={fmtX(x)}
              y={zeroOverride ? zero : (fmtY(y) ?? "No data")}
              units={zeroOverride ? null : fmtUnits(y)}
            />
          );
        })}
      </div>
    );
  }
};

export const useStdTooltip = <S extends AnySeries[]>(
  props: StdTooltipProps<S>,
) => useDeepCompareMemo(() => stdTooltip(props), [props]);

// == Geo =====================================================================

interface StdGeoTooltipProps<
  S extends AnyGeoSeries[],
  G extends BBChartFeatureCollection = BBChartFeatureCollection,
> {
  className?: string;
  title?: ReactAccessor<S[number]["data"][string]["datum"]>;
  units?: React.ReactNode;
  fmtFeature?: ReactAccessor<G["features"][number]["properties"]>;
  fmtValue?: (
    val: NonNullable<ReturnType<S[number]["data"][string]["value"]>>,
  ) => React.ReactNode;
  zero?: React.ReactNode;
  nearestOnly?: boolean;
}

export const stdGeoTooltip = <
  S extends AnyGeoSeries[],
  G extends BBChartFeatureCollection = BBChartFeatureCollection,
>({
  className,
  title,
  fmtFeature = "name",
  fmtValue = identity,
  zero,
  units,
  nearestOnly,
}: StdGeoTooltipProps<S, G>): GeoTooltipComponent<S, G> => {
  const titleAccessor = title ? makeAccessor(title) : null;
  const featureAccessor = fmtFeature ? makeAccessor(fmtFeature) : null;
  const fmtUnits = typeof units === "function" ? units : () => units;
  if (nearestOnly) {
    return ({
      nearest: { label, datum, value, feature },
    }: GeoTooltipProps<S, G>) => {
      const zeroOverride = value === 0 && zero != null;
      return (
        <div className={className}>
          {titleAccessor && (
            <div className="font-bold truncate">{titleAccessor(datum)}</div>
          )}
          <TooltipRow
            label={featureAccessor?.(feature.properties)}
            x={label}
            y={zeroOverride ? zero : (fmtValue(value) ?? "No data")}
            units={zeroOverride ? null : fmtUnits(value)}
          />
        </div>
      );
    };
  } else {
    return ({ series }: GeoTooltipProps<S, G>) => (
      <div className="flex flex-col gap-2">
        {definedSeries(series).map(({ seriesKey, label, value, feature }) => {
          const zeroOverride = value === 0 && zero != null;
          return (
            <TooltipRow
              key={seriesKey}
              label={featureAccessor?.(feature.properties)}
              x={label}
              y={zeroOverride ? zero : (fmtValue(value) ?? "No data")}
              units={zeroOverride ? null : fmtUnits(value)}
            />
          );
        })}
      </div>
    );
  }
};

export const useStdGeoTooltip = <
  S extends AnyGeoSeries[],
  G extends BBChartFeatureCollection = BBChartFeatureCollection,
>(
  props: StdGeoTooltipProps<S, G>,
) => useDeepCompareMemo(() => stdGeoTooltip(props), [props]);
