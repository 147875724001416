import { useState } from "react";
import ReactLagRadar from "react-lag-radar";

const LagRadar = () => {
  const [paused, setPaused] = useState(true);
  return (
    <button
      style={{
        position: "fixed",
        bottom: 0,
        left: 60,
        zIndex: 99999,
        height: 50,
        width: 50,
        cursor: "pointer",
        padding: 0,
        margin: 6,
        border: "none",
        background: "none",
      }}
      type="button"
      onClick={() => setPaused((x) => !x)}
      title={paused ? "click to start" : "click to pause"}
    >
      {paused ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: 50,
            height: 50,
            borderRadius: "50%",
            backgroundImage:
              "conic-gradient(from 200deg, transparent 0deg, #14B814 90deg, #47EB47 90deg 100deg, transparent 100deg)",
          }}
        />
      ) : (
        <ReactLagRadar size={50} frames={50} />
      )}
    </button>
  );
};

export default process.env.NODE_ENV === "development" ? LagRadar : () => null;
