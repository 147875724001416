import BiobotCard from "src/components/BiobotCard";
import { Concentration } from "src/components/GraphElements/ConcentrationSelector";
import useId from "src/hooks/useId";

import PCRSummaryBody from "../PCRSummaryBody";
import type { PCRDatum } from "../types";

export interface RsvSummaryCardProps {
  data: PCRDatum[];
  setConcentrationUnit: (value: Concentration) => void;
  concentrationUnit: Concentration;
}
const RsvSummaryCard = ({
  data: rawData,
  setConcentrationUnit,
  concentrationUnit,
}: RsvSummaryCardProps) => {
  const titleId = useId();
  const subtitleId = useId();
  return (
    <BiobotCard
      title="Compare RSV across Locations"
      subtitle="Discover trends in RSV with this interactive time series graph. Select multiple locations to look at trends over time and across locations. This visualization supports monitoring and comprehension of RSV dynamics in wastewater."
      titleId={titleId}
      subtitleId={subtitleId}
    >
      <PCRSummaryBody
        rawData={rawData}
        setConcentrationUnit={setConcentrationUnit}
        concentrationUnit={concentrationUnit}
        aria-labelledby={titleId}
        aria-describedby={subtitleId}
      />
    </BiobotCard>
  );
};

export default RsvSummaryCard;
