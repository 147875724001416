/* eslint-disable @typescript-eslint/no-explicit-any */

type CacheKeyList = readonly CacheKey[];
type CacheKey = any;
export type Cache = Map<CacheKey, any>;

interface Leaf<T = any> {
  keyList: CacheKeyList;
  value?: T;
  refs: number;
  timeoutId?: NodeJS.Timeout;
}

const LEAF_KEY = Symbol("__leaf");
const defaultGcTimeout = 1000 * 60 * 1; // 1 minute

const getLeaf = <T>(cache: Cache, keyList: CacheKeyList): Leaf<T> => {
  const final = keyList.reduce<Cache>((m, k) => {
    let next = m.get(k);
    if (!next) {
      next = new Map();
      m.set(k, next);
    }
    return next;
  }, cache);
  const leaf = final.get(LEAF_KEY);
  if (leaf) {
    return leaf as Leaf<T>;
  } else {
    const newLeaf: Leaf<T> = { keyList, refs: 0 };
    final.set(LEAF_KEY, newLeaf);
    return newLeaf;
  }
};

const deleteLeaf = (cache: Cache, keyList: CacheKeyList) => {
  // Collect all nodes along the keyList path
  const parents: [Cache, CacheKey][] = [];
  const final = keyList.reduce<Cache>((m, k) => {
    parents.push([m, k]);
    return m.get(k);
  }, cache);
  // Delete the leaf
  final.delete(LEAF_KEY);
  // Walk backward to the root and delete any nodes that are now empty (i.e.
  // they were only part of this keyList).
  let [parent, childKey] = parents.pop() ?? [];
  while (parent?.get(childKey)?.size === 0) {
    parent.delete(childKey);
    [parent, childKey] = parents.pop() ?? [];
  }
};

const createCache = ({ gcTimeout = defaultGcTimeout } = {}) => {
  const cache = new Map();

  return {
    cache,

    clear() {
      cache.clear();
    },

    fetch<T>(fn: () => T, keyList: CacheKeyList) {
      const leaf = getLeaf<T>(cache, keyList);
      if (!("value" in leaf)) {
        leaf.value = fn();
      }
      return leaf.value as T;
    },

    incRef(keyList: CacheKeyList) {
      const leaf = getLeaf(cache, keyList);
      leaf.refs += 1;
      if (leaf.timeoutId) {
        clearTimeout(leaf.timeoutId);
        delete leaf.timeoutId;
      }
    },

    decRef(keyList: CacheKeyList) {
      const leaf = getLeaf(cache, keyList);
      leaf.refs -= 1;
      if (leaf.refs === 0 && gcTimeout !== -1) {
        if (leaf.timeoutId) {
          clearTimeout(leaf.timeoutId);
        }
        leaf.timeoutId = setTimeout(
          () => deleteLeaf(cache, keyList),
          gcTimeout,
        );
      }
    },
  };
};

export default createCache;
