interface InfectiousDiseaseIconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}

const InfectiousDiseaseIcon = ({
  className,
  ...props
}: InfectiousDiseaseIconProps) => (
  <svg
    viewBox="0 0 36.63 36.63"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill={props.fill ?? "currentColor"}
    {...props}
  >
    <g>
      <path d="m35.13 14.77c-.83 0-1.5.67-1.5 1.5v.55h-2.56c-.27-2.31-1.16-4.55-2.67-6.47l1.81-1.81.39.39c.29.29.68.44 1.06.44s.77-.15 1.06-.44c.59-.59.59-1.54 0-2.12l-2.9-2.9c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12l.39.39-1.8 1.8c-1.91-1.51-4.16-2.4-6.46-2.67v-2.56h.55c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-4.1c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h.55v2.56c-2.31.27-4.55 1.16-6.46 2.67l-1.81-1.81.39-.39c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0l-2.9 2.9c-.59.59-.59 1.54 0 2.12.29.29.68.44 1.06.44s.77-.15 1.06-.44l.39-.39 1.81 1.81c-1.51 1.91-2.4 4.16-2.67 6.46h-2.57v-.55c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v4.1c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-.55h2.57c.27 2.3 1.16 4.55 2.67 6.46l-1.81 1.81-.39-.39c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12l2.9 2.9c.29.29.68.44 1.06.44s.77-.15 1.06-.44c.59-.59.59-1.54 0-2.12l-.39-.39 1.81-1.81c1.91 1.51 4.15 2.4 6.46 2.67v2.57h-.55c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h4.1c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-.55v-2.57c2.31-.27 4.55-1.15 6.46-2.66l1.81 1.81-.39.39c-.59.59-.59 1.54 0 2.12.29.29.68.44 1.06.44s.77-.15 1.06-.44l2.9-2.9c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0l-.39.39-1.81-1.81c1.51-1.91 2.4-4.16 2.67-6.46h2.56v.55c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4.1c0-.83-.67-1.5-1.5-1.5zm-23.78-3.42c1.92-1.92 4.44-2.88 6.97-2.88s5.05.96 6.97 2.88c3.84 3.84 3.84 10.1 0 13.94s-10.09 3.84-13.94 0c-3.84-3.84-3.84-10.09 0-13.94z" />
      <circle cx="16.41" cy="20.22" r="4.58" />
      <path d="m22.39 16.27c1.12 0 2.03-.91 2.03-2.03s-.91-2.03-2.03-2.03-2.03.91-2.03 2.03.91 2.03 2.03 2.03z" />
    </g>
  </svg>
);

export default InfectiousDiseaseIcon;
