import { capitalize, mapValues } from "lodash";

import api, { type ApiOutput } from "src/api";

import type { CustomerFormInfo } from "./types";

const niceDatasetName = (x: string) => {
  if (x.startsWith("*")) {
    return `All ${x.slice(1)} datasets`;
  } else {
    const parts = x.split(":");
    const suffix = parts.includes("interpolated") ? " (interpolated)" : "";
    return capitalize(parts[0]) + suffix;
  }
};

const niceOptionName = (x: string) => (x === "*all" ? "(All)" : x);

export const transformFormInfo = ({
  options: globalOptions,
  filters,
}: ApiOutput<"customer.form.getInfo">): CustomerFormInfo =>
  mapValues(filters, ({ filter_type, ...filtersConfig }) => ({
    filterType: filter_type.options[0].value as string,
    fields: mapValues(filtersConfig, (config, name) => ({
      ...config,
      name,
      options: (globalOptions[name] ?? config.options).map(
        ({ label, value }) => ({
          value,
          label:
            name === "dataset_name"
              ? niceDatasetName(label)
              : niceOptionName(label),
        }),
      ),
    })),
  }));

const useCustomerFormInfo = () =>
  api.customer.form.getInfo.useQuery({}, { select: transformFormInfo });

export default useCustomerFormInfo;
