import { Switch } from "antd";
import { useState } from "react";

import { ChartThemeProvider, useOrdinalColorScale } from "src/bb-chart";
import BiobotCard from "src/components/BiobotCard";
import { SmallMultiples } from "src/components/ChartLayout";
import RangePickerWithPresets, {
  DateRange,
} from "src/components/DateRangePickerWithPresets";
import EmptyPlot from "src/components/EmptyPlot";
import CheckboxLegend from "src/components/GraphElements/CheckboxLegend";
import ConcentrationSelector, {
  Concentration,
} from "src/components/GraphElements/ConcentrationSelector";
import { useLocationTypeSelector } from "src/components/GraphElements/LocationTypeSelector";
import useExtent from "src/hooks/useExtent";
import useTransformedData from "src/hooks/useTransformedData";
import useUniqueValues from "src/hooks/useUniqueValues";
import { smallChartTheme } from "src/theme/chartTheme";

import { PCRDatum } from "../types";
import NoroLocationChart from "./NoroLocationChart";
import {
  Genogroup,
  fmtDateUpdated,
  fmtUnits,
  genogroupOptions,
  genogroupValues,
} from "./util";

interface NoroLocationsBodyProps {
  data: PCRDatum[];
  concentrationUnit: Concentration;
  setConcentrationUnit: (value: Concentration) => void;
}

export interface NoroLocationsCardProps extends NoroLocationsBodyProps {
  dateLastUpdated?: string;
}

const NoroLocationsBody = ({
  data: rawData_,
  concentrationUnit,
  setConcentrationUnit,
}: NoroLocationsBodyProps) => {
  const { data: rawData, locationTypeControl } =
    useLocationTypeSelector(rawData_);

  const [locationNames] = useUniqueValues(rawData, ["sampling_location_name"]);
  const colorScale = useOrdinalColorScale(genogroupValues);

  const [isStandardized, setIsStandardized] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>();
  const [genogroups, setGenogrous] = useState<Genogroup[]>(genogroupValues);
  const fullDateExtent = useExtent(rawData, "sample_date");

  const data = useTransformedData(rawData, {
    filter: {
      sample_date: { between: dateRange },
      primer_target: { in: genogroups },
    },
    units: {
      effective_concentration: { from: "1/L", to: concentrationUnit },
    },
  });

  const concentrationRange = useExtent(data, "effective_concentration");

  const empty =
    genogroups.length === 0 ? (
      <EmptyPlot status="select-genogroup" />
    ) : (
      <EmptyPlot status="no-results-timeframe" />
    );

  return (
    <div>
      <div className="flex flex-col gap-8 py-6 sticky top-0 bg-white z-[100]">
        <div className="flex flex-wrap items-end gap-8">
          <div className="-mb-1">
            <div className="text-p2 text-navy-9">Select Date Range</div>
            <RangePickerWithPresets value={dateRange} onChange={setDateRange} />
          </div>
          <ConcentrationSelector
            value={concentrationUnit}
            onChange={setConcentrationUnit}
          />
          <CheckboxLegend
            value={genogroups}
            onChange={setGenogrous}
            options={genogroupOptions}
            colorScale={colorScale}
            className="[&>label]:text-p2-thin"
          />
          {locationNames.length > 1 && (
            <label className="text-p2-thin flex items-center gap-2">
              <Switch
                onChange={setIsStandardized}
                checked={isStandardized}
                title="Standardize Graph Axes"
              />
              Standardize Axes
            </label>
          )}
        </div>
        {locationTypeControl}
      </div>
      <SmallMultiples.GridWithExpand
        data={data}
        groupBy="sampling_location_name"
        keys={locationNames}
        renderChart={({ data: chartData, isExpanded }) => (
          <div className="aspect-[16/9]">
            <NoroLocationChart
              data={chartData}
              unitsText={fmtUnits(concentrationUnit)}
              dateRange={dateRange ?? (isStandardized ? fullDateExtent : null)}
              concentrationRange={
                isStandardized ? concentrationRange : undefined
              }
              colorScale={colorScale}
              isExpanded={isExpanded}
              empty={empty}
            />
          </div>
        )}
      />
    </div>
  );
};

const NoroLocationsCard = ({
  dateLastUpdated,
  ...props
}: NoroLocationsCardProps) => (
  <BiobotCard
    title="Compare GI and GII within Individual Locations"
    subtitle="Dive into location-specific time series graphs in this section,
    featuring both GI and GII norovirus genogroups. Gain valuable insights
    into the local levels and trends of these predominant genogroups
    contributing to norovirus infections and outbreaks."
  >
    {dateLastUpdated && (
      <div
        className="text-p2-thin text-navy-7 mr-4 italic"
        data-test="noro-locations-wastewater"
      >
        Wastewater data last updated {fmtDateUpdated(dateLastUpdated)}
      </div>
    )}
    <ChartThemeProvider value={smallChartTheme}>
      <NoroLocationsBody {...props} />
    </ChartThemeProvider>
  </BiobotCard>
);

export default NoroLocationsCard;
