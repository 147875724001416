import { Button, Form, Input, message } from "antd";
import dayjs, { type Dayjs } from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import api, { getErrorMessage } from "src/api";
import DateStringPicker, {
  DateStringPickerProps,
} from "src/components/DateStringPicker";
import PageTitle from "src/components/PageTitle";

import ApiTokenCard from "./ApiTokenCard";
import {
  type ApiTokenFormData,
  type NewApiToken,
  initialTokenFormValues,
  tokenFormDataToPayload,
} from "./util";

interface ApiTokenFormProps {
  onFinish: (valus: ApiTokenFormData) => void;
  onCancel?: () => void;
  initialValues?: ApiTokenFormData;
}

const expiresPresets: DateStringPickerProps["presets"] = [
  { label: "7 days", value: dayjs().add(7, "days") },
  { label: "30 days", value: dayjs().add(30, "days") },
  { label: "60 days", value: dayjs().add(60, "days") },
  { label: "90 days", value: dayjs().add(90, "days") },
  { label: "No expiration", value: null as unknown as Dayjs },
];

const ApiTokenForm = ({
  onFinish,
  onCancel,
  initialValues,
}: ApiTokenFormProps) => {
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="nickname"
        rules={[{ required: true }]}
        label="Token name"
        extra="Identify what this token will be used for"
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item name="expires_at" label="Expiration">
        <DateStringPicker
          disabledDate={(current) => current && current.valueOf() < Date.now()}
          presets={expiresPresets}
        />
      </Form.Item>
      <Form.Item>
        <div className="flex justify-end gap-2">
          {onCancel && <Button onClick={onCancel}>Cancel</Button>}
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

const ApiTokenCreatePage = () => {
  const createToken = api.user.createToken.useMutation();
  const navigate = useNavigate();
  const [newToken, setNewToken] = useState<NewApiToken | null>(null);

  const onFinish = (formValues: ApiTokenFormData) => {
    createToken.mutate(tokenFormDataToPayload(formValues), {
      onError(error) {
        message.error(
          `Failed to generate token: ${getErrorMessage(error)}`,
          10,
        );
      },
      onSuccess(res) {
        setNewToken(res.data);
      },
    });
  };

  return (
    <>
      <PageTitle.Heading back="/settings/tokens">
        New API Token
      </PageTitle.Heading>
      {newToken ? (
        <ApiTokenCard token={newToken} allowCopy />
      ) : (
        <ApiTokenForm
          onFinish={onFinish}
          onCancel={() => navigate("/settings/tokens")}
          initialValues={initialTokenFormValues}
        />
      )}
    </>
  );
};

export default ApiTokenCreatePage;
