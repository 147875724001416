import BiobotCard from "src/components/BiobotCard";
import { Concentration } from "src/components/GraphElements/ConcentrationSelector";
import useId from "src/hooks/useId";

import PCRSummaryBody from "../PCRSummaryBody";
import { PCRDatum } from "../types";
import { fluPrimerTargetOptions } from "./util";

export interface FluSummaryCardProps {
  data: PCRDatum[];
  setConcentrationUnit: (value: Concentration) => void;
  concentrationUnit: Concentration;
}

const FluSummaryCard = ({
  data: rawData,
  setConcentrationUnit,
  concentrationUnit,
}: FluSummaryCardProps) => {
  const titleId = useId();
  const subtitleId = useId();
  return (
    <BiobotCard
      title="Compare Influenza A & B across Locations"
      subtitle="Investigate patterns in influenza A and B levels using this dynamic time series chart. Choose various locations and switch between influenza A or B to study fluctuations over time and in different places. This graphic tool assists in tracking and comprehending the prevalence of these viruses in the environment."
      titleId={titleId}
      subtitleId={subtitleId}
    >
      <PCRSummaryBody
        rawData={rawData}
        targetNameOptions={fluPrimerTargetOptions}
        setConcentrationUnit={setConcentrationUnit}
        concentrationUnit={concentrationUnit}
        aria-labelledby={titleId}
        aria-describedby={subtitleId}
      />
    </BiobotCard>
  );
};

export default FluSummaryCard;
