import { EditOutlined } from "@ant-design/icons";
import { Alert, Spin } from "antd";
import dayjs from "dayjs";
import dayjsPluginRelativeTime from "dayjs/plugin/relativeTime";
import { Link, useParams } from "react-router-dom";

import api from "src/api";
import BiobotCard from "src/components/BiobotCard";
import NotificationList from "src/components/NotificationList";
import PageTitle from "src/components/PageTitle";

import Auth0RoleLink from "./Auth0RoleLink";
import DataFilterTags from "./DataFilterTags";
import DatasetSummary from "./DatasetSummary";
import type { Customer, CustomerFormInfo } from "./types";
import useCustomerFormInfo from "./useCustomerFormInfo";

dayjs.extend(dayjsPluginRelativeTime);

interface Params {
  customer_code: string;
}

interface InnerProps {
  customer: Customer;
  info?: CustomerFormInfo;
}

const CustomerDataFilters = ({ customer, info }: InnerProps) => (
  <div>
    {customer.data_filter.map((dataFilter, idx) => (
      <div className="text-base flex flex-wrap items-center mt-2" key={idx}>
        <div className="mr-2 whitespace-nowrap">
          {dataFilter.filter_type} datasets:
        </div>
        <DataFilterTags key={idx} dataFilter={dataFilter} info={info} />
      </div>
    ))}
  </div>
);

const CustomerDetails = ({ customer, info }: InnerProps) => {
  const items = [
    ["Customer name", customer.customer_name],
    ["Customer code", customer.customer_code],
    ["Show risk scores?", customer.show_risk_scores ? "Yes" : "No"],
    ["Auth0 role", <Auth0RoleLink roleId={customer.role_id} />],
    ["Data slices", <CustomerDataFilters customer={customer} info={info} />],
  ] as const;

  return (
    <div className="space-y-4">
      {items.map(([label, value]) => (
        <div key={label}>
          <p className="text-p2-thin text-neutral-7">{label}</p>
          <div className="text-p1">{value}</div>
        </div>
      ))}
    </div>
  );
};

const ViewCustomerPage = () => {
  const { customer_code } = useParams<keyof Params>() as Params;
  const { data: customer, isLoading } = api.customer.get.useQuery({
    customer_code,
  });
  const { data: info } = useCustomerFormInfo();

  if (isLoading) {
    return <Spin size="small" />;
  } else if (!customer) {
    return (
      <Alert message="Error loading customer" type="error" className="py-0" />
    );
  }

  return (
    <>
      <PageTitle title={`Customer: ${customer.data.customer_name}`} back=".." />
      <div className="flex gap-4">
        <Link to="edit">
          <EditOutlined /> Edit
        </Link>
        <Link
          to={`/?customerCode=${customer.data.customer_code}&customerMode=0`}
        >
          View platform as customer
        </Link>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
        <BiobotCard title="Details">
          <CustomerDetails customer={customer.data} info={info} />
        </BiobotCard>
        <BiobotCard title="Dataset summary">
          <DatasetSummary dataFilter={customer.data.data_filter} />
        </BiobotCard>
        <BiobotCard title="Notifications" className="lg:col-span-2">
          <NotificationList
            filter={{ customer_code: customer.data.customer_code }}
          />
        </BiobotCard>
      </div>
    </>
  );
};

export default ViewCustomerPage;
