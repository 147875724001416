import { every, isUndefined } from "lodash";
import { useContext, useEffect } from "react";
import {
  UNSAFE_DataRouterContext,
  UNSAFE_NavigationContext,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  ArrayParam as ArrayParamBase,
  BooleanParam as BooleanParamBase,
  NumericArrayParam as NumericArrayParamBase,
  QueryParamAdapter,
  QueryParamAdapterComponent,
  QueryParamConfig,
  QueryParamConfigMap,
  StringParam as StringParamBase,
  useQueryParams,
  withDefault,
} from "use-query-params";

/** Like ArrayParam from use-query-params, but excluding nulls */
export const ArrayParam = withDefault(
  ArrayParamBase,
  undefined,
) as QueryParamConfig<string[] | undefined>;

/** Like NumericArrayParam from use-query-params, but excluding nulls */
export const NumericArrayParam = withDefault(
  NumericArrayParamBase,
  undefined,
) as QueryParamConfig<number[] | undefined>;

/** Like BooleanParam from use-query-params, but excluding nulls */
export const BooleanParam = withDefault(
  BooleanParamBase,
  undefined,
) as QueryParamConfig<boolean | undefined>;

/** Like StringParam from use-query-params, but excluding nulls */
export const StringParam = withDefault(
  StringParamBase,
  undefined,
) as QueryParamConfig<string | undefined>;

/**
 * Wrapper around useQueryParams that perists params when a navigation event
 * occurs without query params.
 */
export const useSessionQueryParams = <TConfig extends QueryParamConfigMap>(
  config: TConfig,
  defaults: { [K in keyof TConfig]: TConfig[K]["default"] },
) => {
  const storageKey = `sticky-${Object.keys(config).sort().join("-")}:`;
  const [params, setParams] = useQueryParams(config);

  // If the search string is cleared, assume this is from a navigation event
  // that does not include query params. In that case, we want to reset to the
  // stored values (or defaults if there are none).
  let replaceParams: typeof params | null = null;
  if (every(params, isUndefined)) {
    const savedValues = sessionStorage.getItem(storageKey);
    replaceParams = savedValues ? JSON.parse(savedValues) : defaults;
    // break a potential infinite loop where savedValues is an empty object
    if (every(replaceParams, isUndefined)) {
      replaceParams = defaults;
    }
  }

  // Persist params in the query string and in sessionStorage any time they
  // change.
  useEffect(() => {
    if (replaceParams) {
      setParams(replaceParams, "replace");
    }
    sessionStorage.setItem(storageKey, JSON.stringify(replaceParams ?? params));
  }, [params, replaceParams, setParams, storageKey]);

  // If we have replaceParams, it's because params are invalid. While we did
  // just reset params to be replaceParams, that isn't going to take effect
  // until the next render. We return replaceParams immediately so that the
  // caller has a valid value at all times.
  return [replaceParams ?? params, setParams] as const;
};

/**
 * Hacked version of ReactRouter6Adapter from useQueryParams to support
 * keeping url fragments.
 *
 * This is copied from the file in the source repo:
 * https://github.com/pbeshai/use-query-params/blob/b14c97ec2e7b1dfaca51d4d17439f9c306b34dba/packages/use-query-params-adapter-react-router-6/src/index.ts
 *
 * ... with edits recommended in this github issue:
 * https://github.com/pbeshai/use-query-params/issues/229
 */
export const ReactRouter6AdapterWithHash: QueryParamAdapterComponent = ({
  children,
}) => {
  // we need the navigator directly so we can access the current version
  // of location in case of multiple updates within a render (e.g. #233)
  // but we will limit our usage of it and have a backup to just use
  // useLocation() output in case of some kind of breaking change we miss.
  // see: https://github.com/remix-run/react-router/blob/f3d87dcc91fbd6fd646064b88b4be52c15114603/packages/react-router-dom/index.tsx#L113-L131
  const { navigator } = useContext(UNSAFE_NavigationContext);
  const navigate = useNavigate();
  const router = useContext(UNSAFE_DataRouterContext)?.router;
  const location = useLocation();

  const adapter: QueryParamAdapter = {
    replace(loc) {
      navigate(loc, { replace: true, state: loc.state });
    },
    push(loc) {
      navigate(loc, { replace: false, state: loc.state });
    },
    get location() {
      // be a bit defensive here in case of an unexpected breaking change in React Router
      return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        router?.state?.location ?? (navigator as any)?.location ?? location
      );
    },
  };

  return children(adapter);
};
