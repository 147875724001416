import colors from "src/colors";
import { themeConfig } from "src/theme/themeConfig";

export default `
// Heading styles
h1, h2, h3, { color: ${colors["navy-7"]} }

p {
  margin: 1em 0;
}

.jupyter-toc {
  margin: 1em 0;

  &::before {
    content: "Table of Contents";
    font-weight: bold;
    display: block;
    margin: 0.5em 0;
  }

  & li {
    list-style: none;
  }
}

// Jupyter cells
.jupyter-cell {
  padding: 1em;
  margin: 1em 0;
  font-size: 0.875em;

  & pre, & code {
    margin: unset !important;
    padding: unset !important;
    font-size: unset !important;
    background: unset !important;
    border: unset !important;
  }
}

// Jupyter input cells
.jupyter-input {
  background: #08273f; // calculated by redoc in openapi docs
  font-family: ${themeConfig.token.fontFamilyCode};

  & details > summary {
    cursor: pointer;
    list-style: none;
  }

  & details > summary::after {
    content: "(click to expand)";
  }

  & details[open] > summary::after {
    content: "(click to hide)";
  }
}

// Jupyter output cells
.jupyter-output {
  background: white;
  color: ${colors["navy-7"]};
  border: 2px solid ${colors["navy-7"]};
  overflow: auto;
  max-width: 100%;

  // Remove default syntax highlighting
  & pre, & code, & span.token {
    color: unset !important;
  }

  // Vertical scrolling
  &.jupyter-scrolled {
    max-height: 24rem;
  }

  // Image size
  & svg {
    max-width: 100%;
    height: unset;
  }

  & .dataframe {
    // Reset dataframe styling since redoc includes table styles
    &, & tr, & td, & th {
      margin-top: unset;
      background: unset;
      border: none;
    }

    // Style cells like a pandas dataframe in jupyter:
    // (1) everything right-aligned
    & th, & td {
      text-align: right;
    }
    // (2) line under the headers
    & thead {
      border-bottom: 1px solid ${colors["navy-2"]};
    }
    // (3) alternating dark and light rows
    & tbody tr:nth-child(2n) {
      background: ${colors["navy-2"]};
    }
  }
}
`;
