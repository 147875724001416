import { CSSProperties } from "react";

import redocTheme from "./theme";
import { methods } from "./useApiSpec";

type Method = (typeof methods)[number];

interface MethodTagProps {
  method: Method;
  className?: string;
}

const methodStyle: Record<Method, CSSProperties> = {
  delete: { background: redocTheme.colors.http.delete },
  get: { background: redocTheme.colors.http.get },
  head: { background: redocTheme.colors.http.head },
  patch: { background: redocTheme.colors.http.patch },
  post: { background: redocTheme.colors.http.post },
  put: { background: redocTheme.colors.http.put },
};

const MethodTag = ({ method, className }: MethodTagProps) => (
  <span
    className={`
      inline-block w-8 p-0.5 rounded
      text-[0.6rem] leading-none text-center
      uppercase text-white font-medium
      ${className ?? ""}
    `}
    style={methodStyle[method]}
  >
    {method === "delete" ? "del" : method}
  </span>
);

export default MethodTag;
