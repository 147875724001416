import { PlusOutlined } from "@ant-design/icons";
import { Alert, Table, TableColumnsType } from "antd";
import { Link } from "react-router-dom";

import api, { type ApiData } from "src/api";
import PageTitle from "src/components/PageTitle";

import Auth0RoleLink from "./Auth0RoleLink";

type CustomerListDatum = ApiData<"customer.list">[number];

const CustomerListBody = () => {
  const {
    data: customerList,
    isLoading,
    isError,
  } = api.customer.list.useQuery({});

  if (isError) return <Alert message="Error loading customers" />;

  const tableData = customerList?.data ?? [];

  const columns: TableColumnsType<CustomerListDatum> = [
    {
      title: "Name",
      dataIndex: [],
      key: "customer_name",
      filters: tableData.map(({ customer_name }) => ({
        text: customer_name,
        value: customer_name,
      })),
      filterSearch: true,
      onFilter: (value, record) => record.customer_name === value,
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      render: (d: CustomerListDatum) => (
        <Link to={d.customer_code}>{d.customer_name}</Link>
      ),
    },
    {
      title: "Code",
      dataIndex: "customer_code",
      key: "customer_code",
      filters: tableData.map(({ customer_code }) => ({
        text: customer_code,
        value: customer_code,
      })),
      filterSearch: true,
      onFilter: (value, record) => record.customer_code === value,
    },
    {
      title: "Auth0 Role",
      dataIndex: "role_id",
      render: (roleId) => <Auth0RoleLink roleId={roleId} />,
    },
  ];

  return (
    <Table
      rowKey="customer_code"
      data-test="customer-table"
      scroll={{ x: "min-content" }}
      loading={isLoading}
      columns={columns}
      pagination={{
        defaultPageSize: 10,
      }}
      dataSource={tableData}
    />
  );
};

const CustomerListPage = () => (
  <>
    <div className="flex justify-between items-center">
      <PageTitle title="Customers" />
      <Link to="create">
        <PlusOutlined /> New customer
      </Link>
    </div>
    <CustomerListBody />
  </>
);

export default CustomerListPage;
