import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { Modal, ModalFuncProps } from "antd";
import { useNavigate } from "react-router-dom";

const idTokenAgeSeconds = (token?: IdToken) => {
  if (!token?.iat) {
    return null;
  } else {
    return Math.trunc(Date.now() / 1000) - token.iat;
  }
};

interface ReauthNavigateProps {
  to: string;
  maxAge?: number;
  prompt?: ModalFuncProps;
}

/**
 * Returns a function to navigate after checking that the user has
 * authenticated within the has `maxAge` seconds. If the user has not,
 * redirects to the auth0 login page before navigating.
 *
 * @example
 * const reauthNavigate = useReauthNavigate();
 *
 * // simple navigate if reauth required
 * const onClick = () => {
 *   reauthNavigate({
 *     maxAge: 300,
 *     to: "/next",
 *   })
 * }
 *
 * // same, but first prompt the user to let them know what will happen
 * const onClickWithPrompt = () => {
 *   reauthNavigate({
 *     maxAge: 300,
 *     to: "/next",
 *     prompt: {
 *       title: "Reauthentication required",
 *       content: "You will be redirected to auth0 to log in again",
 *     }
 *   })
 * }
 */
const useReauthNavigate = () => {
  const { getIdTokenClaims, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  return async ({ to, prompt, maxAge = 300 }: ReauthNavigateProps) => {
    const token = await getIdTokenClaims();
    const age = idTokenAgeSeconds(token);
    // Force the user to re-auth if they haven't since maxAge
    if (age === null || age > maxAge) {
      const reauth = () =>
        loginWithRedirect({
          appState: { returnTo: to },
          max_age: 0, // force re-login
          login_hint: token?.email,
        });
      if (prompt) {
        // Prompt before the auth0 redirect
        Modal.info({
          ...prompt,
          onOk(evt) {
            prompt.onOk?.(evt);
            reauth();
          },
        });
      } else {
        // auth0 redirect immediately
        await reauth();
      }
    } else {
      // no reauth required: immediate navigate
      navigate(to);
    }
  };
};

export default useReauthNavigate;
