import { useState } from "react";

import {
  AriaChartProps,
  ChartThemeProvider,
  useOrdinalColorScale,
} from "src/bb-chart";
import { ChartLayout } from "src/components/ChartLayout";
import DateRangePickerWithPresets, {
  DateRange,
} from "src/components/DateRangePickerWithPresets";
import ChartLegend from "src/components/GraphElements/ChartLegend";
import ConcentrationSelector, {
  Concentration,
} from "src/components/GraphElements/ConcentrationSelector";
import RadioButtons from "src/components/GraphElements/RadioButtons";
import Label from "src/components/Label";
import useOptions from "src/hooks/useOptions";
import useTransformedData from "src/hooks/useTransformedData";
import useUniqueValues from "src/hooks/useUniqueValues";
import { compareLocationsChartTheme } from "src/theme/chartTheme";

import PCRSummaryChart from "./PCRSummaryChart";
import { PCRDatum } from "./types";
import { fmtUnits } from "./util";

interface PCRSummaryBodyProps extends AriaChartProps {
  rawData: PCRDatum[];
  targetNameOptions?: {
    label: string;
    value: "InfA" | "InfB" | "NoV_GI" | "NoV_GII" | "RSV";
  }[];
  setConcentrationUnit: (value: Concentration) => void;
  concentrationUnit: Concentration;
  typeSelectorText?: string;
}

const PCRSummaryBody = ({
  rawData,
  targetNameOptions,
  setConcentrationUnit,
  concentrationUnit,
  typeSelectorText = "Type Selection",
  ...ariaProps
}: PCRSummaryBodyProps) => {
  const [locationNames] = useUniqueValues(rawData, ["sampling_location_name"]);
  const colorScale = useOrdinalColorScale(locationNames);

  const [primerTarget, setPrimerTarget] = useState(
    targetNameOptions?.[0].value,
  );
  const [locations, setLocations] = useState(locationNames.slice(0, 10));
  const [dateRange, setDateRange] = useState<DateRange>();

  const data = useTransformedData(rawData, {
    filter: {
      sample_date: { between: dateRange },
      sampling_location_name: { in: locations },
      // when primerTarget is undefined (for covid or rsv) this filter does nothing
      primer_target: { eq: primerTarget },
    },
    units: {
      effective_concentration: { from: "1/L", to: concentrationUnit },
    },
  });

  const unitsText = concentrationUnit === "1/L" ? "copies/L" : "copies/mL";
  const legendOptions = useOptions(locationNames, { sort: true });
  return (
    <ChartThemeProvider value={compareLocationsChartTheme}>
      <ChartLayout>
        <ChartLayout.Body>
          <p className="text-navy-9 text-xs font-thin">
            Wastewater Effective Concentration ({unitsText})
          </p>
          <div className="aspect-[16/9]">
            <PCRSummaryChart
              data={data}
              dateRange={dateRange}
              colorScale={colorScale}
              locations={locations}
              unitsText={fmtUnits(concentrationUnit)}
              {...ariaProps}
            />
          </div>
        </ChartLayout.Body>

        <ChartLayout.Controls>
          <Label text="Concentration" />
          <ConcentrationSelector
            value={concentrationUnit}
            onChange={setConcentrationUnit}
          />
          {targetNameOptions && (
            <>
              <Label text={typeSelectorText} />
              <RadioButtons
                options={targetNameOptions}
                value={primerTarget}
                onChange={setPrimerTarget}
              />
            </>
          )}
          <Label text="Edit Duration" className="mt-2" />
          <DateRangePickerWithPresets
            value={dateRange}
            onChange={setDateRange}
          />
          <Label text="Select Locations" className="mt-2" />
          <ChartLegend
            options={legendOptions}
            value={locations}
            onChange={setLocations}
            colorScale={colorScale}
            flex
          />
        </ChartLayout.Controls>
      </ChartLayout>
    </ChartThemeProvider>
  );
};

export default PCRSummaryBody;
