import { Typography } from "antd";
import cx from "classnames";

const { Link } = Typography;

const AssayCard = ({
  className,
  disabled,
  title,
  singularTitle,
  image,
}: {
  className?: string;
  disabled: boolean;
  title: string;
  singularTitle?: string;
  image: string;
}) => (
  <div
    className={cx(
      "flex flex-col items-center bg-gradient-to-r from-biobotblue-4 to-biobotblue-8 w-[280px] h-[275px] rounded-3xl shadow-navy-5 shadow-lg",
      className,
      disabled && "grayscale",
    )}
  >
    <p className="text-3xl mt-3.5 text-h2 font-light text-navy-1">{title}</p>
    <img
      src={image}
      className={cx(
        "w-[207px] h-[207px]",
        disabled && "grayscale blur-[1px] opacity-40",
      )}
      alt={`3D ${title}`}
    />
    {disabled && (
      <div className="absolute text-center p-6 text-navy-1 mt-[70px] text-p1 leading-7 max-w-[280px]">
        To add {singularTitle ?? title} data, contact us at{" "}
        <Link
          className="text-p1 text-navy-1 underline"
          href={`mailto:support@biobot.io?subject=Unlock ${title} Data`}
          target="_blank"
        >
          support@biobot.io
        </Link>
      </div>
    )}
  </div>
);

export default AssayCard;
