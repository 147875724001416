import { GeoPath } from "d3-geo";

import useGlobalMemo from "../common/useGlobalMemo";
import { useMapData } from "./MapData";
import type { BBChartFeature, BBChartFeatureCollection } from "./types";

interface LazyFeature {
  id: BBChartFeature["id"];
  properties: BBChartFeature["properties"];
  d: string;
  centroid: [number, number];
  bounds: [[number, number], [number, number]];
}

const lazyFeature = (feature: BBChartFeature, path: GeoPath) => {
  const ret: Partial<LazyFeature> = {
    id: feature.id,
    properties: feature.properties,
    // Memoized getters, see
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#smart_self-overwriting_lazy_getters
    get d() {
      delete this.d;
      this.d = path(feature) ?? "";
      return this.d;
    },
    get centroid() {
      delete this.centroid;
      this.centroid = path.centroid(feature);
      return this.centroid;
    },
    get bounds() {
      delete this.bounds;
      this.bounds = path.bounds(feature);
      return this.bounds;
    },
  };
  return ret as LazyFeature;
};

/**
 * Returns an array of features with lazily-evaluated properties for rendering:
 *
 * - id -- feature id from the topojson file
 * - properties -- properties from the topojson file
 * - d -- svg path
 * - centroid -- centroid point of the feature
 * - bounds -- bounding box of the feature
 */
const useFeatures = (
  features_: BBChartFeatureCollection | undefined,
): LazyFeature[] => {
  const { defaultFeatures, path } = useMapData();
  const features = (features_ ?? defaultFeatures)?.features;
  return useGlobalMemo(
    () => (features ?? []).map((f) => lazyFeature(f, path)),
    [features, path],
  );
};

export default useFeatures;
