const funFacts = [
  "The ancient Roman Empire built one of the earliest known sewer systems, the Cloaca Maxima, around 600 BC. 🏛️ It still exists today, functioning as a storm drain beneath the city of Rome! 🇮🇹",
  'The world\'s first flushing toilet was invented by a wealthy Englishman named Sir John Harington in 1596. He installed the toilet in his own house and named it the "Ajax," — a pun on the term "a jakes," which was a popular slang term for toilets. 🚽',
  "The average person spends about three years of their life sitting on the toilet. 📅🚽",
  "The first modern sewage system in the United States was built in Chicago in 1885. It was a monumental engineering achievement and helped combat diseases like cholera and typhoid. 🦠",
  "The International Space Station (ISS) uses a specialized vacuum system to collect and dispose of human waste. 💩 The waste is then treated and filtered to produce clean water that can be reused on the station. 🚀🧑‍🚀",
  'In Tokyo, Japan, some public toilets are equipped with noise-making devices to mask embarrassing bathroom sounds. 🙉 🚽 These devices are known as "sound princesses" or "toilet sound generators."',
  'The city of London faced a serious wastewater problem in the mid - 19th century. The Thames River, which served as a disposal site for untreated sewage, became so polluted that it was known as the "Great Stink." This prompted the construction of the London sewer system. 💩',
  "Ancient civilizations, such as the Indus Valley Civilization in present-day Pakistan and India, had advanced sewage and drainage systems as early as 2500 BCE. These systems featured underground sewer networks and public toilets. 🚽",
];

export default funFacts;
