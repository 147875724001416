import { useAuth0 } from "@auth0/auth0-react";

import type { RoleName as RoleName_ } from "~api/types/auth";

export type RoleName = RoleName_;

/**
 * Checks that the Auth0 access token has a given role set.
 *
 * Note that typically you want to use {@link ./useUiPermissionCheck}, but
 * there are some situations where you need this information **before** you
 * have access to ui permissions. There are only two roles that you can check
 * with this function: "biobot-admin" and "biobot-feature-flagger".
 */
const useRoleCheck = (roleName?: RoleName) => {
  const { user } = useAuth0();
  return user?.["https://api.biobot.io/roles"]?.includes(roleName);
};

export default useRoleCheck;
