import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { QueryClient } from "react-query";

const isRetriableError = (error: unknown) => {
  if (process.env.NODE_ENV === "test") {
    return false;
  }
  if (error instanceof AxiosError) {
    const status = error.response?.status;
    if (status) {
      // Retry server errors, and any client errors that are retriable without
      // modification.
      return status >= 500 || status === 409 || status === 429;
    }
  }
  // react-query retries everything by default, so keep that behavior for
  // non-http errors.
  return true;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
      retry: (failureCount, error) => {
        if (failureCount < 3 && isRetriableError(error)) {
          return true;
        }
        Sentry.captureException(error);
        return false;
      },
      // this will be the default once v4 is released
      notifyOnChangeProps: "tracked",
    },
  },
});

export default queryClient;
