import { extent } from "d3-array";
import { useMemo } from "react";

/**
 * Calculates the extent for a dataset.
 *
 * @example
 * const [minDate, maxDate] = useExtent(data, "sample_date");
 */
const useExtent = <K extends keyof T & string, T>(data: T[], k: K) =>
  useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ret = extent(data, (d) => d[k] as any);
    if (ret[0] == null) {
      return undefined;
    } else {
      return ret as [NonNullable<T[K]>, NonNullable<T[K]>];
    }
  }, [data, k]);

export default useExtent;
