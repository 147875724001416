import { useCustomerOverride } from "../context/Customer";

/**
 * Returns the permission_override query param for all requests. This is
 * intended to be used only from Auth0 query and mutation hooks.
 */
const usePermissionOverride = (useOverride: boolean) => {
  const { customerCode } = useCustomerOverride();
  if (!customerCode || !useOverride) {
    return undefined;
  }
  return `customer:${customerCode}`;
};

export default usePermissionOverride;
