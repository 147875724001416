import { Graticule as VisxGraticule } from "@visx/geo";

import { ChartTheme, wrapTheme } from "../common/ChartTheme";
import { Text, TextProps } from "../common/Text";
import { Accessor, makeAccessor } from "../common/accessor";
import { isDarkColor } from "../common/util";
import { useMapData } from "./MapData";
import type { GeoTooltipDatum } from "./Tooltip";
import type { BBChartFeatureCollection } from "./types";
import useFeatures from "./useFeatures";

/* eslint-disable @typescript-eslint/no-explicit-any */

const wrapSeries = <T,>(
  themeKey: keyof NonNullable<ChartTheme["series"]>,
  Component: React.ComponentType<T>,
) => wrapTheme((x) => x?.series?.[themeKey], Component);

interface FeatureTextProps extends TextProps {
  centroid?: [number, number];
  pos?: [number, number];
  bounds?: [[number, number], [number, number]];
  paddingPct?: number;
}

/** Text placed inside or alongside a feature. */
export const FeatureText = ({
  centroid,
  bounds,
  pos,
  paddingPct = 0.25,
  ...props
}: FeatureTextProps) => {
  const newProps: TextProps = {};
  if (bounds) {
    const [[x1, y1], [x2, y2]] = bounds;
    const width = x2 - x1;
    const height = y2 - y1;
    const px = paddingPct * width;
    const py = paddingPct * height;
    newProps.x = x1 + px;
    newProps.y = y1 + py;
    newProps.width = width - 2 * px;
    newProps.height = height - 2 * py;
    newProps.scaleToFit = "shrink-only";
    if (newProps.width < 15 || newProps.height < 15) {
      newProps.display = "none";
    }
    // Manual placement
    if (pos) {
      const [x, y] = pos;
      newProps.x = x1 + x * width;
      newProps.y = y1 + y * height;
      newProps.textAnchor = "middle";
      newProps.verticalAnchor = "middle";
    }
  }
  if (centroid && !pos) {
    const [x, y] = centroid;
    newProps.x = x;
    newProps.y = y;
    newProps.textAnchor = "middle";
    newProps.verticalAnchor = "middle";
  }

  return <Text {...newProps} cursor="default" {...props} />;
};

interface FeatureSeriesProps
  extends Omit<React.SVGProps<SVGPathElement>, "path"> {
  seriesKey?: string;
  features?: BBChartFeatureCollection;
  label?: Accessor<any> | string;
  labelClassName?: string;
  colorScale?: (value: string) => string;
}

/**
 * Renders features with a given geo series. This is intended to be used for
 * choropleths, so fill comes from passing the `value` of each data point in
 * the series to `colorScale`.
 */
export const FeatureSeries = wrapSeries(
  "feature",
  ({
    seriesKey = "series1",
    features: features_,
    colorScale,
    label,
    labelClassName,
    ...svgProps
  }: FeatureSeriesProps) => {
    const { series: allSeries } = useMapData();
    const series = allSeries[seriesKey];
    const labelAccessor = label ? makeAccessor(label) : null;
    const features = useFeatures(features_);
    return (
      <g>
        {features.map((feature) => {
          const { labelPos, labelOutside, ...properties } = feature.properties;
          const labelText = labelAccessor?.(feature.properties);
          const seriesValue = series.data[feature.id ?? ""]?.value;
          const fill = colorScale?.(seriesValue);
          const ttData: Omit<GeoTooltipDatum, "value" | "datum" | "label"> = {
            feature: { id: feature.id, properties },
            seriesKey,
          };
          return (
            <g
              key={feature.id}
              className={`group/feature ${labelOutside ? "group/label-outside" : "group/label"}`}
              data-bb-chart-tooltip={JSON.stringify(ttData)}
            >
              <path d={feature.d} fill={fill} {...svgProps} />
              {labelText && (
                <FeatureText
                  centroid={feature.centroid}
                  pos={labelPos}
                  bounds={feature.bounds}
                  className={`${fill && !labelOutside && isDarkColor(fill) ? "bg-dark" : "bg-light"} ${labelClassName ?? ""}`}
                >
                  {labelText}
                </FeatureText>
              )}
            </g>
          );
        })}
      </g>
    );
  },
);

interface FeatureAnnotationProps
  extends Omit<React.SVGProps<SVGPathElement>, "path"> {
  features?: BBChartFeatureCollection;
}

/**
 * Renders a collection of features as an annotation (i.e. static shapes
 * without series data or tooltip information).
 */
export const FeatureAnnotation = wrapSeries(
  "annotation",
  ({ features: features_, ...svgProps }: FeatureAnnotationProps) => {
    const features = useFeatures(features_);
    return (
      <g>
        {features.map(({ id, d }) => (
          <path key={id} d={d} pointerEvents="none" {...svgProps} />
        ))}
      </g>
    );
  },
);

type VisxGraticuleProps = React.ComponentProps<typeof VisxGraticule>;

export const Graticule = wrapSeries(
  "graticule",
  (props: VisxGraticuleProps) => {
    const { path } = useMapData();
    return <VisxGraticule graticule={(g) => path(g) || ""} {...props} />;
  },
);
