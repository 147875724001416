import type { Compound } from "./util";

const oxfordJoin = (items: readonly string[], conjunction = "and") => {
  if (items.length === 2) {
    return `${items[0]} ${conjunction} ${items[1]}`;
  } else if (items.length > 2) {
    return `${items.slice(0, -1).join(", ")}, ${conjunction} ${
      items.slice(-1)[0]
    }`;
  } else {
    return items[0];
  }
};

const compoundLabels = (compounds: readonly Compound[]) =>
  compounds.map((x) => x.label);

const compoundLabelsLower = (compounds: readonly Compound[]) =>
  compounds.map((x) => x.lowerLabel ?? x.label.toLowerCase());

export const parentName = (compounds: readonly Compound[]) =>
  compounds[0].label;

export const summaryCardTitle = (compounds: readonly Compound[]) => {
  const compoundNames = oxfordJoin(compoundLabels(compounds), "or");
  return `Compare ${compoundNames} across Locations`;
};

export const summaryCardSubtitle = (compounds: readonly Compound[]) => {
  const [parent, ...metabolites] = compoundLabelsLower(compounds);
  if (metabolites.length === 0) {
    return `Examine trends in ${parent} over time and across locations.`;
  } else if (metabolites.length === 1) {
    return `Select multiple locations and toggle between ${parent} (the parent drug) or ${metabolites[0]} (the metabolite) to examine trends over time and across locations.`;
  }
  return `Select multiple locations and toggle between ${oxfordJoin(
    [`${parent} (the parent drug)`, ...metabolites],
    "or",
  )} (the metabolites) to examine trends over time and across locations.`;
};

export const locationsCardTitle = (compounds: readonly Compound[]) => {
  const compoundNames = oxfordJoin(compoundLabels(compounds), "and");
  return `Compare ${compoundNames} within Individual Locations`;
};

export const locationsCardSubtitle = (compounds: readonly Compound[]) => {
  const compoundNames = oxfordJoin(compoundLabelsLower(compounds), "and");
  return `Monitor and compare trends in ${compoundNames} within locations. Toggle axis standardization for ease of comparison across locations.`;
};
