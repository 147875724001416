import { Modal, Select, SelectProps } from "antd";
import { castArray, partition, uniq } from "lodash";

/* eslint-disable @typescript-eslint/no-explicit-any */

type OptionType<ValueType = any> =
  | { label: string; value: ValueType }
  | { label: string; options: { label: string; value: ValueType }[] };

export interface BigSelectProps<ValueType = any>
  extends Omit<
    SelectProps<ValueType, OptionType<ValueType>>,
    "options" | "value"
  > {
  options: { label: string; value: ValueType }[];
  value?: ValueType | ValueType[] | null;
  /** Allow the user to paste multiple values */
  pasteMany?: boolean;
}

const groupOptions = <V, O extends { label: string; value: V }>(
  options: O[],
  value: V | V[] | null | undefined,
) => {
  if (!Array.isArray(value) || value.length === 0) {
    return options;
  } else {
    const [selected, unselected] = partition(options, (opt) =>
      value.includes(opt.value),
    );
    return [
      { label: "selected", options: selected },
      { label: "available", options: unselected },
    ];
  }
};

const handlePaste = <ValueType,>(
  text: string,
  {
    options,
    value,
    onChange,
  }: Pick<BigSelectProps<ValueType>, "options" | "value" | "onChange">,
) => {
  const searchVals = text.split(/\s+/);
  // Look for pasted values in the options array
  const optionsMap = Object.fromEntries(options.map((o) => [`${o.value}`, o]));
  const searchResults = searchVals.map((val) => ({
    search: val,
    option: optionsMap[val],
  }));
  if (searchResults.every((r) => !r.option)) {
    return false;
  }
  // Show a modal with search results and ask the user to confirm
  const classes = "border border-solid border-navy-3 p-1";
  Modal.confirm({
    title: "Pasting multiple values",
    content: (
      <div className="overflow-auto max-h-48">
        <table className={`${classes} border-collapse`}>
          <tbody>
            {searchResults.map((r, idx) => (
              <tr key={idx} className={r.option ? "bg-lime-2" : "bg-coral-3"}>
                <td className={classes}>{r.option ? "✅" : "❌"}</td>
                <td className={classes}>{r.search}</td>
                <td className={classes}>{r.option?.label ?? "(not found)"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
    onOk() {
      const oldValues = value == null ? [] : castArray(value);
      const newValues = uniq([
        ...oldValues,
        ...searchResults.filter((r) => r.option).map((r) => r.option.value),
      ]);
      const newOptions = options.filter((o) => newValues.includes(o.value));
      onChange?.(newValues as ValueType, newOptions);
    },
  });
  return true;
};

/**
 * An antd Select customized for multiselect with large lists.
 *
 * Notable additions:
 * - Defaults to multiselect with a search allowed and a dropdown arrow
 * - Options are grouped with already selected options on top, and remaining options below.
 * - With `pasteMany`, you are allowed to paste multiple values at once, which
 *   will be checked against the option list.
 */
const BigSelect = <ValueType = any,>({
  value,
  options,
  pasteMany,
  onChange,
  ...props
}: BigSelectProps<ValueType>) => (
  <Select<ValueType, OptionType<ValueType>>
    value={value as ValueType}
    options={groupOptions(options, value)}
    onChange={onChange}
    mode="multiple"
    optionFilterProp="label"
    onPaste={
      pasteMany
        ? (e) => {
            const text = e.clipboardData?.getData("text");
            if (text) {
              if (handlePaste(text, { options, value, onChange })) {
                e.preventDefault();
              }
            }
          }
        : undefined
    }
    showSearch
    showArrow
    {...props}
  />
);

export default BigSelect;
