import { AreaClosed, Circle, LinePath } from "@visx/shape";
import { min } from "d3-array";
import type { CurveFactory } from "d3-shape";
import { useEffect, useState } from "react";

import { ChartTheme, useChartTheme, wrapTheme } from "../common/ChartTheme";
import { useSvgSize } from "../common/SvgSize";
import { useTooltip } from "../common/Tooltip";
import type { GapFn } from "../types";
import { useXYData } from "./XYData";
import { barPath, seriesDataWithGaps } from "./shapeUtil";

// -- Helper shapes --

type HVLineBase = Omit<React.SVGProps<SVGLineElement>, "x" | "y">;

export interface VerticalLineProps extends HVLineBase {
  x: number;
}

export interface HorizontalLineProps extends HVLineBase {
  y: number;
}

/** A vertical line running through the x coordinate. */
export const VerticalLine = ({ x, ...props }: VerticalLineProps) => {
  const { height } = useSvgSize();
  return <line x1={x} x2={x} y1={0} y2={height} {...props} />;
};

/** A horizontal line running through the y coordinate. */
export const HorizontalLine = ({ y, ...props }: HorizontalLineProps) => {
  const { width } = useSvgSize();
  return <line x1={0} x2={width} y1={y} y2={y} {...props} />;
};

// -- Hover shapes --

export interface HoverDefProps {
  children: React.ReactNode;
  id: `series-${string}` | "horizontal" | "vertical" | "custom";
}

/** Wraps a shape in an svg `<defs>` element that will only be shown on over. */
const HoverDef = ({ children, id }: HoverDefProps) => {
  const { idPrefix } = useSvgSize();
  return (
    <defs>
      <g id={`${idPrefix}-hover-${id}`}>{children}</g>
    </defs>
  );
};

interface HoverShapeProps {
  color?: string;
  className?: string;
}

/** A point show on hover. */
export const HoverPoint = ({ color, ...props }: HoverShapeProps) => {
  const theme = useChartTheme();
  return <Circle r={5} fill={color} {...theme.hover?.point} {...props} />;
};

/** A horizontal line shown at the cursor position on hover. */
export const HoverHorizontalLine = () => {
  const theme = useChartTheme();
  return (
    <HoverDef id="horizontal">
      <HorizontalLine y={0} {...theme.hover?.line} />
    </HoverDef>
  );
};

/** A vertical line shown at the cursor position on hover. */
export const HoverVerticalLine = () => {
  const theme = useChartTheme();
  return (
    <HoverDef id="vertical">
      <VerticalLine x={0} {...theme.hover?.line} />
    </HoverDef>
  );
};

/**
 * Wrapper for any custom element to be shown on hover. Note that your chart
 * may have at most one of these since it uses a static id.
 */
export const HoverCustom = ({ children }: { children: React.ReactNode }) => (
  <HoverDef id="custom">{children}</HoverDef>
);

// -- Series helpers --

interface SeriesProps {
  seriesKey: string;
  gap?: GapFn | number;
  color?: string;
  className?: string;
  hoverComponent?: (props: HoverShapeProps) => JSX.Element | null;
  inBackground?: boolean;
  showDataPoints?: boolean;
}

/** Common props for series components. */
export const useSeriesProps = <T extends SeriesProps>({
  seriesKey,
  color,
  hoverComponent,
  inBackground,
  showDataPoints,
  gap,
  ...props
}: T) => {
  const { xScales, yScales, series: allSeries } = useXYData();
  const series = allSeries[seriesKey];
  if (!series) {
    throw new Error(
      `useSeriesProps called with an invalid seriesKey: ${seriesKey}`,
    );
  }
  const xScale = xScales[series.xScaleIdx];
  const yScale = yScales[series.yScaleIdx];
  const xDomain = xScale.domain();
  type Datum = (typeof series.data)[number];
  return {
    data: seriesDataWithGaps(series, gap) as Datum[],
    x: (d: Datum) => xScale(series.x(d)),
    y: (d: Datum) => yScale(series.y(d)),
    y0: (d: Datum) => Math.min(yScale(series.y0(d)), yScale.range()[0]),
    defined: (d: Datum) => d != null && series.definedInXDomain(d, xDomain),
    color: color ?? series.color,
    series,
    xScale,
    yScale,
    seriesKey,
    HoverComponent: inBackground ? null : hoverComponent,
    passthrough: props,
    showDataPoints: showDataPoints ?? false,
  };
};

/**
 * Detects when a series is in the "background", i.e. when hovering over the
 * chart, but this series is not the nearest series.
 */
const SeriesDetector = ({
  seriesKey,
  onChange,
}: {
  seriesKey: string;
  onChange: (arg: boolean) => void;
}) => {
  const tt = useTooltip();
  const isInBackground = tt.isOpen && tt.data?.nearest.seriesKey !== seriesKey;
  useEffect(() => {
    onChange(isInBackground);
  }, [onChange, isInBackground]);
  return null;
};

/**
 * Adds the `unfocusedClassName` prop to a Series component. This should be
 * applied to all our shapes.
 *
 * When this `unfocusedClassName` is not null:
 *
 * - adds unfocusedClassName to the series component
 * - hides the hoverComponent
 */
const wrapHover =
  <T extends SeriesProps>(Component: React.ComponentType<T>) =>
  ({ unfocusedClassName, ...props }: T & { unfocusedClassName?: string }) => {
    const { seriesKey } = props;
    const [inBackground, setInBackground] = useState(false);
    if (unfocusedClassName) {
      return (
        <>
          <SeriesDetector seriesKey={seriesKey} onChange={setInBackground} />
          <g className={inBackground ? unfocusedClassName : undefined}>
            <Component {...(props as T)} inBackground={inBackground} />
          </g>
        </>
      );
    } else {
      return <Component {...(props as T)} inBackground={inBackground} />;
    }
  };

const wrapSeries = <T extends SeriesProps>(
  themeKey: keyof NonNullable<ChartTheme["series"]>,
  Component: React.ComponentType<T>,
) => wrapTheme((x) => x?.series?.[themeKey], wrapHover(Component));

// -- Series shapes --

export interface LineSeriesProps extends SeriesProps {
  curve?: CurveFactory;
}

/** A line series, made of an svg path. */
export const LineSeries = wrapSeries("line", (props: LineSeriesProps) => {
  const {
    color,
    seriesKey,
    passthrough,
    data,
    x,
    y,
    defined,
    HoverComponent,
    showDataPoints,
  } = useSeriesProps({ hoverComponent: HoverPoint, ...props });
  return (
    <>
      <LinePath {...passthrough} {...{ data, x, y, defined }} stroke={color} />

      {showDataPoints &&
        data.map(
          (d, i) =>
            defined(d) && (
              <circle
                key={`point-${i}`}
                r={2}
                cx={x(d)}
                cy={y(d)}
                stroke={color}
                fill={color}
              />
            ),
        )}

      {HoverComponent && (
        <HoverDef id={`series-${seriesKey}`}>
          <HoverComponent color={color} />
        </HoverDef>
      )}
    </>
  );
});

interface AreaSeriesProps extends SeriesProps {
  curve?: CurveFactory;
}

/** An area series, made of a closed svg path. */
export const AreaSeries = wrapSeries("area", (props: AreaSeriesProps) => {
  const {
    color,
    seriesKey,
    passthrough,
    data,
    x,
    y,
    y0,
    defined,
    yScale,
    HoverComponent,
  } = useSeriesProps({ hoverComponent: HoverPoint, ...props });
  return (
    <>
      <AreaClosed
        {...passthrough}
        {...{ data, x, y1: y, y0, defined, yScale }}
        stroke={color}
        fill={color}
      />
      {HoverComponent && (
        <HoverDef id={`series-${seriesKey}`}>
          <HoverComponent color={color} />
        </HoverDef>
      )}
    </>
  );
});

interface BarSeriesProps extends SeriesProps {
  width?: number;
  paddingPct?: number;
}

export const BarSeries = wrapSeries(
  "bar",
  ({ width, paddingPct = 25, ...props }: BarSeriesProps) => {
    const {
      color,
      seriesKey,
      passthrough,
      data,
      x,
      y,
      y0,
      defined,
      xScale,
      HoverComponent,
    } = useSeriesProps({ hoverComponent: HoverPoint, ...props });
    // We can plot bars with band scales or continuous scales, but we need to
    // make some tweaks depending on the type of scale.
    let barWidth = width;
    let xAccessor = x;
    if ("bandwidth" in xScale) {
      // Band scales include the band with as part of the definition, but they
      // return x as the left edge, so we need to shift half a bar to the right
      // to get the center of the bar.
      barWidth ??= xScale.bandwidth();
      xAccessor = (d) => x(d) + barWidth! / 2;
    } else if (barWidth == null) {
      // This is a continuous scale, so we need to calculate an appropriate bar
      // width based on how close the points are (the x accessor already returns
      // the middle of the bar, so no change needed).
      const minPointSpacing = min(data, (d, idx, arr) =>
        idx > 0 ? Math.abs(x(d) - x(arr[idx - 1])) : undefined,
      ) as unknown as number;
      barWidth = (100 - paddingPct) * 0.01 * minPointSpacing;
    }
    const d = barPath({ x: xAccessor, y, y0, defined, width: barWidth })(data);
    return (
      <>
        <path {...passthrough} d={d} fill={color} />
        {HoverComponent && (
          <HoverDef id={`series-${seriesKey}`}>
            <HoverComponent color={color} />
          </HoverDef>
        )}
      </>
    );
  },
);
