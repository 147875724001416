import cx from "classnames";

export interface LabelProps {
  text: React.ReactNode;
  className?: string;
}

const Label = ({ text, className }: LabelProps) => (
  <div className={cx("overridable:text-h2", className)}>{text}</div>
);

export default Label;
