import { utcFormat } from "d3-time-format";

import {
  Chart,
  HoverVerticalLine,
  LineSeries,
  TooltipComponent,
  XAxis,
  YAxis,
  useSeries,
} from "src/bb-chart";
import type { DateRange } from "src/components/DateRangePickerWithPresets";
import { numberFormatter } from "src/components/GraphElements/formatters";

import type { PCRDatum } from "../types";
import { getSampleDate, xTickFormat } from "../util";

export const fmtDate = utcFormat("%A, %B %e");

interface CovidLocationChartProps {
  data: PCRDatum[];
  dateRange?: DateRange;
  concentrationRange?: [number, number];
  isExpanded?: boolean;
  unitsText: string;
  empty?: React.ReactNode;
}

const CovidLocationChart = ({
  data,
  dateRange,
  concentrationRange,
  isExpanded,
  unitsText,
  empty,
}: CovidLocationChartProps) => {
  const series = useSeries({
    seriesKey: "covid",
    data,
    x: getSampleDate,
    y: "effective_concentration",
  });

  const tooltip: TooltipComponent<typeof series> = ({ nearest }) => (
    <div className="uppercase">
      <div>{fmtDate(nearest.x)}</div>
      {nearest.y === 0 ? (
        <div>
          <span className="font-bold">Below the limit of detection</span>
        </div>
      ) : (
        <div>
          <span className="font-bold">{numberFormatter(nearest.y)}</span>{" "}
          {unitsText}
        </div>
      )}
    </div>
  );

  const showDataPoints = isExpanded ? data.length <= 150 : data.length <= 50;

  return (
    <Chart
      series={[series]}
      xScales={[{ type: "time", domain: dateRange }]}
      yScales={[
        { type: "linear", zero: true, nice: 5, domain: concentrationRange },
      ]}
      tooltip={tooltip}
      empty={empty}
      aria-label="SARS-CoV-2 Wastewater Concentration Graph"
      aria-description="Sample location graph illustrating wastewater concentration of SARS-CoV-2 over time"
    >
      <XAxis tickFormat={xTickFormat} />
      <YAxis grid numTicks={5} tickFormat={numberFormatter} zeroTick="LOD" />
      <LineSeries seriesKey="covid" showDataPoints={showDataPoints} />
      <HoverVerticalLine />
    </Chart>
  );
};

export default CovidLocationChart;
