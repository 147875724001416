import axios, { AxiosError } from "axios";
import { useMutation } from "react-query";

/**
 * Programmatically downloads a file given an href.
 *
 * This is the same approach taken by js-file-download, file-saver, etc.
 */
const downloadUrl = (href: string) => {
  const a = document.createElement("a");
  a.href = href;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 * Is this export request retriable?
 *
 * API Gateway returns 503 for lambda timeouts, which would happen if the
 * initial request has not completed in 30 seconds. Our backend responds 202
 * for export requests while another one is in progress. So for a long-running
 * export we might get a sequence like:
 *
 * - 503 (retry, export took longer than 30 seconds)
 * - 202 (retry, still in progress)
 * - 202 (retry, still in progress)
 * - 200 (done)
 */
const isRetriable = (error: unknown) => {
  if (!(error instanceof AxiosError)) {
    return false;
  }
  const status = error.response?.status;
  return status === 503 || status === 202;
};

/**
 * Hook used to download exports. Since exports can take a long time to
 * complete we want to show progress (and prevent the user from triggering
 * downloads twice).
 *
 * This way this works is:
 *
 * 1. This hook is used to send a request to the backend to initiate an export
 *    (when a user clicks the download button)
 * 2. The backend kicks off an export, saving the file in s3, then responds
 *    with a presigned link to the s3 file in the response body.
 * 3. We then download the presigned s3 link.
 *
 * The idea is that while step 2 is running the frontend knows that we are
 * waiting for the export to complete, so it can show a spinner, disable the
 * download button, etc. Previously in step 2 the backend returned a 307 so the
 * original link could be used in an <a> element directly, but there's no way
 * to show progress with that approach.
 */
const useExportDownload = () => {
  const { mutateAsync, ...rest } = useMutation(
    async (url: string) => {
      const { data } = await axios({
        url,
        params: { redirect: false },
        // The backend will return 200 once the export is complete. We'll retry
        // other statuses.
        validateStatus: (status) => status === 200,
      });
      downloadUrl(data);
    },
    {
      retry(failureCount, error) {
        // APIGateway has a timeout of 30 seconds, so this could make the user
        // wait up to 90 seconds. As of 2024-04-11 our largest dataset (county
        // interpolated) typically takes about 45 seconds to export if it is
        // not cached.
        return failureCount < 3 && isRetriable(error);
      },
    },
  );
  return { download: mutateAsync, ...rest };
};

export default useExportDownload;
