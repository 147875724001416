import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

export const compounds = [
  { label: "Methadone", value: "Methadone" },
  { label: "EDDP", value: "EDDP", lowerLabel: "EDDP" },
];

const aboutTheData = (
  <>
    <p>
      Methadone is a synthetic opioid used for chronic pain management and as a
      medication for opioid use disorder. It is administered orally or via
      injection. Methadone and its major metabolite, EDDP, are excreted in urine
      and are stable in wastewater.
    </p>
    <p>
      Doses vary widely, typically starting at 20 mg per day for opioid use
      disorder and adjusted based on individual response and treatment goals.
      Recreational doses, however, may exceed 30 mg.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const MethadonePage = () => {
  const { data, isLoading } = api.data.methadoneWastewater.useQuery({
    fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default MethadonePage;
