import { kitDatasets } from "@repo/data/datasets";

import FAQs from "./FAQs";
import Watchlist from "./Watchlist";

export const testingDatasets = kitDatasets;

const TestingHomepage = () => (
  <div className="mb-8">
    <Watchlist />
    <FAQs className="mt-6" />
  </div>
);
export default TestingHomepage;
