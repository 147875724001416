import BiobotCard from "src/components/BiobotCard";
import BiobotCollapse, { Panel } from "src/components/BiobotCollapse";

import { FAQFixtures } from "./FAQFixtures";

const FAQs = ({ className }: { className?: string }) => (
  <div className={className} data-test="faqs-block">
    <BiobotCard title="FAQs">
      <BiobotCollapse className="font-black text-h1">
        {Array.isArray(FAQFixtures) &&
          FAQFixtures.map((faq, idx) => (
            <Panel
              header={faq.header}
              key={idx}
              className="bg-navy-1 p-4 mb-3.5 rounded"
              data-test="faq-question"
            >
              <div className="text-p2 font-light mt-1 mb-1">
                {faq.description}
              </div>
            </Panel>
          ))}
      </BiobotCollapse>
    </BiobotCard>
  </div>
);
export default FAQs;
