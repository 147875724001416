const communityUrl = "https://biobot.io/covid19-report-notes";
const buildingUrl =
  "https://biobot.io/interpreting-building-level-covid19-wastewater-monitoring-data";

interface CovidLinksProps {
  locationTypes: ("community" | "facility" | null)[];
  className?: string;
}

const CovidLinks = ({ locationTypes, className }: CovidLinksProps) => {
  const locationTypesText = [
    locationTypes.includes("community") && "community",
    locationTypes.includes("facility") && "building",
  ]
    .filter(Boolean)
    .join(" & ");

  const urls = [
    locationTypes.includes("community") && communityUrl,
    locationTypes.includes("facility") && buildingUrl,
  ].filter(Boolean);

  return (
    <div className={className}>
      <p className="text-p2">
        Learn more about Biobot&apos;s {locationTypesText} level insights,
        protocols, and methodology by visiting
      </p>
      <ul className="m-0 pl-5">
        {urls.map((url) => (
          <li key={url}>
            <a href={url} target="_blank" rel="noreferrer">
              {url}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CovidLinks;
