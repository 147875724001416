import { isEmpty, pickBy } from "lodash";

import AssayCard from "./AssayCard";
import { ExportButton } from "./Buttons";
import type { Group, Links } from "./types";

interface DownloadCardProps {
  className?: string;
  group: Group;
  singularTitle?: string;
  image: string;
  // eslint-disable-next-line react/no-unused-prop-types
  links?: Links;
  // eslint-disable-next-line react/no-unused-prop-types
  loading?: boolean;
}

export const useLinkState = ({
  links: links_,
  loading,
  group,
}: DownloadCardProps) => {
  const links = pickBy(
    links_,
    (link) => link?.href && link.group === group,
  ) as { [K in keyof Links]: NonNullable<Links[K]> };
  if (loading) {
    return { state: "loading", links: null } as const;
  } else if (!isEmpty(links)) {
    return { state: "data", links } as const;
  } else {
    return { state: "no-data", links: null } as const;
  }
};

const DownloadCard = (props: DownloadCardProps) => {
  const { state, links } = useLinkState(props);
  const { className, group, singularTitle, image } = props;
  return (
    <div className={className}>
      <AssayCard
        disabled={state === "no-data" || state === "loading"}
        title={group}
        image={image}
        singularTitle={singularTitle}
        className="mb-5"
      />
      {state === "loading" && <ExportButton label="loading..." loading />}
      {state === "data" && (
        <>
          {Object.entries(links).map(([k, info]) => (
            <ExportButton
              key={k}
              label={info.label}
              href={info.href}
              gaAction={`${k}-downloaded`}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default DownloadCard;
