import Link from "antd/es/typography/Link";

const textStyle = "text-p2";
const SupportEmail = (
  <Link
    className={textStyle}
    href="mailto:support@biobot.io?subject=Dashboard FAQ Inquiry"
    target="_blank"
  >
    support@biobot.io
  </Link>
);

export const FAQFixtures = [
  {
    header: "How can I share these results with others?",
    description: (
      <>
        <p className="mb-4">
          Your data may be downloaded as a .csv or NWSS+ file by selecting the
          circular yellow button next to each file type within the Download Hub.
          You may also share screenshots (Command+Shift+3 on Mac and Windows key
          + Print Screen on PC) of the time series with affected community
          groups, local stakeholders, and public health officials as desired
        </p>
        <p>
          If you do not currently receive Biobot’s Variant Sequencing product,
          but are interested in receiving ongoing variant detection reports
          specific to your location, please contact us at {SupportEmail}.
        </p>
      </>
    ),
  },
  {
    header: "Why is my time series updated but the NWSS file is not?",
    description: (
      <>
        While our dashboard time series is updated on a rolling basis as new
        data becomes available, NWSS file updates occur based on a weekly
        schedule.
      </>
    ),
  },
  {
    header:
      "I am not seeing the most recent sample represented within the data. Why may that be?",
    description: (
      <>
        <p className="mb-4">
          If your most recent sample is not represented within the data it is
          possible that the sample is undergoing a re-run in order to confirm
          the results that were returned. It is also possible that the sample
          collection details were not submitted via the sample log, or were
          submitted late, which would prevent or delay us in associating the
          sample with the correct date and location of collection.
        </p>
        <p>
          Please feel free to reach out to us at {SupportEmail} with the sample
          kit ID and we would be happy to investigate why a given sample is not
          appearing within your time series.
        </p>
      </>
    ),
  },
];
