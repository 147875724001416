import { PlusCircleOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

export const { Panel } = Collapse;

const BiobotCollapse = ({
  children,
  className,
  defaultActiveKey = [], // default all panels collapsed
  activeKey,
}: {
  children: React.ReactNode;
  className?: string;
  defaultActiveKey?: number[];
  activeKey?: number[];
}) => (
  <Collapse
    expandIconPosition="end"
    ghost
    bordered
    expandIcon={({ isActive }) => (
      <PlusCircleOutlined
        className="text-2xl"
        frameBorder="0"
        rotate={isActive ? 45 : 0}
      />
    )}
    size="large"
    className={`biobot-collapse ${className ?? ""}`}
    defaultActiveKey={defaultActiveKey}
    {...(activeKey && { activeKey })} // component becomes controlled when activeKey is passed
  >
    {children}
  </Collapse>
);

BiobotCollapse.Panel = Collapse.Panel;

export default BiobotCollapse;
