import { DatasetName } from "@repo/data/datasets";

import { useCustomer } from "src/context/Customer";
import { type FeatureFlags, useFeatureFlags } from "src/context/FeatureFlag";

import DaasHomepage, { daasDatasets } from "./daas/DaasHomepage";
import TestingHomepage, { testingDatasets } from "./testing/TestingHomepage";

interface Page {
  datasets: readonly DatasetName[];
  Component: React.ComponentType;
  flag?: keyof FeatureFlags;
}

const pages: Page[] = [
  { datasets: testingDatasets, Component: TestingHomepage },
  {
    datasets: daasDatasets,
    Component: DaasHomepage,
  },
];

const Homepage = () => {
  const customer = useCustomer();
  const featureFlags = useFeatureFlags();
  const page = pages.find(({ datasets, flag }) => {
    if (flag && !featureFlags[flag]) {
      return false;
    }
    return datasets.some((d) => customer.datasets?.includes(d));
  });
  if (page) {
    const { Component } = page;
    return <Component />;
  } else {
    return <TestingHomepage />;
  }
};

export default Homepage;
