import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

const compounds = [
  { label: "Tramadol", value: "Tramadol" },
  {
    label: "N-Desmethyltramadol",
    value: "N-Desmethyltramadol",
    lowerLabel: "N-desmethyltramadol",
  },
  {
    label: "O-Desmethyltramadol",
    value: "O-Desmethyltramadol",
    lowerLabel: "O-desmethyltramadol",
  },
];

const aboutTheData = (
  <>
    <p>
      Tramadol is a synthetic opioid analgesic used to treat moderate to severe
      pain. It is available in immediate-release and extended-release
      formulations and is typically administered orally, though can be
      administered by injection. Life-threatening overdoses are possible, and
      prolonged use can result in physical dependence. Tramadol and its
      metabolites N-desmethyltramadol and O-desmethyltramadol are excreted in
      urine and stable in wastewater.
    </p>
    <p>
      Medical doses of tramadol range from 25 mg to 100 mg per administration,
      with a maximum daily dose of 400 mg.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const TramadolPage = () => {
  const { data, isLoading } = api.data.tramadolWastewater.useQuery({
    fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default TramadolPage;
