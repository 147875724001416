import { pcrTargetByDataset } from "@repo/data/datasets";
import { Form, FormItemProps, FormRule } from "antd";
import cx from "classnames";
import dayjs from "dayjs";
import { omit, startCase } from "lodash";

import type { IsoDate } from "~api/types/util";

import BigSelect, { type BigSelectProps } from "src/components/BigSelect";
import DateStringPicker from "src/components/DateStringPicker";
import TooltipHelp from "src/components/TooltipHelp";

import type { FieldInfo } from "./types";

type NamePath = NonNullable<FormItemProps["name"]>;
type Rules = NonNullable<FormItemProps["rules"]>;

interface FilterItemProps extends FormItemProps {
  namePrefix?: (string | number)[];
  help?: string;
  label?: React.ReactNode;
  labelClassName?: string;
}

const concatRules = (a?: Rules, b?: Rules) =>
  a && b ? [...a, ...b] : (a ?? b);

const defaultLabel = (name?: NamePath): string | null => {
  if (Array.isArray(name)) {
    return defaultLabel(name[name.length - 1]);
  } else if (typeof name === "string") {
    return startCase(name);
  } else {
    return null;
  }
};

const Field = ({
  label: label_,
  required,
  help,
  labelClassName = "leading-8",
  children,
  rules,
  name,
  namePrefix,
  ...props
}: FilterItemProps) => {
  const label = label_ ?? defaultLabel(name);
  return (
    <>
      {label && (
        <p className={labelClassName}>
          {required && <span className="text-coral-5">* </span>}
          {label}
          {help && (
            <span>
              {" "}
              <TooltipHelp title={help} />
            </span>
          )}
        </p>
      )}
      <Form.Item
        name={[namePrefix, name].flat().filter((x) => x != null) as NamePath}
        rules={concatRules([{ required }], rules)}
        {...props}
      >
        {children}
      </Form.Item>
    </>
  );
};

const StandardSelect = ({
  multiple,
  ...props
}: BigSelectProps & { multiple?: boolean }) => (
  <BigSelect
    allowClear
    maxTagCount="responsive"
    className="w-0 min-w-full"
    mode={multiple ? "multiple" : undefined}
    {...props}
  />
);

interface StandardItemProps
  extends Omit<FilterItemProps, "required" | "name">,
    Omit<FieldInfo, "label"> {}

const SelectField = ({ options, multiple, ...props }: StandardItemProps) => (
  <Field {...props}>
    <StandardSelect options={options} multiple={multiple} />
  </Field>
);

const DatasetName = ({ options, ...props }: StandardItemProps) => (
  <SelectField
    label="Datasets"
    options={options.filter((x) => x.value !== "*kit")}
    {...props}
  />
);

const targetNameRule =
  (name: (string | number)[]): FormRule =>
  ({ getFieldValue }) => ({
    validator: () => {
      const { dataset_name, target_name } = getFieldValue([
        "data_filter",
        ...name,
      ]) as { dataset_name: string[]; target_name?: string[] };
      // Make sure that every dataset has a corresponding target
      const invalidDatasets = dataset_name.filter(
        (d) =>
          !pcrTargetByDataset[d.split(":")[0]]?.some((t) =>
            target_name?.includes(t),
          ),
      );
      if (!target_name?.length || invalidDatasets.length === 0) {
        return Promise.resolve();
      } else {
        return Promise.reject(
          new Error(
            `The target_name filter will exclude some selected datasets. You should either (a) remove the target_name filter, (b) remove these datasets, or (c) add a target_name filter that will include every selected dataset. Invalid datasets: ${invalidDatasets.join(", ")}`,
          ),
        );
      }
    },
  });

const TargetName = ({
  rules,
  namePrefix = [],
  ...props
}: StandardItemProps) => (
  <SelectField
    label="Target name"
    help="This should only be used for special cases where you want to limit the targets for a dataset (e.g. limiting the Flu dataset to InfB)."
    namePrefix={namePrefix}
    rules={concatRules([targetNameRule(namePrefix)], rules)}
    dependencies={[["data_filter", ...namePrefix, "dataset_name"]]}
    {...props}
  />
);

const StartDate = (props: StandardItemProps) => (
  <Field label="Sample date end" {...omit(props, ["multiple", "options"])}>
    <DateStringPicker />
  </Field>
);

export const endDateRule: FormRule = {
  validator(_, value: IsoDate) {
    const maxEndDate = dayjs().add(50, "years").format("YYYY-MM-DD");
    if (value > maxEndDate) {
      return Promise.reject(
        new Error(
          "Please remove the end date instead of setting it to the distant future.",
        ),
      );
    } else {
      return Promise.resolve();
    }
  },
};

const EndDate = ({ rules, ...props }: StandardItemProps) => (
  <Field
    label="Sample date end"
    rules={concatRules([endDateRule], rules)}
    {...omit(props, ["multiple", "options"])}
  >
    <DateStringPicker />
  </Field>
);

export const formItems = {
  dataset_name: DatasetName,
  target_name: TargetName,
  start_date: StartDate,
  end_date: EndDate,
};

export const StandardField = ({ name, ...props }: StandardItemProps) => {
  const Component =
    typeof name === "string" && name in formItems
      ? formItems[name]
      : SelectField;
  return <Component name={name} {...props} />;
};

export const FormGrid = ({
  className,
  ...props
}: React.HTMLProps<HTMLDivElement>) => (
  <div
    className={cx(
      "grid grid-cols-[auto,1fr] gap-2 [&_.ant-form-item]:mb-0",
      className,
    )}
    {...props}
  />
);
